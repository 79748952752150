// React Components
import * as React from 'react';

// Audit Vault Components
import TenantForm from "../components/TenantForm";

function CreateTenant(props) {

    const { user, setUser } = props;

    let isAdminPage = false;
    return (
        <>
            {user &&
                <TenantForm user={user} companyId={user.companyId} isAdminPage={isAdminPage} setUser={setUser}>
                </TenantForm>}
        </>
    );


}
export default CreateTenant;