// React Components
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

//Material UI Components
import Alert from '@mui/material/Alert';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Shop2Icon from '@mui/icons-material/Shop2';

// Audit Vault Constants
import { COUNTRIES } from "../../constants/constants-countries";
import { PROVINCE_STATES } from "../../constants/constants-provincestates";
import { SUPPORT_EMAIL_TO_RECEIVE_SETUP_REQUESTS } from '../../constants/constants-email';
const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

// Audit Vault Utilities
import currencyFormatter from "../../utilities/common-currency-utils";
import ProgressBar from "../common/ProgressBar";


function MyCompanyPreCheckout(props) {
    const { 
        companyNameValue, 
        emailValue,
        phoneValue,
        country,
        countryValue,
        product, setProduct,
        price, setPrice,
        getProductAndPriceIds,
        billingInterval, setBillingInterval, 
        billingCustomerName, setBillingCustomerName,
        billingCustomerPhone, setBillingCustomerPhone,
        billingCustomerEmail, setBillingCustomerEmail,
        billingCustomerAddressLine1, setBillingCustomerAddressLine1,
        billingCustomerAddressLine2, setBillingCustomerAddressLine2,
        billingCustomerCity, setBillingCustomerCity,
        billingCustomerProvince, setBillingCustomerProvince,
        billingCustomerPostalCode, setBillingCustomerPostalCode,
        billingCustomerCountry, setBillingCustomerCountry,
        promoCode, setPromoCode,
        billingCustomerNameError,
        billingCustomerPhoneError,
        billingCustomerEmailError,
        billingCustomerAddressLine1Error,
        billingCustomerCityError,
        billingCustomerProvinceError,
        billingCustomerPostalCodeError,
        promo,
        totalPrice, setTotalPrice
     } = props;

    const [discountCode, setDiscountCode] = useState("");
    
    const [promoCodeMsg, setPromoCodeMsg] = useState("");
    const [promoCodeError, setPromoCodeError] = useState(false);
    const [promoUsed, setPromoUsed] = useState(false);
    const [loadingPromo, setLoadingPromo] = useState(false);

    const provState = useRef("NA");

    const billingTextFields = [
        { key: 'txtCustomerName', 
            name: 'customerName', 
            required: true, 
            label: 'Customer Name:', 
            value: billingCustomerName, 
            onchange: setBillingCustomerName, 
            error: billingCustomerNameError, 
            errText: 'Customer Name cannot be empty.', 
            descText: '',
            placeholderText: 'Enter your company name here.'
        },
        { key: 'txtCustomerPhone', 
            name: 'customerPhone', 
            required: true, 
            label: 'Billing Phone Number:', 
            value: billingCustomerPhone, 
            onchange: setBillingCustomerPhone, 
            error: billingCustomerPhoneError, 
            errText: 'Billing Phone number cannot be empty.', 
            descText: '',
            placeholderText: 'Enter your phone number here.' },
        { key: 'txtCustomerEmail', 
            name: 'customerEmail', 
            required: true, 
            label: 'Billing Email:', 
            value: billingCustomerEmail, 
            onchange: setBillingCustomerEmail, 
            error: billingCustomerEmailError, 
            errText: 'Billing Email cannot be empty and must be a valid email address.', 
            descText: '',
            placeholderText: 'Enter your email address here.' },
        { key: 'txtCustomerAddressLine1', 
            name: 'customerAddressLine1', 
            required: true, 
            label: 'Address Line 1:', 
            value: billingCustomerAddressLine1, 
            onchange: setBillingCustomerAddressLine1, 
            error: billingCustomerAddressLine1Error, 
            errText: 'Address Line 1 cannot be empty.', 
            descText: '',
            placeholderText: 'Enter your address here.' },
        { key: 'txtCustomerAddressLine2', 
            name: 'customerAddressLine2', 
            required: false, 
            label: 'Address Line 2:', 
            value: billingCustomerAddressLine2, 
            onchange: setBillingCustomerAddressLine2,
            errText: '', 
            descText: '',
            placeholderText: '' },
        { key: 'txtCustomerCity', 
            name: 'customerCity', 
            label: 'City:', 
            required: true, 
            value: billingCustomerCity, 
            onchange: setBillingCustomerCity, 
            error: billingCustomerCityError, 
            errText: 'Billing City cannot be empty.', 
            descText: '',
            placeholderText: 'Enter your city here.' },
        { key: 'txtProvinceState', 
            name: 'customerProvinceState', 
            label: 'Province/State:', 
            required: true, 
            value: billingCustomerProvince, 
            onchange: setBillingCustomerProvince, 
            error: billingCustomerProvinceError, 
            errText: 'Province/State cannot be empty.', 
            descText: '',
            placeholderText: 'Enter your province/state here.' },
        { key: 'txtCustomerPostalCode', 
            name: 'customerPostalCode', 
            required: false, 
            label: 'Postal/Zip Code:', 
            value: billingCustomerPostalCode, 
            onchange: setBillingCustomerPostalCode, 
            error: billingCustomerPostalCodeError, 
            errText: 'Postal/Zip Code cannot be empty.', 
            descText: '',
            placeholderText: 'Enter your zip or postal code here.' },
    ];

    useEffect(() => {
        fetchData();
    }, []);

    async function fetchData()
    {
        setBillingCustomerName(companyNameValue);
        setBillingCustomerEmail(emailValue);
        setBillingCustomerCountry(countryValue);
        setBillingCustomerPhone(phoneValue);
        setBillingCustomerProvince(billingCustomerProvince);

        await handleBillingInterval(billingInterval);
    }

    
    const handleApplyPromoCode = async() =>
    {
        var found = false;
        const response = await axios.get(`${WEB_API_URL}Billing/get-promos`);
        if (response) {
            for(var i = 0; i < response.data.length; i++) {
                
                if (response.data[i].code == discountCode)
                {
                    if (response.data[i].couponIsValidForBillingInterval == price.billingInterval)
                    {
                        setPromoCode(response.data[i]);
                        setPromoCodeMsg("Success!  Applied " + response.data[i].couponName);
                        setPromoUsed(true);

                        var total = Number(price.pricePerUnit);

                        if (response.data[i].couponPercentOff > 0)
                        {
                            total = (Number(price.pricePerUnit) - Number(price.pricePerUnit * response.data[i].couponPercentOff/100))/100;
                        }
                        else if (response.data[i].couponAmountOff > 0)
                        {
                            total = (Number(price.pricePerUnit) - Number(response.data[i].couponAmountOff))/100;
                        }

                        if (total > 0)
                        {
                            found = true;
                            // Apply Promo code discount to price
                            setTotalPrice(total);
                        }
                        else
                        {
                            found = false;
                        }

                        setLoadingPromo(false);
                        break;
                    }
                }
            }

            if (!found)
            {
                setPromoCode('');
                setPromoUsed(false);
                setPromoCodeMsg("Promo Code is not valid.");
                setLoadingPromo(false);

                // reset price back to original
                setTotalPrice(price.pricePerUnit/100)
            }
        }
    }

    const handleApplyPromo = async() =>
    {
        setLoadingPromo(true);
        let isError=false;
        if (discountCode.trim() === '' ? setPromoCodeError(true) : setPromoCodeError(false));
        if (discountCode.trim() === '') {isError = true;}
        if (!isError)
        {
            var applyPromoResponse = await handleApplyPromoCode();
            if (applyPromoResponse)
            {
                setLoadingPromo(false);
            }
        }
        else
        {
            // reset price back to original
            setTotalPrice(price.pricePerUnit/100)
            setLoadingPromo(false);
        }
        
    }
    
    const handleRemovePromo = () => 
    {
        // reset price back to original
        setTotalPrice(price.pricePerUnit/100)

        setPromoCode("");
        setPromoUsed(false);
        setPromoCodeMsg("");
        setDiscountCode('');
    }

    const handleBillingInterval = async(val) =>
    {
        setPromoCode("");
        setPromoUsed(false);
        setPromoCodeMsg("");
        setDiscountCode('');

        setBillingInterval(val);
        var response = await getProductAndPriceIds(val);
        if (response)
        {
            setTotalPrice(response.pricePerUnit/100);
        }  
    }

    return (
        <>
        <Grid container spacing={2}>
            <Grid sx={{ minWidth: 500, width: "50%", padding: 2 }}>
            <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%' }}>
                <Card variant="outlined">
                    <CardContent>
                    <Typography variant="h6" component="div">2: Add Billing Info</Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary" component="div">Please provide your billing information.</Typography>                
                    <Box
                        sx={{
                        '& .MuiTextField-root': { m: 1, width: '45ch', marginLeft: '0px' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                    {billingTextFields.map((txtfld) => (
                        <Typography key={txtfld.key} component="div">
                        <b>{txtfld.label}</b>
                        <br></br>
                        {txtfld.key != 'txtProvinceState' ?
                        <TextField required 
                            name={txtfld.name}
                            placeholder={txtfld.placeholderText}
                            variant="outlined" 
                            size="small"
                            style={{ width: 350}}
                            InputLabelProps={{ shrink: true }}
                            value={txtfld.value}
                            onChange={event => txtfld.onchange(event.target.value) }
                            error={txtfld.error}
                            helperText={txtfld.error ? txtfld.errText : txtfld.descText}/>
                            :
                            <TextField 
                            required 
                            select 
                            value={billingCustomerProvince} 
                            onChange={event => setBillingCustomerProvince(event.target.value)} 
                            name={txtfld.name}  
                            variant="outlined" 
                            size="small"
                            style={{ width: 350}}
                            InputLabelProps={{ shrink: true }}
                            inputRef={provState} 
                            error={txtfld.error}
                            helperText={txtfld.error ? txtfld.errText : txtfld.descText}
                            >
                        {PROVINCE_STATES.map((option) => (
                        <MenuItem key={option.value} value={option.value} >
                            {option.label}
                        </MenuItem>
                        ))}
                        </TextField>
                    }
                    </Typography>
                    ))}
                    <Typography key='lblCountry' component="div">
                        <b>Country:</b>
                        <br></br>
                        <TextField required 
                            select 
                            value={billingCustomerCountry} 
                            onChange={event => setBillingCustomerCountry(event.target.value)} 
                            name="country" 
                            variant="outlined" 
                            size="small"
                            style={{ width: 350}}
                            InputLabelProps={{ shrink: true }}
                            inputRef={country} >
                            {COUNTRIES.map((option) => (
                                <MenuItem key={option.label} value={option.value} >
                                {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Typography>
                    </Box>
                    </CardContent>
                </Card>
                </Paper>
            </Grid>
            <Grid sx={{ minWidth: 500, width: "50%", padding: 2 }}>
                <Card variant="outlined">
                    <CardContent>
                    <Typography variant="h6" component="div">Selected Subscription Plan</Typography>
                    <hr />
                    <>
                        <Typography variant="h6" component="div">{product.name}</Typography>                
                        <Typography component="div">{product.description}</Typography>                
                        <Typography><br />Total Price: {currencyFormatter(totalPrice)}/{billingInterval}</Typography>
                        {promoCode && 
                            (
                                <>
                                <Typography sx={{paddingTop: '5px', paddingBottom:'5px'}}>Discount: <b>{promoCode.couponName}</b></Typography>
                                </>
                            )                                                       
                        }
                        <Typography component="div" sx={{ fontSize: '0.8rem' }}>{product.productHighlights}</Typography>
                        <Typography component="div"><br></br></Typography>
                        <Typography component="div" sx={{paddingBottom: 1}}>
                            <Typography sx={{ paddingRight: 3}}>
                            Billing Frequency:
                            </Typography>
                            <Typography>
                                    <TextField required 
                                    select 
                                    value={billingInterval}
                                    onChange={event => handleBillingInterval(event.target.value)} 
                                    name="billingInterval" 
                                    variant="outlined" 
                                    size="small"
                                    margin="dense"
                                    style={{ width: 125}}
                                    InputLabelProps={{ shrink: true }} >
                                        <MenuItem key='year' value='year'>Yearly</MenuItem>
                                        <MenuItem key='month' value='month'>Monthly</MenuItem>
                                </TextField>
                            </Typography>
                        </Typography>
                    </>       
                    <hr />
                    <Shop2Icon style={{ color: 'green' }} />&nbsp; <b><u><span style={{ color: 'green' }}>Try free for 14 days!</span></u></b>&nbsp; Your free trial period is for 14 days after which payment will be due.
                    <br /><br /><b>Optional:</b> Concierge setup and quick-start service is available if you prefer concierge service to get started (get your solution setup with training for your Administrator(s)).&nbsp; <a href={`mailto:${SUPPORT_EMAIL_TO_RECEIVE_SETUP_REQUESTS}?subject=${encodeURIComponent('Concierge Setup Inquiry for Audit Vault for M365')}`}>Contact us</a> to find out more and schedule a time if you require this add-on service.
                    <br />
                    </CardContent>
                </Card>

                <br />

                <Card sx={{ width: '100%' }} variant="outlined">
                    <CardContent>
                    <Typography variant="h6" component="div">Apply Promotional Discount</Typography>
                    <hr />                    
                    <div>
                        {loadingPromo &&
                        (<><ProgressBar loading={loadingPromo}></ProgressBar></>)
                        }      
                        {promoCodeMsg && 
                            (
                                <>
                                <Alert severity="info">{promoCodeMsg}</Alert>
                                <br />
                                </>
                            )                                                       
                        }
                        {!loadingPromo && !promoCode  &&
                            (
                                <>
                                <b>Promo Code:</b>
                                <br></br>                   
                                <TextField 
                                    name="promoCode" 
                                    controlid="discountCode" 
                                    variant="outlined" 
                                    size="small"
                                    InputLabelProps={{ shrink: true }}
                                    onChange={event => setDiscountCode(event.target.value) }
                                    error={promoCodeError}
                                    disabled={promoUsed}
                                    inputRef={promo}
                                    helperText={promoCodeError ? 'Promo code cannot be empty.' : ''} />
                                    <Button onClick={handleApplyPromo} sx={{ mr: 1 }}>Apply</Button>
                                </>
                            )
                        }
                        {promoCode &&
                            (
                                <>
                                <b>Promo Code:</b>
                                <br></br>
                                <Typography component="span" sx={{paddingRight: '20px'}}>{promoCode.couponName}</Typography>        
                                <Typography component="span"><Button onClick={handleRemovePromo} sx={{ mr: 1 }}>Remove</Button></Typography>
                                </>
                            )
                        }
                    </div>
                    <Typography variant="h6" component="div"><br></br></Typography>
                    <Typography>Total Price: {currencyFormatter(totalPrice)}/{billingInterval}</Typography>
                </CardContent>
                </Card>
            </Grid>
        </Grid>
        </>
    );
                    
}
export default MyCompanyPreCheckout;