// React Components
import { useEffect, useState } from "react";
import PropTypes from 'prop-types';

// Material UI Components
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';

// Audit Vault Components
import AccessDenied from "../components/common/AccessDenied";
import CompanyNag from "../components/common/CompanyNag";
import ServiceIsDown from "../components/common/ServiceIsDown";
import InvoiceList from "../components/billing/InvoiceList";
import ProgressBar from "../components/common/ProgressBar";
import DisplayBreadCrumb from "../components/common/DisplayBreadCrumb";
import DisplaySectionTitleAndDescription from "../components/common/DisplaySectionTitleAndDescription";
import MyBillingUpdatePayment from "../components/payments/MyBillingUpdatePayment";
import UpdateBillingAddressForm from "../components/billing/UpdateBillingAddressForm";

// Audit Vault Common
import { getCompanyByCompanyId } from "../utilities/common-company";
import { getSubscriptionInfo, getCompanyProductsInfo, getBillingStatusColour, getBillingStatus, getCollectionMethod, getPaymentType, getBillingInterval } from "../utilities/common-getBillingInfo";
import { formatDate } from "../utilities/common-date-utils";
import currencyFormatter from "../utilities/common-currency-utils";
import { getStripeCustomer, getStripeCustomerPaymentMethod, getStripeCustomerInvoices } from "../utilities/common-getBillingInfo";

// Audit Vault Constants
import { LINK_TO_SAAS_CUSTOMER_AGREEMENT } from "../constants/constants-links";

const theSectionTitle = "Billing and Payments";
const theSectionDesc = "Welcome to your Billing and Payments Dashboard. This is where you can review your invoices and payment methods.";
const isAdminPage = false;


const theBreadcrumbPath = [
    { name: 'Home', link: '/' },
    { name: 'Company', link: '/mycompany' },
    { name: 'Billing and Payments', link: '/mybilling' },
];

const rows = [];
let subscriptionItemscolumns = [
    { id: 'createdDate', label: 'Created Date', minWidth: 10, align: 'left' },
    //{ id: 'priceId', label: 'Item ID', minWidth: 20, align: 'left' },
    { id: 'productName', label: 'Product Type', minWidth: 50, align: 'left' },
    { id: 'reoccuringInterval', label: 'Billing Interval', minWidth: 50, align: 'left' },
    { id: 'unitAmount', label: 'Amount', minWidth: 40, align: 'right' },
];

let subscriptionDiscountcolumns = [
    { id: 'couponName', label: 'Name', minWidth: 10, align: 'left' },
    { id: 'couponPercentOff', label: 'Percent Off', minWidth: 20, align: 'left' },
    { id: 'couponDollarsOff', label: 'Dollars Off', minWidth: 20, align: 'left' },
    { id: 'couponDuration', label: 'Discount Duration', minWidth: 50, align: 'left' },
];


function MyBilling(props) {

    // Component Constants
    const { user, openCompanyNag, setOpenCompanyNag } = props;

    const [value, setValue] = useState(0);
    const [company, setCompany] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [companyProducts, setCompanyProducts] = useState("");
    const [subscription, setSubscription] = useState("");
    const [loading, setLoading] = useState(true);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);
    const [accessDenied, setAccessDenied] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState("");
    const [showUpdatePayment, setShowUpdatePayment] = useState(false);
    const [updatePaymentMsg, setUpdatePaymentMsg] = useState(false);

    const [stripeCustomer, setStripeCustomer] = useState("");
    const [showUpdateBilling, setShowUpdateBilling] = useState(false);
    const [updateBillingMsg, setUpdateBillingMsg] = useState(false);

    async function fetchProductInfo() {

        if (loading) {
            if (user) {
                try {
                    const response = await getCompanyByCompanyId(user.companyId, setServiceIsDownError);
                    if (response) {
                        setCompany(response);
                        if (response.setupStageComplete >= 2) {
                            var productsReponse = await getCompanyProductsInfo(user.companyId, setCompanyProducts);
                            if (productsReponse) {
                                for (var i = 0; i < productsReponse.products.length; i++) {
                                    var subResponse = await getSubscriptionInfo(productsReponse.products[i].stripeSubscriptionId, setSubscription);
                                    if (subResponse) {
                                        console.log(subResponse)
                                    }
                                }
                            }
                        }
                    }
                    else {
                        setAccessDenied(true);
                    }
                }
                catch (e) {
                    console.log(e)
                    setServiceIsDownError(true);
                }
                finally {
                    setLoading(false);
                }
            }
        }
    };

    async function fetchInvoiceData() {
        try {
            setLoading(true);
            if (subscription) {
                const response = await getStripeCustomerInvoices(subscription.stripeCustomerId, 24);
                if (response) {
                    setInvoices(response);
                }
            }
        }
        catch (e) {
            console.log("ERROR: MyBilling.fetchInvoiceData");
            console.log(e);
            setServiceIsDownError(true);
        }
        finally {
            setLoading(false);
        }
    }

    async function fetchPaymentMethodData() {
        try {
            setLoading(true);
            if (subscription) {
                const response = await getStripeCustomerPaymentMethod(subscription.stripeCustomerId);
                if (response) {
                    setPaymentMethod(response);
                }
            }
        }
        catch (e) {
            console.log("ERROR: MyBilling.fetchPaymentMethodData");
            console.log(e);
            setServiceIsDownError(true);
        }
        finally {
            setLoading(false);
        }
    }

    async function fetchBillingAddressData() {
        try {
            setLoading(true);
            if (subscription) {
                const response = await getStripeCustomer(subscription.stripeCustomerId);
                if (response) {
                    setStripeCustomer(response);
                }
            }
        }
        catch (e) {
            console.log("ERROR: MyBilling.fetchBillingAddressData");
            console.log(e);
            setServiceIsDownError(true);
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchProductInfo();
    }, []);

    // Component Functions
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);

        if (newValue == 1) {
            fetchInvoiceData();
        }

        if (newValue == 2) {
            fetchPaymentMethodData();
        }

        if (newValue == 3) {
            fetchBillingAddressData();
        }

    };


    // Component UI
    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }

    if (accessDenied) {
        return (<AccessDenied></AccessDenied>);
    }

    return (
        <>
            <DisplayBreadCrumb paths={theBreadcrumbPath} />
            <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />
            <CompanyNag company={company}
                openCompanyNag={openCompanyNag}
                setOpenCompanyNag={setOpenCompanyNag}>
            </CompanyNag>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Products" {...a11yProps(0)} />
                        <Tab label="Invoices" {...a11yProps(1)} />
                        <Tab label="Payment Method" {...a11yProps(2)} />
                        <Tab label="Billing Profile" {...a11yProps(3)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <Typography component="span">
                        {loading && (<ProgressBar message="Loading ..." loading={loading} />)}
                        {!loading && subscription &&
                            (
                                <>
                                    <Card sx={{ maxWidth: 750 }} variant="outlined">
                                        <CardContent>
                                            <b>Current Subscriptions:</b>
                                            <Table sx={{ minWidth: 250 }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell key="billingTableHead" colSpan={2}></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell key="BillingStatus" style={{ width: '40%' }}>Status:</TableCell>
                                                        <TableCell key="BillingStatusDesc">
                                                            <Typography variant="h6" color={getBillingStatusColour(subscription.status)} >
                                                                <CircleRoundedIcon color={getBillingStatusColour(subscription.status)} sx={{ paddingRight: 1, alignItems: "center" }} />
                                                                {getBillingStatus(subscription.status)}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell key="BillingProduct" style={{ width: '40%' }}>Subscription:</TableCell>
                                                        <TableCell key="BillingProductDesc">
                                                            {subscription.description}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell key="BillingSubscriptionStartDate" style={{ width: '40%' }}>Subscribed On:</TableCell>
                                                        <TableCell key="BillingSubscriptionStartDateDesc">
                                                            {formatDate(subscription.startDate)}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell key="BillingSubscriptionCurrency" style={{ width: '40%' }}>Currency:</TableCell>
                                                        <TableCell key="BillingSubscriptionCurrencyDesc">
                                                            {subscription.currency.toUpperCase()}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell key="BillingSubscriptionCollectionMethod" style={{ width: '40%' }}>Collection Method:</TableCell>
                                                        <TableCell key="BillingSubscriptionCollectionMethodDesc">
                                                            {getCollectionMethod(subscription.collectionMethod)}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell key="BillingSubscriptionCurrentPeriodEndDate" style={{ width: '40%' }}>Current Period End Date:</TableCell>
                                                        <TableCell key="BillingSubscriptionCurrentPeriodEndDateDesc">
                                                            {formatDate(subscription.currentPeriodEndDate)}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell key="CustomerAgreement" style={{ width: '40%' }}>Customer Agreement:</TableCell>
                                                        <TableCell key="CustomerAgreementDesc">
                                                            <a href={LINK_TO_SAAS_CUSTOMER_AGREEMENT} target="_blank" rel="noopener noreferrer">View Customer Agreement</a>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell key="ItemsDesc" colSpan={2}><b>Items Included With Subscription:</b></TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell key="items" colSpan={2}>
                                                            <TableHead>
                                                                <TableRow>
                                                                    {subscriptionItemscolumns.map((column) => (
                                                                        <TableCell
                                                                            key={column.id}
                                                                            align={column.align}
                                                                            style={{ minWidth: column.minWidth }
                                                                            }
                                                                        >
                                                                            {column.label}
                                                                        </TableCell>
                                                                    ))}
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {subscription.items.map((row) => {
                                                                    return (
                                                                        <TableRow hover role="checkbox" tabIndex={-1} key={`RowID-${row.id}`}>
                                                                            {subscriptionItemscolumns.map((column) => {
                                                                                const value = row[column.id];
                                                                                switch (column.id) {

                                                                                    case "createdDate":
                                                                                        return (<TableCell key={column.id} align={column.align}>{formatDate(value)}</TableCell>)

                                                                                    case "unitAmount":
                                                                                        return (<TableCell key={column.id} align={column.align}>{currencyFormatter(value / 100)}</TableCell>)

                                                                                    case "productName":
                                                                                        return (<TableCell key={column.id} align={column.align}>{getPaymentType(value)}</TableCell>);

                                                                                    case "reoccuringInterval":
                                                                                        return (<TableCell key={column.id} align={column.align}>{getBillingInterval(value)}</TableCell>);

                                                                                    default:
                                                                                        return (
                                                                                            <TableCell key={column.id} align={column.align}>
                                                                                                {value
                                                                                                    ? value.toString()
                                                                                                    : value
                                                                                                }
                                                                                            </TableCell>
                                                                                        )
                                                                                }
                                                                            })}
                                                                        </TableRow>
                                                                    );
                                                                })}
                                                            </TableBody>
                                                        </TableCell>

                                                    </TableRow>
                                                    {subscription.discount && subscription.discount.couponId &&
                                                        (
                                                            <><TableRow>
                                                                <TableCell key="DiscountDesc" colSpan={2}><b>Discounts Included With Subscription:</b></TableCell>
                                                            </TableRow>
                                                                <TableRow>
                                                                    <TableCell key="items" colSpan={2}>
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                {subscriptionDiscountcolumns.map((column) => (
                                                                                    <TableCell
                                                                                        key={column.id}
                                                                                        align={column.align}
                                                                                        style={{ minWidth: column.minWidth }}
                                                                                    >
                                                                                        {column.label}
                                                                                    </TableCell>
                                                                                ))}
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            <TableRow hover role="checkbox" tabIndex={-1} key="subscriptionDiscount">
                                                                                <TableCell key="subscriptionDiscountNameValue">{subscription.discount.couponName}</TableCell>
                                                                                {subscription.discount.couponPercentOff > 0 &&
                                                                                    (
                                                                                        <><TableCell key="subscriptionDiscountPercentValue">{subscription.discount.couponPercentOff}%</TableCell>
                                                                                            <TableCell key="subscriptionDiscountDollarValue"></TableCell></>
                                                                                    )
                                                                                }
                                                                                {subscription.discount.couponAmountOff > 0 &&
                                                                                    (<><TableCell key="subscriptionDiscountPercentValue"></TableCell><TableCell key="subscriptionDiscountPercentValue">${subscription.discount.couponAmountOff / 100}</TableCell></>)
                                                                                }
                                                                                <TableCell key="subscriptionDiscountDurationValue">{subscription.discount.couponDuration}</TableCell>
                                                                            </TableRow>
                                                                        </TableBody>
                                                                    </TableCell>
                                                                </TableRow></>
                                                        )
                                                    }
                                                </TableBody>
                                            </Table>
                                        </CardContent>
                                    </Card>
                                </>
                            )
                        }
                    </Typography>
                    <Typography><br></br></Typography>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Typography component="span">
                        {loading && (<ProgressBar message="Loading ..." loading={loading} />)}
                        {!loading && invoices &&
                            (
                                <>
                                    <InvoiceList invoices={invoices}></InvoiceList>
                                </>
                            )
                        }
                    </Typography>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Typography component="span">
                        {loading && (<ProgressBar message="Loading ..." loading={loading} />)}
                        {!loading && paymentMethod && !showUpdatePayment &&
                            (
                                <>
                                    {updatePaymentMsg ? <><Alert severity="success"><AlertTitle>Update Successful</AlertTitle></Alert><Typography component="div"><br></br></Typography></> : ''}
                                    <Card sx={{ maxWidth: 500 }} variant="outlined">
                                        <CardContent>
                                            <b>Current Payment Method:</b>
                                            <Table sx={{ minWidth: 250 }} aria-label="simple table2">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell key="paymentMethodTableHead" colSpan={2}></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell key="paymentMethodCardType" style={{ width: '40%' }}>Card:</TableCell>
                                                        <TableCell key="paymentMethodCardTypeDesc">{paymentMethod.cardBrand.toUpperCase()}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell key="paymentMethodCardType" style={{ width: '40%' }}>Card Number:</TableCell>
                                                        <TableCell key="paymentMethodCardTypeDesc">**** **** **** {paymentMethod.cardLast4}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell key="paymentMethodCardType" style={{ width: '40%' }}>Expiry Month:</TableCell>
                                                        <TableCell key="paymentMethodCardTypeDesc">{paymentMethod.cardExpiryMonth}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell key="paymentMethodCardType" style={{ width: '40%' }}>Expiry Year:</TableCell>
                                                        <TableCell key="paymentMethodCardTypeDesc">{paymentMethod.cardExpiryYear}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </CardContent>
                                        <CardActions>
                                            <Button size="small" onClick={() => setShowUpdatePayment(!showUpdatePayment)}>{showUpdatePayment ? '' : 'Edit'}</Button>
                                        </CardActions>
                                    </Card>
                                </>
                            )
                        }
                        {showUpdatePayment ?
                            (
                                <Card sx={{ maxWidth: 700 }} variant="outlined">
                                    <CardContent>
                                        <Typography component="div"><b>Update Payment Method:</b></Typography>
                                        <Typography component="div">Please enter your new credit card information below.  You next payment will be charged against this card.</Typography>
                                        <MyBillingUpdatePayment
                                            stripeCustomerId={company.stripeCustomerId}
                                            companyProducts={companyProducts}
                                            setSubscription={setSubscription}
                                            setShowUpdatePayment={setShowUpdatePayment}
                                            setUpdatePaymentMsg={setUpdatePaymentMsg}
                                            fetchPaymentMethodData={fetchPaymentMethodData} />
                                    </CardContent>
                                    <CardActions>
                                        <Button size="small" onClick={() => setShowUpdatePayment(!showUpdatePayment)}>{showUpdatePayment ? 'Exit' : ''}</Button>
                                    </CardActions>
                                </Card>
                            )


                            : ''}
                    </Typography>
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <Typography component="span">
                        {loading && (<ProgressBar message="Loading ..." loading={loading} />)}
                        {!loading && stripeCustomer && !showUpdateBilling &&
                            (
                                <>
                                    {updateBillingMsg ? <><Alert severity="success"><AlertTitle>Update Successful</AlertTitle></Alert><Typography component="div"><br></br></Typography></> : ''}
                                    <Card sx={{ maxWidth: 500 }} variant="outlined">
                                        <CardContent>
                                            <b>Current Billing Address:</b>
                                            <Table sx={{ minWidth: 250 }} aria-label="simple table2">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell key="billingAddressTableHead" colSpan={2}></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell key="billingAddressAddressLine1" style={{ width: '40%' }}>Address Line 1:</TableCell>
                                                        <TableCell key="billingAddressAddressLine1Desc">{stripeCustomer.addressLine1}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell key="billingAddressAddressLine2" style={{ width: '40%' }}>Address Line 2:</TableCell>
                                                        <TableCell key="billingAddressAddressLine2Desc">{stripeCustomer.addressLine2}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell key="billingAddressCity" style={{ width: '40%' }}>City:</TableCell>
                                                        <TableCell key="billingAddressCityDesc">{stripeCustomer.city}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell key="billingAddressProvState" style={{ width: '40%' }}>Province/State:</TableCell>
                                                        <TableCell key="billingAddressProvStateDesc">{stripeCustomer.provinceState}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell key="billingAddressPostalZip" style={{ width: '40%' }}>Postal/Zip Code:</TableCell>
                                                        <TableCell key="billingAddressPostalZipDesc">{stripeCustomer.postalZipCode}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell key="billingAddressCountry" style={{ width: '40%' }}>Country:</TableCell>
                                                        <TableCell key="billingAddressCountryDesc">{stripeCustomer.country}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </CardContent>
                                        <CardActions>
                                            <Button size="small" onClick={() => setShowUpdateBilling(!showUpdateBilling)}>{showUpdateBilling ? '' : 'Edit'}</Button>
                                        </CardActions>
                                    </Card>
                                </>
                            )
                        }

                        {showUpdateBilling ?
                            (
                                <Card sx={{ maxWidth: 700 }} variant="outlined">
                                    <CardContent>
                                        <Typography component="div"><b>Update Billing Address:</b></Typography>
                                        <Typography component="div">Please enter your updated billing address.  The changes will be reflected on your next invoice.</Typography>
                                        <Typography component="br"></Typography>
                                        <UpdateBillingAddressForm
                                            companyId={user.companyId}
                                            stripeCustomer={stripeCustomer}
                                            setStripeCustomer={setStripeCustomer}
                                            setShowUpdateBilling={setShowUpdateBilling}
                                            setUpdateBillingMsg={setUpdateBillingMsg}
                                            fetchBillingAddressData={fetchBillingAddressData}
                                        ></UpdateBillingAddressForm>
                                    </CardContent>
                                    <CardActions>
                                        <Button size="small" onClick={() => setShowUpdateBilling(!showUpdateBilling)}>{showUpdateBilling ? 'Exit' : ''}</Button>
                                    </CardActions>
                                </Card>
                            )


                            : ''}
                    </Typography>
                </TabPanel>
            </Box>
        </>
    );

}

export default MyBilling;