// React Components
import * as React from 'react';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Material UI Components
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionActions from '@mui/material/AccordionActions';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';

// Audit Vault Components
import CompanyNag from "../components/common/CompanyNag";
import ServiceIsDown from "../components/common/ServiceIsDown";
import ProgressBar from "../components/common/ProgressBar";
import DisplayBreadCrumb from "../components/common/DisplayBreadCrumb";
import DisplaySectionTitleAndDescription from "../components/common/DisplaySectionTitleAndDescription";
import TenantDropDown from "../components/reports/TenantDropDown";
import TenantRoleList from "../components/role/TenantRoleList";

// Audit Vault Common
import { getCompanyByCompanyId } from "../utilities/common-company";
import { getAuditVaultUsersCompanyAdminsByCompanyId, removeCompanyAdminAccess } from '../utilities/common-user-utils';

const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

function MyCompanyRoles(props) {

    const { user, openCompanyNag, setOpenCompanyNag } = props;
    const queryParams = new URLSearchParams(window.location.search);

    // Component Constants
    const navigate = useNavigate();

    const [company, setCompany] = useState("");
    const [loading, setLoading] = useState(true);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);
    const [companyAdminUsers, setCompanyAdminUsers] = useState([]);
    const [selectedTenant, setSelectedTenant] = useState(0);
    const [selectedTenantChanged, setSelectedTenantChanged] = useState(false);

    const [selectedTenantUrl, setSelectedTenantUrl] = useState("");
    const [currentTenantObj, setCurrentTenantObj] = useState(null);

    const [companyAdminsloading, setCompanyAdminsLoading] = useState(false);
    const [selectedRole, setSelectedRole] = useState(0);
    const [openNotification, setOpenNotification] = useState(false);
    const [openNotificationMsg, setOpenNotificationMsg] = useState('User Removed from Role');
    const [selectedTenantOption, setSelectedTenantOption] = useState('');

    const [companyAdmins, setCompanyAdmins] = useState(false);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);


    const theBreadcrumbPath = [
        { name: 'Home', link: '/' },
        { name: 'Company', link: '/mycompany' },
        { name: 'Company Roles', link: '/mycompany-roles' },
    ];
    const theSectionTitle = "Company Role Assignments";
    const theSectionDesc = "Below are the built-in security access role groups that are used to manage access to this application for your Company.";
    const isAdminPage = false;


    async function fetchData() {
        if (loading && user) {
            try {
                const response = await getCompanyByCompanyId(user.companyId, setServiceIsDownError);
                if (response) {
                    setCompany(response);
                    if (response != null) {

                        if (response.tenantList != null && response.tenantList.length > 0) {
                            setSelectedTenantOption(response.tenantList[0]);
                            setSelectedTenant(response.tenantList[0].id);
                            setSelectedTenantUrl(response.tenantList[0].tenantUrl);
                            setCurrentTenantObj(response.tenantList[0]);

                            if (queryParams.get("successfulAdd") && queryParams.get("successfulAdd").length > 0) {
                                getCompanyAdmins();
                                setOpenNotificationMsg("User Added to Role");
                                setOpenNotification(true);
                            }

                            if (queryParams.get("roleId") && queryParams.get("roleId").length > 0) {
                                let roleNum = Number(queryParams.get("roleId"));
                                switch (roleNum) {
                                    case 1:
                                        setSelectedRole(roleNum);
                                        setCompanyAdmins(true);
                                        break;

                                    default:
                                        setSelectedRole(roleNum);
                                        break;
                                }
                            }
                        }
                    }
                }
            }
            catch (e) {
                setServiceIsDownError(true);
                console.log(e);
            }
            finally {
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const handleTenantOptionChange = (event) => {
        setSelectedTenantChanged(true);
        setSelectedTenantOption(event.target.value);
        setSelectedTenant(event.target.value.id);
    };

    const handleAddMembers = (roleIdValue) => {
        if (roleIdValue > 0) {
            navigate(`/mycompany-assign-role/${selectedTenant}/${roleIdValue}`)
        }
    };


    const getCompanyAdmins = async () => {
        var responseCa = await getAuditVaultUsersCompanyAdminsByCompanyId(user.companyId);
        if (responseCa) {
            setCompanyAdminUsers(responseCa);
            setCompanyAdminsLoading(false);
        }
    }

    const deleteCompanyAdmin = async (id) => {
        try {
            const response = await removeCompanyAdminAccess(user.companyId, user.microsoftGraphId, id);
            if (response) {
                setOpenNotificationMsg("User Removed from Role");
                getCompanyAdmins();
                setOpenNotification(true);
            }
        } catch (e) {
            console.log("ERROR: MyCompanyRoles.deleteCompanyAdmin");
            console.log(e);
            setServiceIsDownError(true);
        }
        finally {
            setLoading(false);
        }
    };

    const handleReviewMembers = (value) => {
        if (value > 0) {
            navigate(`/mytenant-roles/${selectedTenant}/${value}`)
        }
    };

    const handleAccordionLoad = async (roleId) => {
        setCompanyAdmins(companyAdmins);
        if (!companyAdmins) {
            getCompanyAdmins();
        }
        setCompanyAdmins(!companyAdmins);
    }

    // For snackbar close action.
    const handleNotificationClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenNotification(false);
    };

    // For snackbar notification action.
    const openNotificationAction = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleNotificationClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    // Component UI
    if (serviceIsDownError) {
        return (
            <><ServiceIsDown></ServiceIsDown></>);
    }

    if (!loading && company) {
        return (
            <>
                <DisplayBreadCrumb paths={theBreadcrumbPath} />
                <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />
                <CompanyNag company={company} openCompanyNag={openCompanyNag} setOpenCompanyNag={setOpenCompanyNag} />
                {loading &&
                    <div><ProgressBar loading={loading} message="Loading ..." /></div>
                }

                {!loading && company.setupStageComplete === 4 &&

                    <><Typography component="span">
                        <Typography><b>Company Roles:</b></Typography>
                        <Typography><br></br></Typography>
                        <Card sx={{ width: 600, minWidth: 600 }} variant="outlined">
                            <CardContent>
                                <Accordion expanded={companyAdmins}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        onClick={event => handleAccordionLoad()}
                                        id="panel1a-header"
                                    >
                                        <Typography><b>Company Administrators</b><br />Has full access to all management features in this application.</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <ProgressBar message="Loading ..." loading={companyAdminsloading} />
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableBody>
                                                {companyAdminUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                                    console.log(row)
                                                    if (row.isCompanyAdmin) {
                                                        return (
                                                            <TableRow>
                                                                <TableCell key={'TA' + row.displayName + row.id}>{row.displayName}</TableCell>
                                                                <TableCell key={'TADEL' + row.displayName + row.id} sx={{ textAlign: "right" }}>
                                                                    <IconButton aria-label="delete" onClick={(event) => deleteCompanyAdmin(row.microsoftGraphId)}>
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    }
                                                })
                                                }
                                            </TableBody>
                                        </Table>
                                        {companyAdminUsers.length > 25 &&
                                            <TablePagination
                                                rowsPerPageOptions={[25, 50, 75]}
                                                component="span"
                                                count={systemAdmins.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage} />
                                        }
                                    </AccordionDetails>
                                    <AccordionActions>
                                        <Typography component="span" sx={{ width: '100%' }}>
                                            <Button size="small" sx={{ textAlign: "left" }} onClick={(event) => handleReviewMembers(1)}>See More</Button>
                                        </Typography>
                                        <IconButton sx={{ textAlign: "right" }} aria-label="addUser" onClick={(event) => handleAddMembers(1)}>
                                            <PersonAddAlt1Icon sx={{ color: "green" }} />
                                        </IconButton>
                                    </AccordionActions>
                                </Accordion>

                            </CardContent>
                        </Card>
                        <Typography><br></br></Typography>
                        <TenantDropDown
                            user={user}
                            company={company}
                            handleTenantOptionChange={handleTenantOptionChange}
                            selectedTenantOption={selectedTenantOption}></TenantDropDown>

                        <TenantRoleList user={user}
                            tenantId={selectedTenant}
                            roleNum={selectedRole}
                            sourcePage={"MyCompany"}
                            setSelectedTenantChanged={setSelectedTenantChanged}
                            selectedTenantChanged={selectedTenantChanged}
                        ></TenantRoleList>
                    </Typography>
                        <div>
                            <Snackbar
                                open={openNotification}
                                autoHideDuration={6000}
                                onClose={handleNotificationClose}
                                message={openNotificationMsg}
                                action={openNotificationAction} />
                        </div>
                    </>
                }

            </>
        );
    }
}
export default MyCompanyRoles;