// React Components
import { useParams } from "react-router-dom";
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// Material UI Components
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Grid from '@mui/material/Grid';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import InfoIcon from '@mui/icons-material/Info';
import Link from '@mui/material/Link';

// Audit Vault Components
import ProgressBar from "../components/common/ProgressBar";
import ServiceIsDown from "../components/common/ServiceIsDown";
import DisplaySectionTitleAndDescription from "../components/common/DisplaySectionTitleAndDescription";
import DisplayExportableDataSource from "../components/reports/DisplayExportableDataSource";
import ImageSharePoint from '../imgs/iconSharePoint.svg';
import SessionTimeout from "../components/common/SessionTimeout";

// Audit Vault Graph Functions
import { permsRequest, loginRequest } from '../authConfig';
import { callMsGraphPerms } from '../graph';

// Audit Vault Common Functions
import { verifyUserHasTenantRolePermissions, loginToMicrosoft } from "../utilities/common-user-utils";
import { ROLE_CODE_TENANTVIEWER } from "../constants/constants-roles";
import { getTenantsRecordedTime2 } from "../utilities/common-date-utils";
import { encryptKey } from '../utilities/common-encrypt-util';
import { getTenantById } from "../utilities/common-tenant";
import { createSystemEvent } from "../utilities/common-sysEvents-util";

import { SYSEVENT_VIEW_SP_SPOAUDITHISTORY_REPORT_NOACCESS } from "../constants/constants-systemEvents";

const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

/*

ReportsFromSpoViewSharePointAuditHistory report.
This is a special page accessed only from SharePoint Online.
This page is meant to be accessed only from the SharePoint Item Menu > View Audit History
As such this page should only ever be accessed from SharePoint Online directly.

This page should be using the permissions model as per below:

SharePoint Audit Page
The Audit Vault for M365 - SharePoint Audit Page can be added to your SharePoint tenant(s), this allows you to enable an Audit menu item in 
SharePoint, it can be accessed directly from Audit Vault for M365 as well. The SharePoint Audit Page displays audit information for a 
SharePoint Item. You can control the behavior of the SharePoint Audit Page below.   
- If enabled, accessing an Item's audit detail from SharePoint will require the User to have the correct Tenant Viewer role in Audit Vault for M365.
- If disabled, accessing an Item's audit detail from SharePoint will require the User to have "View" access to the SharePoint Item only (not needing even an Audit Vault User account)
  - Note that if the user does NOT have "view" rights to the item in SharePoint, then they will NOT be able to view the audit history for that item.
  - Note that if the user does have "view" rights to the item in SharePoint, then they will be able to view the audit history for that item.


Cases: (we must handle them all)
Login status for... (no = not logged, yes = logged in)
M365    Audit Vault for M365    UseSPPerms  
Yes     Yes                     Yes: check Graph perms, No: check Audit Vault perms                            
No      Yes                     Yes: check Graph perms, No: check Audit Vault perms 
Yes     No                      Yes: check Graph perms, No: check Audit Vault perms (not logged in - display as such)
No      No                      Not logged in - display error message.

Page is displayed only if:
Tenant Config Setting - Set to allow 
1.) anyone with Access to View the Object in SP has access to view the audit (in which case we check the current user’s perms to confirm adequate SP object access).  
OR 
2.) Was restricted to the users you've specified here for “Tenant Viewer+” role permissions (minimum view access).

Notes...
Look into - require user to login to Audit Vault for M365 - first time only.  
OR is it possible to pull the M365 credentials and check.  

*/
function ReportsFromSpoViewSharePointAuditHistory(props) {

    const { user } = props;
    const navigate = useNavigate();

    // Component Constants
    const { id } = useParams();
    const [history, setHistory] = useState([]);
    const [lastSuccessfulRunDate, setLastSuccessfulRunDate] = useState("");

    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState(null);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [companyNotSetup, setCompanyNotSetup] = useState(false);
    const [loginTimeout, setLoginTimeout] = useState(false);

    const theSectionTitle = "Report: View SharePoint Item's Audit History";
    const theSectionDesc = "Audit Vault for M365 is managing and securing your organization’s audit activities. Below are the recorded audit activities for the specified SharePoint item.";
    const isAdminPage = false;
    const [displayAuditRecord, setDisplayAuditRecord] = useState(false);

    const [usingAuditVaultPermsToAuthenticate, setUsingAuditVaultPermsToAuthenticate] = useState(false);  // Track what setting is used on the Tenant.
    const [usingAuditVaultPermsHasTenantViewAccess, setUsingAuditVaultPermsHasTenantViewAccess] = useState(false);  // Track if has access.
    const [usingSPOPermsHasAccess, setUsingSPOPermsHasAccess] = useState(false);  // Track if has access.
    const [tenantId, setTenantId] = useState(0);

    const isAuthenticated = useIsAuthenticated();

    const columns = [
        { id: 'operation', label: 'Event', minWidth: 100, maxWidth: 100, align: 'left' },
        { id: 'creationTime', label: 'Date', minWidth: 100, maxWidth: 100, align: 'left' },
        { id: 'userId', label: 'User', minWidth: 100, maxWidth: 200, align: 'left' },
        { id: 'objectId', label: 'Item', minWidth: 100, maxWidth: 200, align: 'left' },
    ];


    // Component Functions
    const fetchAuditData = async () => {

        // NOTE: THIS IS A SPECIAL PAGE, WE MAY NOT ALWAYS HAVE A USER, SINCE THIS PAGE MAY ALSO USE SP PERMISSIONS ONLY AND IS ACCESSED DIRECTLY FROM SPO.
        // HENCE WE DON'T NEED TO OR WANT TO DO... THE USUAL: if (user && user.userId && user.companyId > 0) {

        // If you are not logged in, log in using SS
        if (!isAuthenticated) {
            instance.loginRedirect(loginRequest).catch(error => {
                console.log(error);
            });
        }
        else {
            const request = {
                ...permsRequest,
                account: accounts[0]
            };

            var request1;
            var accessTok;

            try {
                // Attempt to grab log in token.  Microsoft Authentication Library (MSAL), acquireTokenSilent is used to silently acquire an access token for the specified account (in this case, accounts[0]) without showing any user interface prompts.
                request1 = await instance.acquireTokenSilent(request);  // If user is not logged in will throw an error.                
                accessTok = request1.accessToken;
            }
            catch (error) {
                // Unable to acquire user token for accounts[0].
                // This means no one is logged in whatsoever.  User is not authenticated.
                console.log("ERROR: acquireTokenSilent");
                console.log(error);
                if (error.toString().indexOf("invalid_grant") > 0) {
                    // means that company has not been set up yet or admin consent has not been granted yet,
                    setCompanyNotSetup(true);
                }
                else if (error.toString().indexOf("login_required") > 0) {
                    console.log("Logging into Microsoft again.");
                    loginToMicrosoft(instance);
                }
                else
                    setLoginTimeout(true);
            }

            try {
                if (accessTok) {

                    // First check if the user.companyId == 0.  If it's 0, that means the User is a valid M365 User, but the Company has not been setup for use with Audit Vault for M365.
                    if (user.companyId === 0) {
                        // Company was not setup.
                        setCompanyNotSetup(true);  // Set company to not yet setup.
                    }
                    else {

                        // So at this point we are logged in.          
                        // We use displayAuditRecord as the state tracker to determine if we will display the audit record.  So that is only set to true if it passes all checks.                              
                        // Try to load the Audit History data.  Now, in this case we load the data first because we need to load the data in order to get theGraph Url.                    
                        // We load the Audit History for the GUID given the User's company id.  WebAPI call will check all of their tenants.  In theory only 1 of the tenant's would match on the guid.
                        // Before creating the JSON we need to convert the guid to a string so it can be converted to JSON.

                        var usrEmail = "N/A";
                        if (user.userEmail)
                            usrEmail = user.userEmail;

                        var payload = `{
                            "userMicrosoftId": "${await encryptKey(user.microsoftGraphId)}",
                            "userEmail": "${await encryptKey(usrEmail)}",
                            "companyId": ${user.companyId},
                            "listItemUniqueId": "${id.toString()}",
                            "isSpoReport": ${true},
                          }`;

                        var response;
                        if (payload) {
                            response = await axios.get(`${WEB_API_URL}Reports/GetSharePointAuditHistoryByListItemUniqueId`, {
                                params: {
                                    key: `${payload}`
                                }
                            });
                        }
                        if (response) {
                            //console.log("MK1: Audit History = " + response.data.auditHistory)
                            setHistory(response.data.auditHistory);  // Store the audit history.                

                            if (response.data.auditHistory[0]) {
                                // There is audit history to display.
                                // Let's get the first element's tenantId.
                                var checkTenantId = response.data.auditHistory[0].tenantId;
                                // Check Tenant to see if it's using SP Perms or Audit Vault Perms to control access to this page.
                                var responseTenant = null;
                                // Load the tenant and make sure it's valid before continuing.  99% of the time the Tenant is pre-verified in the UI, in the 1% where Sys Admin changes the Tenant ID is where we want to make sure even though the extra call is probably overkill and can be handled on backend error checking, we do both.
                                responseTenant = await getTenantById(checkTenantId);
                                setTenantId(checkTenantId);
                                if (responseTenant != '') {
                                    // It is a valid tenant since non-blank data was returned in the response.
                                    // Check the Tenant setting for what perms to use for this page.
                                    if (responseTenant.useSaaPermsToAccessSpAudit == true) {
                                        //console.log("MK1: Tenant setting is using Audit Vault Perms.");
                                        // We are using Audit Vault permissions - user will require minimum Tenant Viewer access.
                                        setUsingAuditVaultPermsToAuthenticate(true);  // Mark as using audit vault perms.

                                        // In order to access "SharepointAuditHistory" the current user must have at least Tenant Viewer access to the specified tenant.
                                        var checkReportAccessForTenant = verifyUserHasTenantRolePermissions(user, checkTenantId, ROLE_CODE_TENANTVIEWER);
                                        if (checkReportAccessForTenant) {
                                            //console.log("MK1: User has Tenant View Access...authentication passed.");
                                            setUsingAuditVaultPermsHasTenantViewAccess(true);  // User has access.
                                            setDisplayAuditRecord(true);  // Set display audit record to true.
                                        }
                                        else {
                                            //console.log("MK1: User does not have Tenant View Access...authentication failed");
                                            // The user does not have access.                                        
                                            setUsingAuditVaultPermsHasTenantViewAccess(false);
                                            setDisplayAuditRecord(false);
                                        }
                                    }
                                    else {
                                        //console.log("MK1: Tenant setting is using SPO Perms.");
                                        // We are using SharePoint permissions.  We will need to check SharePoint.
                                        setUsingAuditVaultPermsToAuthenticate(false);  // Mark as using SP perms.
                                        // In order to access "SharepointAuditHistory" the current user must have access to the item in SharePoint (SP Perms).
                                        var graphUrl = response.data.graphUrl;
                                        //console.log(`MK1: Graph Url: ${graphUrl}`);
                                        if (graphUrl && graphUrl.length > 0) {

                                            if (graphUrl == 'ACCESS DENIED') {

                                                setUsingSPOPermsHasAccess(false);
                                                setDisplayAuditRecord(false);
                                            }
                                            else {
                                                // There is a graph url, let's check SPO perms now.
                                                var graphResponse = await callMsGraphPerms(accessTok, graphUrl);

                                                setGraphData(graphResponse);

                                                // Check permissions graph perms here and set displayAuditRecord accordingly.
                                                if (graphResponse.error) {
                                                    //console.log("MK1: User does not have SPO Perms to this; graphResponse found error");
                                                    // There is a graph error, meaning access denied.
                                                    createSystemEvent(user, SYSEVENT_VIEW_SP_SPOAUDITHISTORY_REPORT_NOACCESS, id.toString());
                                                    setUsingSPOPermsHasAccess(false);
                                                    setDisplayAuditRecord(false);

                                                } else {
                                                    //console.log("MK1: User has SPO Perms to this; graphResponse has no error");
                                                    setUsingSPOPermsHasAccess(true);
                                                    setDisplayAuditRecord(true);
                                                }
                                                // Note: if graphUrl == "DELETED", it means the item was deleted from SP and can't be viewed from SPO interface.
                                                // If someone wants to view the details they have to view it from the regular Reports with Report+ perms.  
                                            }
                                        }
                                    }

                                    // Finally once we've done all the audit loads and perms checks also load the Tenant Jobs to get last successfule run date for SharePoint
                                    fetchJobsData(checkTenantId);

                                }
                                // Else not a valid tenant, do nothing, we don't need to display an error message.  Page will display a generic not found message.                            
                            }
                            else {
                                // There was no response, so history is empty.
                                setHistory([]);
                            }
                        }
                    }
                }
            }
            catch (e) {
                console.log("ERROR: ReportsSharePointAuditHistory.fetchAuditData");
                console.log(e);
                setServiceIsDownError(true);
            }
            finally {
                setLoading(false);
            }
        }
    };


    // Load Tenant Jobs to get Last successful run date.
    async function fetchJobsData(tenantId) {
        try {
            // We attempt to load the tenant health stats (which gives us access to last synch job times).
            var rsaKey = `{Id: "${await encryptKey(tenantId.toString())}" }`;
            if (rsaKey) {
                var result = await axios.get(`${WEB_API_URL}Jobs/GetJobsByTenantId`, {
                    params: {
                        key: `${rsaKey}`
                    }
                });
                if (result != null) {
                    for (var x = 0; x < result.data.length; x++) {
                        if (result.data[x].auditTypeAssigned == 0) {
                            setLastSuccessfulRunDate(result.data[x].lastSuccessfulRunEndTime);
                        }
                    }
                }
            }
        }
        catch (e) {
            console.log("ERROR: ReportsSharePointAuditHistory.fetchJobsData");
            console.log(e);
        }
    }


    useEffect(() => {
        fetchAuditData();
    }, [id]);

    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }

    // Component UI
    if (loading) {
        return (<ProgressBar message="Loading ..." loading={loading} />);
    }

    if (companyNotSetup) {
        return (
            <Grid item xs={12}>
                <Alert severity="error">
                    <AlertTitle>
                        It looks like your Company hasn't yet registered to use Audit Vault for M365!
                    </AlertTitle>
                    <br />You can register your Microsoft 365 Tenant and start using Audit Vault for M365 in under 5 minutes.  <Link onClick={() => navigate(`/`)} component="button">Sign Up Now</Link>
                    <br /><br />“Audit Vault for M365 empowers organizations to thrive in highly regulated environments by preserving, shielding, and intelligently surfacing your audit history information where and when you need it.”
                </Alert>
            </Grid>
        );
    }

    // Specify the icon to represent the SharePoint item.
    let iconComponent;
    if (!loading && history.length > 0) {
        switch (history[0].itemType) {
            case 'Folder':
                iconComponent = <FolderIcon style={{ color: 'blue' }} />;
                break;
            case 'File':
                iconComponent = <InsertDriveFileIcon style={{ color: 'blue' }} />;
                break;
            case 'Page':
                iconComponent = <MenuBookIcon style={{ color: 'blue' }} />;
                break;
            default:  // List and everything else use this icon.
                iconComponent = <ReceiptLongIcon style={{ color: 'blue' }} />;
                break;
        }
    }

    var csvFilename = "ItemAuditHistory_" + id + ".csv";

    // Special case...            
    // Check if history exists and its listItemUniqueId is not "00000000-0000-0000-0000-000000000000"
    if (!loading && history.length > 0 && displayAuditRecord == true) {
        if (history[0].listItemUniqueId == "00000000-0000-0000-0000-000000000000") {
            setDisplayAuditRecord(false);  // Suppress displaying GUID item of 0, it's used by Microsoft as a catch-all, so tons of stuff would show up that is unrelated.            
        }
        // Else the guid is valid, continue to display.
    }

    if (loginTimeout) {
        return (
            <SessionTimeout></SessionTimeout>
        )
    }

    if (!loading) {
        // Permissions and item existence check out, display the item's audit history.

        return (
            <>
                { /* Display report section icon logo. */}
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1, textAlign: 'left', alignSelf: 'flex-start' }}>
                        <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />
                    </div>
                    <div style={{ width: '75px', textAlign: 'left', alignSelf: 'flex-start' }}>
                        <br /><img src={ImageSharePoint} alt="SharePoint Reports" />
                    </div>
                </div>

                { /* Display information about the SP Item. */}
                {
                    displayAuditRecord == true &&
                    (
                        <div>
                            <hr />
                            {/* Display item summary section. */}
                            <TableContainer>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell style={{ maxWidth: 300, border: 'none', padding: 2, whiteSpace: 'normal', wordBreak: 'break-all' }}>Name</TableCell>
                                            <TableCell style={{ maxWidth: 550, border: 'none', padding: 2, whiteSpace: 'normal', wordBreak: 'break-all' }}>
                                                {history[0].sourceFileName === null ? history[0].objectId : history[0].sourceFileName}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ maxWidth: 300, border: 'none', padding: 2, whiteSpace: 'normal', wordBreak: 'break-all' }}>Type</TableCell>
                                            <TableCell style={{ maxWidth: 550, border: 'none', padding: 2, whiteSpace: 'normal', wordBreak: 'break-all' }}>{iconComponent}&nbsp; {history[0].itemType}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ maxWidth: 300, border: 'none', padding: 2, whiteSpace: 'normal', wordBreak: 'break-all' }}>Path</TableCell>
                                            <TableCell style={{ maxWidth: 550, border: 'none', padding: 2, whiteSpace: 'normal', wordBreak: 'break-all' }}>{history[0].objectId}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan='2' style={{ maxWidth: 300, border: 'none', padding: 2, whiteSpace: 'normal', wordBreak: 'break-all' }}>

                                                <br />
                                                <Table>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell style={{ maxWidth: 78, border: 'none', padding: 2, whiteSpace: 'normal', wordBreak: 'break-all' }}>
                                                                <InfoIcon style={{ color: 'blue' }} />
                                                            </TableCell>
                                                            <TableCell style={{ maxWidth: 772, border: 'none', padding: 2, whiteSpace: 'normal', wordBreak: 'break-all' }}>
                                                                Note: Your Organization's Audit Data was last added to the vault on: {getTenantsRecordedTime2(lastSuccessfulRunDate)}.
                                                                <br />
                                                                There may be a delay for when the most recent audit data activity entries are made available here, please check back if you are looking for the latest audit activity.
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>

                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <hr />
                        </div>
                    )
                }

                { /* Display access denied, or item not found messaging. 
                     For debugging purposes to enable debugging just add || displayAuditRecord == true to the first if statement below.
                     This will display the status in alert for all conditions instead of just when not displaying the audit record in case of error / lack of access.
                */}
                {
                    (displayAuditRecord == false) &&
                    (
                        <>
                            <div>
                                <Alert severity="error">
                                    {
                                        (usingAuditVaultPermsToAuthenticate == true && history != null && history.length > 0) ?
                                            (
                                                <>
                                                    This Tenant's settings require you to have Audit Vault for M365 - Tenant View permissions role.
                                                    {
                                                        usingAuditVaultPermsHasTenantViewAccess == false ?
                                                            (
                                                                <><br />Sorry, it appears that you do not have the necessary Audit Vault for M365 permissions to access this item, please contact your Audit Vault Administrator if you have questions.</>
                                                            )
                                                            :
                                                            (
                                                                <><br />SUCCESS - User has Tenant View perms to the Tenant.</>
                                                            )
                                                    }
                                                </>
                                            )
                                            :
                                            (usingAuditVaultPermsToAuthenticate == false && history != null && history.length > 0) ?
                                                (
                                                    <>
                                                        This Tenant's settings require you to have SharePoint Online permissions to the requested item.
                                                        {
                                                            usingSPOPermsHasAccess == false ?
                                                                (
                                                                    <><br />Sorry, it appears that you do not have the necessary SharePoint permissions to access this item, reason: {graphData && graphData.error.code ? graphData.error.code : 'Access Denied'}.</>
                                                                )
                                                                :
                                                                (
                                                                    <><br />SUCCESS - User does have view permissions to the item in SharePoint.</>
                                                                )
                                                        }
                                                    </>
                                                )
                                                :
                                                (
                                                    <>No such record was found matching your request OR there are no audit entries to display for the item at this time.<br />Note: If your SharePoint Item is new it may take a few minutes for the Item's audit entries to show up, please check back.</>
                                                )

                                    }
                                </Alert>
                            </div>
                        </>
                    )
                }

                { /* Display item details. */}
                {
                    displayAuditRecord == true && history != null && history.length > 0 &&
                    (
                        <div>
                            <DisplayExportableDataSource columns={columns} reportResults={history} csvFilename={csvFilename} tenantId={tenantId} reportType={"SharePointViewAuditHistory"} user={user} />
                        </div>
                    )
                }

            </>
        )
    }



}
export default ReportsFromSpoViewSharePointAuditHistory;