// React Components
import * as React from 'react';
import { useRef } from "react";
import { useIsAuthenticated } from "@azure/msal-react";


// Material UI Components
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import { CardActions, Grid } from '@mui/material';
import Paper from '@mui/material/Paper';
import Shop2Icon from '@mui/icons-material/Shop2';

// Audit Vault Components
import { COMPANY_SIZES } from "../../constants/constants-companysizes";
import { COUNTRIES } from "../../constants/constants-countries";
import signInWithMsLogo from '../../imgs/buttonSignInWithMicrosoft.svg'
import { SUPPORT_EMAIL_TO_RECEIVE_SETUP_REQUESTS } from '../../constants/constants-email';
import { LINK_TO_TERMS_OF_SERVICE, LINK_TO_PRIVACY_POLICY } from '../../constants/constants-links';

export const CompanySetUpStep1 = (props) => {

    const { 
      stripeProducts,
      product, setProduct,
      price, setPrice,
      billingInterval, setBillingInterval,
      companyNameValue, setCompanyNameValue, errorCompanyName, setCompanyNameError, 
      companySizeValue, setCompanySizeValue, companySize,
      firstNameValue, setFirstNameValue, errorFirstName, setFirstNameError, 
      lastNameValue, setLastNameValue, errorLastName, setLastNameError, 
      emailValue, setEmailValue, errorEmail, setEmailError,
      phoneValue, setPhoneValue, errorPhone, setPhoneError, 
      countryValue, setCountryValue, country,
      agreeToTermsValue, setAgreeToTermsValue, errorAgreeToTerms, setAgreeToTermsError,
      companySetupId,
      setLoading,
      setServiceIsDownError,
      validateCompanyInfoHandler,
      handleInsert
    } = props

    const isAuthenticated = useIsAuthenticated();

    //const promoCodeValue = useRef("");

    const companyDetailsTextFields = [
      { key: 'txtCompanyName', 
        name: 'companyName', 
        label: 'Company Name:', 
        value: companyNameValue, 
        onchange: setCompanyNameValue, 
        error: errorCompanyName, 
        errText: 'Company Name cannot be empty.', 
        descText: '', 
        placeholderText: 'Enter your company name here.' },
    ];

    const contactTextFields = [
      { key: 'txtFirstName', 
        name: 'firstName', 
        label: 'First Name:', 
        value: firstNameValue, 
        onchange: setFirstNameValue, 
        error: errorFirstName, 
        errText: 'First Name cannot be empty.', 
        descText: '',
        placeholderText: 'Enter your first name here.' },

      { key: 'txtLastName', 
        name: 'lastName', 
        label: 'Last Name:', 
        value: lastNameValue, 
        onchange: setLastNameValue, 
        error: errorLastName, 
        errText: 'Last Name cannot be empty.', 
        descText: '',
        placeholderText: 'Enter your last name here.' },

      { key: 'txtEmailName', 
        name: 'email', 
        label: 'Email:', 
        value: emailValue, 
        onchange: setEmailValue, 
        error: errorEmail, 
        errText: 'Email must be valid and cannot be empty.', 
        descText: '',
        placeholderText: 'Enter your contact email address here.' },

      { key: 'txtPhoneName', 
        name: 'phone', 
        label: 'Business Phone:', 
        value: phoneValue, 
        onchange: setPhoneValue, 
        error: errorPhone, 
        errText: 'Business Phone number cannot be empty.', 
        descText: '',
        placeholderText: 'Enter your business phone number here.' },
    ];

function updateProductInfo(val)
{
  setCompanySizeValue(val);
  for (let i = 0; i < stripeProducts.length; i++) {
    if (val > stripeProducts[i].minUserCount)
    {
      setProduct(stripeProducts[i]);
      for (let x = 0; x < stripeProducts[i].productPrices.length; x++) {
        if (stripeProducts[i].productPrices[x].billingInterval == billingInterval)
        {
            setPrice(stripeProducts[i].productPrices[x]);
            console.log(stripeProducts[i].productPrices[x]);
        }
    }
      break;
    }
  }
}



  return (
    
    <><Grid container spacing={2}>
      <Grid sx={{ minWidth: 500, width: "50%", padding: 2 }}>
      <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%' }}>
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6" component="div">1: Your Company Details</Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary" component="div">Tell us about your company.</Typography>
            {companyDetailsTextFields.map((txtfld) => (
              <Typography key={txtfld.key} component="div">
                <b>{txtfld.label}</b>
                <br></br>
                <TextField required
                  name={txtfld.name}
                  placeholder={txtfld.placeholderText}
                  variant="outlined"
                  size="small"
                  style={{ width: 350 }}
                  InputLabelProps={{ shrink: true }}
                  value={txtfld.value}
                  onChange={event => txtfld.onchange(event.target.value)}
                  error={txtfld.error}
                  helperText={txtfld.error ? txtfld.errText : txtfld.descText} />
              </Typography>
            ))}
            <b>Company Size:</b><br></br>
            <TextField required
              select
              name="companySize"
              variant="outlined"
              size="small"
              style={{ width: 350 }}
              InputLabelProps={{ shrink: true }}
              inputRef={companySize}
              value={companySizeValue}
              helperText="The number of people in your Microsoft 365 Tenant"
              onChange={event => updateProductInfo(event.target.value)}
            >
              {COMPANY_SIZES.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <Typography component="div"><br></br></Typography>
            <Typography variant="h6" component="div">Contact Information </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary" component="div">Provide us your contact information.</Typography>
            {contactTextFields.map((txtfld) => (
              <Typography key={txtfld.key} component="div">
                <b>{txtfld.label}</b>
                <br></br>
                <TextField required
                  name={txtfld.name}
                  placeholder={txtfld.placeholderText}
                  variant="outlined"
                  size="small"
                  style={{ width: 350 }}
                  InputLabelProps={{ shrink: true }}
                  value={txtfld.value}
                  onChange={event => txtfld.onchange(event.target.value)}
                  error={txtfld.error}
                  helperText={txtfld.error ? txtfld.errText : txtfld.descText} />
              </Typography>
            ))}
            <Typography key='companyCountry' component="div">
              <b>Country:</b>
              <br></br>
              <TextField required
                select
                value={countryValue}
                onChange={event => setCountryValue(event.target.value)}
                name="country"
                variant="outlined"
                size="small"
                style={{ width: 350 }}
                InputLabelProps={{ shrink: true }}
                inputRef={country}>
                {COUNTRIES.map((option) => (
                  <MenuItem key={option.label} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Typography>
            <Typography key='companyAgreeToTerms' component="span">
            {errorAgreeToTerms && (<div><Alert severity="error">
              <AlertTitle>Please agree to the Terms of Service and Privacy Policy.</AlertTitle>
            </Alert>
            </div>)}
            <div>
              <Checkbox required
                checked={agreeToTermsValue}
                onChange={event => { setAgreeToTermsValue(event.target.checked); } }
                name="agreeToTermsValue"
                controlid="agreeToTermsValue" />I attest as an authorized representative of Company and agree to the <a href={LINK_TO_TERMS_OF_SERVICE} target="_blank" rel="noopener noreferrer">Terms of Service</a> and <a href={LINK_TO_PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
            </div>
          </Typography>
          </CardContent>
          <CardActions>
            {!isAuthenticated && (
            <>
            <Typography component="div" sx={{border: 1, borderColor: 'lightgrey'}}>
            <Typography component="div" sx={{padding: 3}}><b>We need to verify your information:</b><br />Please sign in with your existing Microsoft Account tied to the Organization you want to manage.<br/>This account will be assigned as the initial Company Admin for this App (you can change this later). 
            <Typography component="div" sx={{textAlign : 'center', paddingTop: '20px'}}>
            <Button onClick={() => handleInsert()} sx={{ padding: '3px', '&:hover': { backgroundColor: 'lightblue' } }}>
            <img src={signInWithMsLogo} alt="Sign in with Microsoft" />
            </Button>
            </Typography>
            </Typography>
            </Typography>
            </>
            )}
            {isAuthenticated && (
              
              <Typography component="div" sx={{textAlign : 'left', width: '100%'}}>
                <Alert severity="success">
                  <AlertTitle>Account Verified</AlertTitle>
                  Please click on the Next button to proceed.
                  <br />Note: Your current M365 Login account will be assigned as the initial Company Admin for this App (you can change this later).
                </Alert>
              </Typography>
            )
            }
          </CardActions>
        </Card>
        </Paper>
      </Grid>
      <Grid sx={{ minWidth: 500, width: "50%", padding: 2 }}>
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6" component="div">Product Highlights</Typography>
            <hr />
            “Audit Vault for M365 empowers organizations to thrive in highly regulated environments by preserving, shielding, and intelligently surfacing your audit history information where and when you need it.”
            <br /><br />
            Benefits:
            <ul>
              <li>Eliminate worry about losing your audit history - Audit Vault for M365 preserves + secures your SharePoint and M365 audit history for as long as you require</li>
              <ul>
                <li>Preserve and prove legal chain of custody for your vital documents and records</li>
                <li>Remove concerns around losing your audit history after 90/180 days or 1 year (E3 and E5 defaults for M365), realize costs savings from not having to upgrade to E5 licensing (to obtain 1 year audit history preservation), and no need for complex configuration and guess-work around using 10 year audit retention rules!</li>
              </ul>
              <li>Enable your organization to monitor, report and search on your M365 audit history</li>
              <li>Expose and surface viewing "Audit History" in SharePoint Online to empower your end users, power users and SMEs to see item history within SharePoint</li>
              <li>Low cost and effective solution to unlock insights into your content, trends, and security recommendations</li>
              <li>Eliminate compliance risk and ensure your organization is compliant with policies, rules, and regulations like GDPR, GxP, GCP, FDA 21 CFR Part 11, HIPAA, Sarbanes-Oxley, and ISO 9001</li>
              <li>Easily manage who in your organization can access your Audit information</li>
            </ul>
            <hr />
            <Shop2Icon style={{ color: 'green' }} />&nbsp; <b><u><span style={{ color: 'green' }}>Try free for 14 days!</span></u></b>&nbsp; Your free trial period is for 14 days after which payment will be due.
            <br /><br /><b>Optional:</b> Concierge setup and quick-start service is available if you prefer concierge service to get started (get your solution setup with training for your Administrator(s)).&nbsp; <a href={`mailto:${SUPPORT_EMAIL_TO_RECEIVE_SETUP_REQUESTS}?subject=${encodeURIComponent('Concierge Setup Inquiry for Audit Vault for M365')}`}>Contact us</a> to find out more and schedule a time if you require this add-on service.
          </CardContent>
          <CardActions>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
    </>
  );
}

export default CompanySetUpStep1;