// React Components
import { useState } from "react";
import { CardElement,  useStripe, useElements } from '@stripe/react-stripe-js';
import { useNavigate } from "react-router-dom";
import axios from "axios";

// Material UI Components
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid'; 
import Typography from '@mui/material/Typography';

// Audit Vault Common
import { encryptKey } from "../../utilities/common-encrypt-util";

// Audit Vault Constants
const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;
const TRIAL_DAYS = process.env.REACT_APP_TRIAL_DAYS;

function PaymentForm(props) {

  const { 
    companyId, 
    authorizedByTenantId,
    product,
    price,
    billingCustomerName,
    billingCustomerPhone,
    billingCustomerEmail, 
    billingCustomerAddressLine1, 
    billingCustomerAddressLine2, 
    billingCustomerCity, 
    billingCustomerProvince, 
    billingCustomerPostalCode, 
    billingCustomerCountry, 
    promoCode,
    handleBack,
    activeStep,
    setLoading
 } = props;

  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentError, setPaymentError] = useState(false);
  const [paymentErrorMsg, setPaymentErrorMsg] = useState("");

  const cardElementOptions = {
    style: {
      base: {
        color: "#000000",
        fontSize: "17px",
      },
      invalid: {
        color: "#fa755a",
        fontSize: "17px",
      },
      iconStyle: 'solid',
    }
  }

const stripePromise = useStripe(process.env.REACT_APP_PUBLIC_STRIPE_PUBLISHABLE_KEY);

const handleSubmit = async (event) => {
    event.preventDefault();
    setPaymentError(false);
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Handle error or display a loading state.
      return;
    }

    setIsProcessing(true);
    setLoading(true);

    // Create a PaymentMethod using the CardElement.
    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });
   

    if (result.error) {
      // Handle error, display error message, etc.
      setPaymentError(true);
      setPaymentErrorMsg(result.error.message);
      setIsProcessing(false);
      setLoading(false);
    } else {
      // PaymentMethod created successfully.
      // Send the PaymentMethod ID to your backend server for further processing.
      const paymentMethodId = result.paymentMethod.id

      // create the customer
      var payload =`{
        "companyId": ${companyId},
        "description": "${await encryptKey(billingCustomerName)}",
        "email": "${billingCustomerEmail}",
        "phone": "${billingCustomerPhone}",
        "name": "${billingCustomerName}",
        "paymentMethodId": "${await encryptKey(paymentMethodId)}",
        "currency": "usd",
        "discount": "",
        "city": "${billingCustomerCity}",
        "country": "${billingCustomerCountry}",
        "addressLine1": "${billingCustomerAddressLine1}",
        "addressLine2": "${billingCustomerAddressLine2}",
        "postalZipCode": "${billingCustomerPostalCode}",
        "provinceState": "${billingCustomerProvince}"
      }`;

      try
      {
        var customerId = await axios.post(`${WEB_API_URL}Billing/create-customer`, null, {
          params: {
              key: payload,
          }
        });

        if (customerId) {
          // create the subscription
          var payload2 = `{
            "StripeCustomerId": "${await encryptKey(customerId.data.id)}",
            "Description": "${await encryptKey(product.name)}",
            "Items": [
              {
                "PriceId": "${price.priceId}"
              }
            ],`;

            if (promoCode && promoCode.couponId != undefined)
            {
              payload2 = payload2 + `"Discount": {
                "CouponId": "${promoCode.couponId}"
              },`
            }
            
            payload2 = payload2 + `
            "TrialPeriodInDays": ${TRIAL_DAYS}
            }`;
            
            var subscriptionId = await axios.post(`${WEB_API_URL}Billing/create-subscription`, null, {
              params: {
                  key: payload2,
              }
            });
            if (subscriptionId) {
              console.log(`/mycompany-checkoutcomplete/${companyId}/${price.priceId}/${product.dbUnitsPerTenant}/${product.iconStyled}?redirect_status=Successfully Subscribed&customer_id=${customerId.data.id}&tenant_id=${authorizedByTenantId}&subscription_id=${subscriptionId.data.id}`)
              //success
              navigate(`/mycompany-checkoutcomplete/${companyId}/${price.priceId}/${product.dbUnitsPerTenant}/${product.id}?redirect_status=Successfully Subscribed&customer_id=${customerId.data.id}&tenant_id=${authorizedByTenantId}&subscription_id=${subscriptionId.data.id}`)
            }
        }
      }
      catch (err)
      {
        console.log("ERROR: PaymentForm.handleSubmit")
        console.log(err);
        setPaymentError(true);
        setIsProcessing(false);
      }
      
    }
  };

  if (paymentError)
  {
    return(<>
    <Alert severity="error"><AlertTitle>Unable to Process Payment</AlertTitle>{paymentErrorMsg}  Please click the back button and try again.</Alert>
    <br />
    <Button
              color="inherit"
              variant="contained"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
          >
              Back
          </Button>
    </>);
  }

  return (
    
    <form onSubmit={handleSubmit} width='100%'>
      <div
        style={
          {
          border: '1px solid lightgray',
          padding: '10px'
          }
        }
      >
      <elements stripe={stripePromise}>
      <CardElement options={cardElementOptions} />
      </elements>
      </div>
      <Grid container spacing={3} sx={{paddingTop: '20px'}}>
        <Grid item xs={6}>
        <br />
        <Typography component="span" sx={{textAlign: "right"}}>
        {!isProcessing && 
            <Button
            color="inherit"
            variant="contained"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
            type="button"              
        >
            Back
        </Button>
          }
          {isProcessing &&
            <>
            <Button
              color="inherit"
              variant="contained"
              disabled="true"
              onClick={handleBack}
              sx={{ mr: 1 }}
              type="button"              
          >
              Back
          </Button>
            </>
          }
          
        </Typography>
        </Grid>
        <Grid item xs={6} sx={{textAlign: "right"}}>
        <br />
        <Typography component="span" sx={{verticalAlign: 'middle'}}>
          {!isProcessing && 
            <Button variant="contained" type="submit">Start 14 Day Trial</Button>
          }
          {isProcessing &&
            <>
            <Button variant="contained" disabled="true">Processing ... </Button>
            </>
          }
        </Typography>
        </Grid>
      </Grid>
    </form>
  );
}

export default PaymentForm;
