
/*
Created = 0,            // first created by user 
PaymentComplete = 1,    // Payment completed by Authorized user at organization
TenantCreated = 2,      // Tenant Created
GrantedAccess = 3,      // Global Admin granted access to Audit Vault to Tenant
SetupComplete = 4       // Set up complete and first audit ran successfully
*/

const COMPANY_SETUP_STAGES = [
    { value: 0, label: '0 of 4: Company created but not setup.' },
    { value: 1, label: '1 of 4: Payment completed by Authorized user at organization.' },
    { value: 2, label: '2 of 4: Tenant created.' },
    { value: 3, label: '3 of 4: Global Admin granted access to Audit Vault to Tenant; need to run first audit synch.' },
    { value: 4, label: '4: Company is Live.' },
];

export { COMPANY_SETUP_STAGES };