// React Components
import React, { useState, useEffect } from "react";

// Material UI Components
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Shop2Icon from '@mui/icons-material/Shop2';

// Stripe Components
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from "./PaymentForm";

// Audit Vault Components
import ProgressBar from "../common/ProgressBar";
import StripeIsDown from "../common/StripeIsDown";
import ServiceIsDown from "../common/ServiceIsDown";

// Audit Vault Utilities
import currencyFormatter from "../../utilities/common-currency-utils";
import { createPaymentIntent } from "../../utilities/common-getBillingInfo";

// Audit Vault Constants
// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
import { LINK_TO_SAAS_CUSTOMER_AGREEMENT } from "../../constants/constants-links";
const stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_STRIPE_PUBLISHABLE_KEY);
const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

function MyCompanyCheckout(props) {
    const { 
        stripeProducts,
        companyId, 
        authorizedBy, 
        authorizedByTenantId,
        product, 
        price,
        billingInterval, setBillingInterval, 
        billingCustomerName, 
        billingCustomerPhone, 
        billingCustomerEmail, 
        billingCustomerAddressLine1, 
        billingCustomerAddressLine2, 
        billingCustomerCity, 
        billingCustomerProvince, 
        billingCustomerPostalCode, 
        billingCustomerCountry,
        promoCode,
        totalPrice, setTotalPrice,
        handleBack, 
        activeStep} = props;

    const [clientSecret, setClientSecret] = useState("");
    const [loading, setLoading] = useState(true); 
    const [stripeError, setStripeError] = useState(false);
    const [stripeErrorMsg, setStripeErrorMsg] = useState(false);
    const [serviceIsDown, setServiceIsDown] = useState(false); 

    useEffect(() => {
        async function fetchData() {
            if (product)
            {           
                try
                {
                    var result = await createPaymentIntent(product.id, price.priceId, authorizedBy); 
                    if (result) {
                        setClientSecret(await result.clientSecret);
                    }
                    else
                    {
                        setStripeError(true);
                        setStripeErrorMsg(result);
                    }
                }
                catch (e)
                {
                    console.log("ERROR: MyCompanyCheckout.fetchData");
                    console.log(e);
                    setServiceIsDown(true);
                }
                finally
                {
                    setLoading(false);
                }
            
            }}
        fetchData();
    }, []);


    if (stripeError)
    {
        return (<StripeIsDown msg={stripeErrorMsg} />);
    }

    if (serviceIsDown)
    {
        return (<ServiceIsDown></ServiceIsDown>);
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={7} key="grdOrderInfo" sx={{minWidth: '425px'}}>
            <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%' }}>
                    <Card sx={{ width: '100%', height: '100%'}} variant="outlined">
                    <CardContent key="cardOrderInfo">
                        <Typography variant="h6" component="div">3. Complete Order</Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary" component="div">Please provide your credit card information.<br/>When ready, click the "Start 14 Day Trial" button to start using Audit Vault for M365.</Typography>                
                        <Typography variant="h6" component="div"><br></br></Typography>
                    {loading && (<ProgressBar loading={loading}></ProgressBar>)}
                    {stripePromise && clientSecret && (
                        <Elements stripe={stripePromise} options={{clientSecret}}>
                            <PaymentForm 
                                companyId={companyId} 
                                authorizedByTenantId={authorizedByTenantId}
                                product={product} 
                                price={price}
                                billingCustomerName={billingCustomerName} 
                                billingCustomerPhone={billingCustomerPhone} 
                                billingCustomerEmail={billingCustomerEmail} 
                                billingCustomerAddressLine1={billingCustomerAddressLine1} 
                                billingCustomerAddressLine2={billingCustomerAddressLine2} 
                                billingCustomerCity={billingCustomerCity} 
                                billingCustomerProvince={billingCustomerProvince} 
                                billingCustomerPostalCode={billingCustomerPostalCode} 
                                billingCustomerCountry={billingCustomerCountry}
                                promoCode={promoCode}
                                handleBack={handleBack}
                                activeStep={activeStep}
                                setLoading={setLoading}
                            />
                        </Elements>
                    )}
                    </CardContent>
                    </Card>
                    </Paper>
                </Grid>
                <Grid item xs={5} key="grdPriceTotal" sx={{minWidth: '425px'}}>
                    <Card sx={{ width: '100%', height: '100%' }} variant="outlined">
                        <CardContent>
                        <Typography variant="h6" component="div">Total Price</Typography>
                        <hr />
                        <Shop2Icon style={{ color: 'green' }} />&nbsp; <b><u><span style={{ color: 'green' }}>Try free for 14 days!</span></u></b>&nbsp; Your free trial period is for 14 days after which payment will be due.
                        <Typography variant="h6" component="div"><br></br></Typography>
                        <Typography><b>Selected Subscription Plan:</b></Typography>
                        
                            <>
                            <Typography>{product.name}</Typography>
                            <div style={{ fontSize: '0.8rem' }}>{product.description}</div>
                            </>
                        
                        {promoCode && 
                            (
                                <>
                                <Typography sx={{paddingTop: '5px', paddingBottom:'5px'}}>Discount Applied: <b>{promoCode.couponName}</b></Typography>
                                </>
                            )                                                       
                        }
                        <a href={LINK_TO_SAAS_CUSTOMER_AGREEMENT} target="_blank" rel="noopener noreferrer">View Customer Agreement</a>                        
                        <Typography variant="h6" component="div"><br></br></Typography>
                        <Typography>Total Price: {currencyFormatter(totalPrice)}/{billingInterval} (USD)<br />Paid every {billingInterval}. 
                        {billingInterval && billingInterval == 'month' && ( <> Minimum 1 year committment.</>) }
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}
export default MyCompanyCheckout;