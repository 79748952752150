// React Components
import * as React from 'react';
import { useParams } from "react-router-dom";

// Audit Vault Components
import CompanySetup from '../components/companysetup/CompanySetUp';

function MyCompanySetup(props) {

    // This page is refreshed with params after login.  Keeps the setupId for continuing with company setup
    const { user } = props;

    const { companySetupId } = useParams();

    return (<CompanySetup user={user} setupId={companySetupId}></CompanySetup>)
}
export default MyCompanySetup;
