// React Components
import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import PropTypes from 'prop-types';

// Material UI Components
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

// Audit Vault Components
import AccessDenied from '../../components/common/AccessDenied';
import ServiceIsDown from '../../components/common/ServiceIsDown';
import ProgressBar from '../../components/common/ProgressBar';
import DeleleCompanyConfirmation from '../../components/company/DeleteCompanyConfirmation';
import DisplaySectionTitleAndDescription from '../../components/common/DisplaySectionTitleAndDescription';
import DisplayBreadCrumb from '../../components/common/DisplayBreadCrumb';
import { COMPANY_SETUP_STAGES } from "../../constants/constants-companysetupstages";
import TenantDBStats from "../../components/TenantDBStats";
import NextBillingDate from '../../components/payments/NextBillingDate.jsx';

// Audit Vault Utilities
import { NumberBadge } from '../../utilities/common-ui-utils.js';
import { linkSystemAdminToCompany } from '../../utilities/common-user-utils.js';
import { encryptKey } from '../../utilities/common-encrypt-util';

// Audit Vault Constants
const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

// Section titles.
const theSectionTitle = "Company List - Administration";
const theSectionDesc = "Below are all the Companies registered to use the Audit Vault for M365 App.  Click on a Company Name to view the Company details.  Perform Administrative functions on a Company from this page.";
// Breadcrumb.
const theBreadcrumbPath = [
    { name: 'Home', link: '/' },
    { name: 'Admin', link: '/Admin/AdminHome' },
    { name: 'Company List', link: '/Admin/CompanyListAdmin' }
];

const columns = [
    { id: 'id', label: 'Id', minWidth: 50, align: 'left' },
    { id: 'companyName', label: 'Company Name', minWidth: 200 },
    { id: 'companySize', label: 'Size', minWidth: 50 },
    { id: 'country', label: 'Country', minWidth: 100 },
    { id: 'setupStageComplete', label: 'Setup Stage', minWidth: 50 },
    { id: 'tenantInfo', label: 'Tenant Info', minWidth: 200 },
    { id: 'paymentStatus', label: 'Payment Status', minWidth: 50 },
    { id: 'nextBillDate', label: 'Next Bill Date', minWidth: 50 },
    { id: 'action', label: 'Action', minWidth: 10 }
];

const rows = [];

// Function for Sorting column headers.
function getComparator(sortConfig) {
    return (a, b) => {
        if (!sortConfig) {
            return 0;
        }
        if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
    };
}

// Function for Sorting column headers.  
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


function CompanyListAdmin(props) {

    const [isAdminPage] = useState(true);

    const { user } = props;

    // Component Constants
    const navigate = useNavigate();
    const [companies, setCompanies] = useState("");
    const [loading, setLoading] = useState(true);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);
    const [accessDenied, setAccessDenied] = useState(true);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [sortConfig, setSortConfig] = useState(null);  // Used for sorting column header.

    const [itemToDeleteId, setItemToDeleteId] = useState(0);
    const [companyNmToDelete, setcompanyNmToDelete] = useState("");
    const [open, setOpen] = React.useState(false);
    const [expandedRow, setExpandedRow] = useState(null);  // For Accordion control. 


    // For snackbar notification UI element.  Company deleted notification.
    const [openSnackbarNotification, setOpenSnackbarNotification] = useState(false);  // Add React hook to add state to a functional component.  It returns the current state, and a function to update it.

    // React Menu specific items.  Each row in the data table has it's own anchor element (button) hence why we need an array of hooks.
    const [anchorEls, setAnchorEls] = React.useState({});
    const [menuSelectedRowId, setMenuSelectedRowId] = React.useState(null);

    let countSetupCompleted = 0;
    let countSetupInProgress = 0;
    let countPaymentUpToDate = 0;
    let countPaymentDelinquent = 0;

    // Component Functions

    async function fetchAllCompanyData() {

        if (user && user.isSystemAdmin) {
            setAccessDenied(false);
            setLoading(true);
            try {
                var result;
                var data = {
                    sysAdminId: user.microsoftGraphId,                
                };                                                
                var keyString = await encryptKey(JSON.stringify(data));
                if (keyString) 
                {                                                
                    result = await axios.get(`${WEB_API_URL}AdminUtilities/GetCompanies`, {
                        params: {
                            key: keyString,
                        }
                    }); 
                    if(result) {
                        console.log(result);
                        setCompanies(result.data);
                        setLoading(false);
                    }
                }
            }
            catch (e) {
                console.log("ERROR: CompanyListAdmin.fetchAllCompanyData");
                console.log(e);
                setAccessDenied(true);
            }
            finally {
                setLoading(false);
            }
        }
        else {
            setAccessDenied(true);
        }
    }

    useEffect(() => {
        fetchAllCompanyData();
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    // Accordion row clicked.  Toggles the company info accordion open or closed.
    const handleMenuChooseView = (id) => {
        handleMenuClose(id);  // Close the menu nicely first.     
        if (!(expandedRow === id)) {
            // Only view if not opened.
            setExpandedRow(id);
        }
    };


    // Accordion row clicked.  Toggles the company info accordion open or closed.
    const handleRowClick = (id) => {
        if (expandedRow === id) {
            setExpandedRow(null);
        }
        else {
            setExpandedRow(id);
        }
    };


    // Functions for Delete Dialog
    const handleClose = (value) => {
        setOpen(false);
        setItemToDeleteId(0);
        setcompanyNmToDelete("");
    };

    function handleClickOpen(val, compNm) {
        handleMenuClose(val);  // Close the menu nicely first. 
        setItemToDeleteId(val);
        setcompanyNmToDelete(compNm);
        setOpen(true);
    };

    DeleleCompanyConfirmation.propTypes = {
        onClose: PropTypes.func.isRequired,
        open: PropTypes.bool.isRequired,
        companyId: PropTypes.number.isRequired,
        companyNm: PropTypes.string.isRequired,
    };


    // For snackbar close action.
    const handleSnackbarNotificationClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbarNotification(false);
    }

    async function handleLinkAccountToCompany(companyId, setAsCompanyAdmin) {

        if (user && user.isSystemAdmin) {
            setAccessDenied(false);
            setLoading(true);  // Progress bar.
            try {
                var response = await linkSystemAdminToCompany(companyId, user.microsoftGraphId, setAsCompanyAdmin);
                if (response) {
                    navigate(0)
                }
            }
            catch (e) {
                console.log("ERROR: CompanyListAdmin.handleLinkAccountToCompany");
                console.log(e);
                setServiceIsDownError(true);
            }
            finally {
                setLoading(false);
            }
        }
        else {
            setAccessDenied(true);
        }
    }

    // For snackbar notification action.
    const openSnackbarNotificationAction = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleSnackbarNotificationClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    )

    // Menu functions - event handler for when a specific menu object is clicked.
    const handleMenuClick = (event, rowId) => {
        setAnchorEls((prevAnchorEls) => ({
            ...prevAnchorEls,
            [rowId]: event.currentTarget,
        }));
        setMenuSelectedRowId(rowId);
    };

    // Menu functions - when a specific menu is closed.
    const handleMenuClose = (rowId) => {
        setAnchorEls((prevAnchorEls) => ({
            ...prevAnchorEls,
            [rowId]: null,
        }));
    };

    // Column header sort function.
    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };


    // Style.
    const iconStyle = {
        color: 'black',
        textDecoration: 'none',
    };


    // Component UI
    if (loading) {
        return (<ProgressBar message="Loading ..." loading={loading} />);
    }

    if (accessDenied) {
        return (<AccessDenied></AccessDenied>);
    }

    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }

    if (loading == false && companies) {
        // Analysis - count up all the Company statistics we are looking for.    
        // Loop through each company and count up the stats we want to summarize.  If there are any issues displaying, we may wish to switch to use the response object in the fetch section, as this would use the object directly versus waiting for the company object to be set.
        (companies).forEach((row) => {
            let checkVal1 = row['setupStageComplete'];
            let checkVal2 = row['customerStripeInfo'];
            console.log("MK1: Company: " + row['companyName'] + ", Customer Stripe Info: " + checkVal2);
            if (checkVal1 === 4) {
                countSetupCompleted++;
            }
            else {
                countSetupInProgress++;
            }
            if (checkVal2) {
                if (checkVal2.isDelinquent == false) {
                    countPaymentUpToDate++;
                }
                else {
                    countPaymentDelinquent++;
                }
            }
            else {
                countPaymentDelinquent++;
            }
        });


        return (
            <>
                <DeleleCompanyConfirmation
                    onClose={handleClose}
                    open={open}
                    companyId={itemToDeleteId}
                    companyNm={companyNmToDelete}
                    snackbarNotification={setOpenSnackbarNotification}
                    updateDataFunc={fetchAllCompanyData}
                    user={user}
                />

                <DisplayBreadCrumb paths={theBreadcrumbPath} />
                <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />

                {/* Display Companies summary count section. */}
                <TableContainer style={{ maxWidth: 800 }}>
                    <Table style={{ width: '100%', fontSize: 'inherit', fontWeight: 'inherit' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ border: 'none', padding: 2 }}>Summary</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ border: 'none', padding: 2 }}>Number of companies</TableCell>
                                <TableCell style={{ border: 'none', padding: 2 }}><NumberBadge number={companies.length} bgcolor='blue' txtcolor='white' /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ border: 'none', padding: 2 }}>Setup complete</TableCell>
                                <TableCell style={{ border: 'none', padding: 2 }}><NumberBadge number={countSetupCompleted} bgcolor='green' txtcolor='white' /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ border: 'none', padding: 2 }}>Setup in-progress</TableCell>
                                <TableCell style={{ border: 'none', padding: 2 }}><NumberBadge number={countSetupInProgress} bgcolor='orange' txtcolor='white' /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ border: 'none', padding: 2 }}>Payments up to date</TableCell>
                                <TableCell style={{ border: 'none', padding: 2 }}><NumberBadge number={countPaymentUpToDate} bgcolor='green' txtcolor='white' /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ border: 'none', padding: 2 }}>Payments overdue</TableCell>
                                <TableCell style={{ border: 'none', padding: 2 }}><NumberBadge number={countPaymentDelinquent} bgcolor='red' txtcolor='white' /></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <br />
                <Paper sx={{ width: '100%', minWidth: 1000 }}>
                    {loading &&
                        (
                            <Typography component="span">
                                <ProgressBar loading={loading} message="Deleting Tenant ..." />
                            </Typography>
                        )
                    }
                    <Table sx={{ width: '100%' }}>
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                        onClick={() => requestSort(column.id)}
                                    >
                                        {column.label}
                                        {sortConfig && sortConfig.key === column.id && (
                                            <span>{sortConfig.direction === 'asc' ? ' 🔽' : ' 🔼'}</span>
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                stableSort(companies, getComparator(sortConfig)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        return (
                                            <React.Fragment key={row.id}>
                                                <TableRow hover role="checkbox" tabIndex={-1} key={`RowID-${row.id}`}>
                                                    {columns.map((column) => {
                                                        const value = row[column.id];
                                                        switch (column.id) {
                                                            case 'companyName':
                                                                return (<TableCell key={column.id} align={column.align} onClick={() => handleRowClick(row.id)}>{value ? value.toString() : value}</TableCell>);
                                                                break;
                                                            case 'setupStageComplete':
                                                                // Display the setup stage.                                                    
                                                                var theMatchingStage = COMPANY_SETUP_STAGES.find(stage => stage.value === value);
                                                                var theMatchingStageText = theMatchingStage ? theMatchingStage.label : 'Invalid setup stage code.';
                                                                return (
                                                                    <TableCell key={column.id} align={column.align} onClick={() => handleRowClick(row.id)}>
                                                                        {
                                                                            theMatchingStageText
                                                                        }
                                                                    </TableCell>
                                                                );
                                                                break;
                                                            case 'paymentStatus':
                                                                var paymentStatus = "Unavailable";
                                                                if (row.customerStripeInfo) {
                                                                    if (row.customerStripeInfo.isDelinquent == false)
                                                                        paymentStatus = "Up to Date";
                                                                    else {
                                                                        paymentStatus = "Delinquent";
                                                                    }
                                                                }
                                                                return (<TableCell key={column.id} align={column.align} onClick={() => handleRowClick(row.id)}>{paymentStatus}</TableCell>);
                                                                break;
                                                            case 'nextBillDate':
                                                                return (<TableCell key={column.id} align={column.align} onClick={() => handleRowClick(row.id)}><NextBillingDate user={user} companyId={row.id}></NextBillingDate></TableCell>);
                                                                break;
                                                            case 'tenantInfo':
                                                                if (row.tenantList != null && row.tenantList.length > 0) {
                                                                    return (
                                                                        <TableCell key={column.id} align={column.align} onClick={() => handleRowClick(row.id)}>
                                                                            {row.tenantList.map((tenant) => (
                                                                                <TenantDBStats user={user} tenant={tenant} viewFullSize={false} showTenantUserCount={true} />
                                                                            ))}
                                                                        </TableCell>
                                                                    );
                                                                }
                                                                else {
                                                                    return (
                                                                        <TableCell key={column.id} align={column.align} onClick={() => handleRowClick(row.id)}>
                                                                            No tenant(s) configured.
                                                                        </TableCell>
                                                                    );
                                                                }

                                                                break;
                                                            case 'action':
                                                                // Display the menu for the row item.                            
                                                                return (
                                                                    <TableCell key={column.id} align="left">
                                                                        <Button
                                                                            id={`av365button-${row.id}`}
                                                                            aria-controls={`av365menu-${row.id}`}
                                                                            aria-haspopup="true"
                                                                            aria-expanded={Boolean(anchorEls[row.id])}
                                                                            onClick={(event) => handleMenuClick(event, row.id)}
                                                                        >
                                                                            <MoreVertIcon fontSize="small" style={iconStyle} />
                                                                        </Button>
                                                                        <Menu
                                                                            id={`av365menu-${row.id}`}
                                                                            anchorEl={anchorEls[row.id]}
                                                                            open={Boolean(anchorEls[row.id])}
                                                                            onClose={() => handleMenuClose(row.id)}
                                                                            MenuListProps={{
                                                                                'aria-labelledby': `av365button-${row.id}`,
                                                                            }}
                                                                        >
                                                                            <MenuItem onClick={(event) => handleMenuChooseView(row.id)}>View Company Info</MenuItem>
                                                                            <MenuItem onClick={(event) => navigate(`/Admin/tenant-admin/${row.id}`)}>View Tenants</MenuItem>
                                                                            <MenuItem onClick={(event) => navigate(`/Admin/company-update/${row.id}`)}>Edit</MenuItem>
                                                                            <MenuItem onClick={(event) => handleClickOpen(row.id, row.companyName)}>Delete</MenuItem>
                                                                            {user && user.companyId != row.id &&
                                                                                < MenuItem onClick={(event) => handleLinkAccountToCompany(row.id.toString(), "true")}>Link My Account to Company</MenuItem>
                                                                            }
                                                                            {user && user.companyId == row.id &&
                                                                                < MenuItem onClick={(event) => handleLinkAccountToCompany(row.id.toString(), "false")}>Unlink My Account to Company</MenuItem>
                                                                            }
                                                                        </Menu>
                                                                    </TableCell>
                                                                )
                                                                break;
                                                            default:
                                                                return (
                                                                    <TableCell key={column.id} align={column.align} onClick={() => handleRowClick(row.id)}>
                                                                        {value
                                                                            ? value.toString()
                                                                            : value
                                                                        }
                                                                    </TableCell>
                                                                );
                                                                break;
                                                        }
                                                    })}
                                                </TableRow>
                                                {
                                                    expandedRow === row.id && (
                                                        <TableRow>
                                                            <TableCell colSpan={columns.length}>
                                                                <Accordion>
                                                                    {/* We don't include an AccordionSummary. */}
                                                                    <AccordionDetails>
                                                                        <span style={{ fontWeight: 'bold' }}>Company Details (corresponding row values from the CompanyInfo table)</span><br />
                                                                        {Object.entries(row).map(([key, value], index) => (
                                                                            <div key={index}>{`${key}: ${value}`}</div>
                                                                        ))}
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                }
                                            </React.Fragment>
                                        );
                                    })}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="span"
                        count={companies.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper >
                <br /><Button variant="contained" type="button" onClick={() => navigate("/Admin/Company-create")}>Add a Company</Button>
                <div>
                    <Snackbar
                        open={openSnackbarNotification}
                        autoHideDuration={6000}
                        onClose={handleSnackbarNotificationClose}
                        message="Company Deleted"
                        action={openSnackbarNotificationAction}
                    />
                </div>
            </>
        )

    }

}
export default CompanyListAdmin;
