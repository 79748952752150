// React Components
import { useState } from "react";

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useNavigate } from "react-router-dom";

// Material UI Components
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// Audit Vault Components
import StripeIsDown from "../common/StripeIsDown";
import { updatePaymentMethod } from "../../utilities/common-getBillingInfo";

function UpdatePaymentForm(props) {

  const { stripeCustomerId,
            setSubscription,
            setShowUpdatePayment,
            setUpdatePaymentMsg,
            fetchPaymentMethodData } = props;

const stripe = useStripe();
const elements = useElements();
const navigate = useNavigate();
const [paymentError, setPaymentError] = useState(false);
const [stripeError, setStripeError] = useState(false);
const [stripeErrorMsg, setStripeErrorMsg] = useState(false);

const stripePromise = useStripe(process.env.REACT_APP_PUBLIC_STRIPE_PUBLISHABLE_KEY);
  
const cardElementOptions = {
  style: {
    base: {
      color: "#000000",
      fontSize: "17px",
    },
    invalid: {
      color: "#fa755a",
      fontSize: "17px",
    },
    iconStyle: 'solid',
  }
}

const handleSubmit = async (event) => {
    event.preventDefault();
    setPaymentError(false);
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Handle error or display a loading state.
      return;
    }

    // Create a PaymentMethod using the CardElement.
    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (result.error) {
      // Handle error, display error message, etc.
      console.log(result.error.message);
    } else {
      // PaymentMethod created successfully.
      // Send the PaymentMethod ID to your backend server for further processing.
      const paymentMethodId = result.paymentMethod.id;
      console.log(`PAYMENT METHOD: ${paymentMethodId}`)

      // Update the customers default Payment Method
      try
      {
        var updatePaymentResult = await updatePaymentMethod(stripeCustomerId, paymentMethodId);
        if (updatePaymentResult)
        {
            // Successful
            setSubscription(null);
            setShowUpdatePayment(false);
            setUpdatePaymentMsg(true);
            fetchPaymentMethodData();
        }
      }
      catch (err)
      {
        console.log("ERROR: UpdatePaymentForm.handleSubmit")
        console.log(err);
        setPaymentError(true);
      }
      
    }
  };

  if (stripeError)
  {
    return (<StripeIsDown msg={stripeErrorMsg} />);
  }

  if (paymentError)
  {
    return(<Typography color="red">Unable to process payment.</Typography>);
  }

  return (
    
    <form onSubmit={handleSubmit}>
      <div
        style={
          {
          border: '1px solid lightgray',
          padding: '10px'
          }
        }
      >
      <elements stripe={stripePromise}>
      <CardElement options={cardElementOptions} />
      </elements>
      </div>
      <p><br></br></p>
      <Button variant="contained" type="submit">Update Payment Method</Button>
    </form>
  );
}

export default UpdatePaymentForm;
