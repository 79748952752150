// React Components
import * as React from 'react';
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Audit Vault Components
import AccessDenied from '../components/common/AccessDenied';
import TenantForm from "../components/TenantForm";
import ProgressBar from '../components/common/ProgressBar';
import ServiceIsDown from '../components/common/ServiceIsDown';

// Audit Vault Utilities
import { verifyUserCanAccessTenantData } from '../utilities/common-user-utils';
import { getTenantById } from '../utilities/common-tenant';

function UpdateTenant(props) {

    const { user, setUser } = props;

    const { id } = useParams();

    const [tenant, setTenant] = useState(0);
    const [loading, setLoading] = useState(true);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);
    const [accessDenied, setAccessDenied] = useState(false);

    // Component Functions
    async function fetchData() {
        if (loading && user) {
            console.log(id)
            if (verifyUserCanAccessTenantData(user, id)) {
                try {
                    var response = await getTenantById(id);
                    if (response) {
                        setTenant(response)
                    }
                    setLoading(false);
                }
                catch (e) {
                    console.log("ERROR: UpdateTenant.fetchData");
                    console.log(e);
                    setServiceIsDownError(true);
                }
                finally {
                    setLoading(false);
                };
            }
            else {
                setLoading(false);
                setAccessDenied(true);
            }

        }
    }


    useEffect(() => {
        fetchData();
    }, []);

    // Comnponent UI
    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }

    if (accessDenied) {
        return (<AccessDenied></AccessDenied>);
    }

    if (loading) {
        return (<ProgressBar message="Loading ..." loading={loading} />)
    }

    if (tenant) {
        return (
            <>
                <TenantForm user={user} tenant={tenant} tenantId={id} setUser={setUser}></TenantForm>
            </>
        );
    }

}
export default UpdateTenant;