// React Components
import React, { useState, useEffect } from "react";

//Material UI Components
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// Stripe Components
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import UpdatePaymentForm from "./UpdatePaymentForm";

// Audit Vault Components
import StripeIsDown from "../common/StripeIsDown";
import { createPaymentIntent } from "../../utilities/common-getBillingInfo";

// Audit Vault Constants
// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_STRIPE_PUBLISHABLE_KEY);
const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

function MyBillingUpdatePayment(props) {
    const { stripeCustomerId, 
        companyProducts, 
        setSubscription,
        setShowUpdatePayment,
        setUpdatePaymentMsg,
        fetchPaymentMethodData } = props;

    const [clientSecret, setClientSecret] = useState("");
    const [stripeError, setStripeError] = useState(false);
    const [stripeErrorMsg, setStripeErrorMsg] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    async function fetchData() {
        if (stripeCustomerId)
        {
            console.log(companyProducts)
            var result = await createPaymentIntent(companyProducts[0].stripeProductId, companyProducts[0].stripePriceId, "null");
            if (result) {
                setClientSecret(await result.clientSecret);
            }
            else
            {
                setStripeError(true);
                setStripeErrorMsg(result);
            }
        }
    }

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };

    if (stripeError)
    {
        return (<StripeIsDown msg={stripeErrorMsg} />);
    }

    return (
        <>
        
        <Grid
        container
        marginTop={3}
        spacing={3}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        >
        <Grid item xs={12} >
                <Card sx={{ width: '100%' }} variant="outlined">
                <CardContent>
                    <Typography component="div"><b>Enter Payment Info:</b></Typography>
                    <Typography variant="h6" component="div"><br></br></Typography>
                {clientSecret && (
                    <Elements options={options} stripe={stripePromise}>
                        <UpdatePaymentForm 
                            stripeCustomerId={stripeCustomerId}
                            setSubscription={setSubscription}
                            setShowUpdatePayment={setShowUpdatePayment}
                            setUpdatePaymentMsg={setUpdatePaymentMsg}
                            fetchPaymentMethodData={fetchPaymentMethodData} />
                    </Elements>
                )}
                </CardContent>
                </Card>
            </Grid>
      </Grid>
      </>
    );
}
export default MyBillingUpdatePayment;