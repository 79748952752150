// React Components
import * as React from 'react';
import { useState, useEffect } from "react";
import axios from "axios";

// Material UI Components

// Audit Vault Components
import ProgressBar from '../common/ProgressBar';
import ServiceIsDown from "../common/ServiceIsDown";

// Audit Vault Utilities
import { encryptKey } from '../../utilities/common-encrypt-util';

const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;



export default function DisplayFullAuditLogDetail(props) {

  const { companyId, tenantId, reportType, auditLogId, user } = props;
  const [loading, setLoading] = useState(false);
  const [serviceIsDownError, setServiceIsDownError] = useState(false);
  const [auditLogDetails, setAuditLogDetails] = useState([]);  

  async function fetchAuditLogData() 
  {
    try {
        setLoading(true);

        var usrEmail = "N/A";
        if (user.userEmail)
            usrEmail = user.userEmail;
        
        var payload = `{
            companyId: ${user.companyId},
            tenantId: ${tenantId},
            auditLogId: "${auditLogId}",
            reportType: "${reportType}",                        
            userMicrosoftGraphId: "${await encryptKey(user.microsoftGraphId)}",
            userEmail: "${await encryptKey(usrEmail)}"               
        }`; 
        
        var response = await axios.get(`${WEB_API_URL}Reports/GetAuditLogDetailById`, {
            params: {
                key: `${payload}`
            }
        });
        
        if (response)
        {
          // Set auditLogDetail          
          setAuditLogDetails(response.data);
          // console.log(response)
        }

    }
    catch (e) {
      console.log("ERROR: DisplayFullAuditLogDetails.fetchAuditLogData")
      console.log(e);
      setServiceIsDownError(true);
    }
    finally{
      setLoading(false);
    }
  }

  useEffect(() => {
      fetchAuditLogData();
  }, []);


  // If we are still loading data, display that in the UI...
  if (loading) {
    return (<ProgressBar message="Loading ..." loading={loading} />);
  }

  // Component UI
  if (serviceIsDownError) {
    return (<><ServiceIsDown></ServiceIsDown></>);
  }

  return (
    <>

      <span style={{ fontWeight: 'bold' }}>Audit Log Details</span><br />
      <span style={{ fontSize: '10px' }}>                                                           
        {/*
        CompanyId: { user.companyId }<br />TenantId: { tenantId }<br />ReportType: { reportType }<br />Id: { auditLogId }<br />User: { user.userEmail }<br />VALUES:  
        */
        }
        {
          Object.entries(auditLogDetails).map(([key, value], index) => (
            value !== null && (<div key={index}>{`${key}: ${value}`}</div>)
          ))
        }
      </span>

    </>
  );
    
}
