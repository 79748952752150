import { loginRequest, organizationRequest } from '../authConfig';
import { callMsGraphOrg } from '../graph';
import axios from "axios";
import { encryptKey } from './common-encrypt-util';

const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

export async function loginToMicrosoft(instance) {

    try {
        instance.NavigateToLoginRequestUrl = true;

        var response = instance.loginRedirect(loginRequest);
        if (response) {
            console.log(response);
        }
        else
            console.log("Failed to Log in again.")
    }
    catch (e) {
        console.log(`ERROR Logging In: ${e}`)
    }

}


export async function getUserOrganizationData(instance, isAuthenticated, accounts, orgData, setOrgData) {

    if (orgData != null)
        return orgData;

    if (!isAuthenticated) {
        instance.loginRedirect(loginRequest).catch(e => {
            console.log(e);
        });
    }
    else {
        console.log(`getUserOrganizationData : ${accounts[0]}`)

        const request = {
            ...loginRequest, organizationRequest,
            account: accounts[0]
        };
        var response = await instance.acquireTokenSilent(request);
        if (response != null) {
            console.log(accounts[0])
            const orgSilent = await callMsGraphOrg(response.accessToken)
            if (orgSilent != null) {
                console.log(orgSilent);
                setOrgData(orgSilent);
                return orgSilent;
            }
        }
    }
}


export async function getAuditVaultUser(isAuthenticated, account) {

    if (isAuthenticated) {

        var rsaKey = await encryptKey(`{GraphId: "${account.localAccountId}" }`);
        if (rsaKey) {
            var result = await axios.get(`${WEB_API_URL}User/GetUserByGraphId`, {
                params: {
                    key: `${rsaKey}`
                }
            });

            if (result.data != null && result.data != "") {
                return result.data;
            }
            else {
                rsaKey = (`{GraphId: "${await encryptKey(account.localAccountId)}", AzureTenantId: "${await encryptKey(account.tenantId)}" }`);
                if (rsaKey) {
                    var result2 = await axios.get(`${WEB_API_URL}User/GetUserByAzureTenantId`, {
                        params: {
                            key: `${rsaKey}`
                        }
                    });

                    if (result2.data != null) {
                        return result2.data;
                    }
                }
            }
        }
    }
    return "";
}


export async function searchForCompanyUsersByName(companyId, searchQuery) {

    var rsaKey = `{CompanyId: "${await encryptKey(companyId.toString())}", SearchQuery: "${await encryptKey(searchQuery)}" }`;
    if (rsaKey) {
        var result = await axios.get(`${WEB_API_URL}User/SearchForCompanyUsersByName`, {
            params: {
                key: `${rsaKey}`
            }
        });
        if (result.data != null) {
            return result.data;
        }
    }
}

export async function getAllCompanyUsers(companyId) {

    var rsaKey = `{CompanyId: "${await encryptKey(companyId.toString())}"}`;
    if (rsaKey) {
        var result = await axios.get(`${WEB_API_URL}User/GetAllCompanyUsers`, {
            params: {
                key: `${rsaKey}`
            }
        });
        if (result.data != null) {
            return result.data;
        }
    }
}

export async function getAllSystemUsers(sysAdminId) {

    var rsaKey = `{SysAdminId: "${await encryptKey(sysAdminId)}"}`;
    if (rsaKey) {
        var result = await axios.get(`${WEB_API_URL}AdminUtilities/GetAllSystemUsers`, {
            params: {
                key: `${rsaKey}`
            }
        });
        if (result.data != null) {
            return result.data;
        }
    }
}

export async function getCountOfTenantUsers(companyId, tenantId) {

    var rsaKey = `{CompanyId: "${await encryptKey(companyId.toString())}", TenantId: "${await encryptKey(tenantId.toString())}"}`;
    if (rsaKey) {
        var result = await axios.get(`${WEB_API_URL}AdminUtilities/GetCountOfTenantUsers`, {
            params: {
                key: `${rsaKey}`
            }
        });
        if (result.data != null) {
            return result.data;
        }
    }
}

export async function searchAuditVaultUsers(companyId, tenantId, emailQuery, roleId) {

    var rsaKey = `{CompanyId: "${await encryptKey(companyId.toString())}", TenantId: "${await encryptKey(tenantId.toString())}", EmailQuery: "${await encryptKey(emailQuery)}", RoleId: "${await encryptKey(roleId.toString())}"}`;
    if (rsaKey) {
        var result = await axios.get(`${WEB_API_URL}User/SearchAuditVaultUsers`, {
            params: {
                key: `${rsaKey}`
            }
        });
        if (result.data != null) {
            return result.data;
        }
    }
}

export async function getAuditVaultUsersCompanyAdminsByCompanyId(companyId) {

    var rsaKey = `{CompanyId: "${await encryptKey(companyId.toString())}"}`;
    if (rsaKey) {
        var result = await axios.get(`${WEB_API_URL}User/GetAuditVaultUsersCompanyAdminsByCompanyId`, {
            params: {
                key: `${rsaKey}`
            }
        });
        if (result.data != null) {
            return result.data;
        }
    }
}

export async function getTenantUsersByRole(tenantId, roleId) {

    var rsaKey = `{TenantId: "${await encryptKey(tenantId.toString())}", RoleId: "${await encryptKey(roleId.toString())}"}`;
    if (rsaKey) {
        var result = await axios.get(`${WEB_API_URL}User/GetTenantUsersByRole`, {
            params: {
                key: `${rsaKey}`
            }
        });
        if (result.data != null) {
            return result.data;
        }
    }
}

export async function getSystemAdmins(sysAdminId) {

    var rsaKey = `{SysAdminId: "${await encryptKey(sysAdminId)}"}`;
    if (rsaKey) {
        var result = await axios.get(`${WEB_API_URL}AdminUtilities/GetSystemAdmins`, {
            params: {
                key: `${rsaKey}`
            }
        });
        if (result.data != null) {
            return result.data;
        }
    }
}

export async function createUser(graphId, email, displayName, companyId) {

    var payload = `{
        "id": 0,
        "permissions": [ ],
        "microsoftGraphId": "${await encryptKey(graphId)}",
        "userEmail": "${await encryptKey(email)}",
        "displayName": "${await encryptKey(displayName)}",
        "isSystemAdmin": false,
        "isCompanyAdmin": true,
        "companyId": ${companyId},
        "isActive": true
      }`;

    var response = await axios.post(`${WEB_API_URL}User/create-user`, null, {
        params: {
            key: payload,
        }
    });

    return response;
}


// Use this function to refresh user data.
export async function getUser(accounts, isAuthenticated, setUser) {

    try {
        if (isAuthenticated) {

            var rsaKey = await encryptKey(`{GraphId: "${accounts[0].localAccountId}" }`);
            if (rsaKey) {
                var result = await axios.get(`${WEB_API_URL}User/GetUserByGraphId`, {
                    params: {
                        key: `${rsaKey}`
                    }
                });

                if (result.data != null) {
                    setUser(result.data);
                    return result.data;
                }
            }
        }
    }
    catch (err) {
        console.log("ERROR: common-user-utils.js.getuser");
        console.log(err);
    }
}


export async function removeSystemAdminAccess(sysAdminId, removeSysAdminId) {

    var rsaKey = `{SysAdminId: "${await encryptKey(sysAdminId)}", RemoveSysAdminId: "${await encryptKey(removeSysAdminId)}" }`;
    if (rsaKey) {
        var result = await axios.put(`${WEB_API_URL}AdminUtilities/RemoveSystemAdminAccess`, null, {
            params: {
                key: rsaKey,
            }
        });
        if (result.data != null) {
            return result.data;
        }
    }
}

export async function linkSystemAdminToCompany(companyId, sysAdminId, setAsCompanyAdmin) {

    var rsaKey = `{CompanyId: "${await encryptKey(companyId)}", SysAdminId: "${await encryptKey(sysAdminId)}", SetAsCompanyAdmin: "${await encryptKey(setAsCompanyAdmin)}" }`;
    if (rsaKey) {
        var result = await axios.put(`${WEB_API_URL}AdminUtilities/LinkSystemAdminToCompany`, null, {
            params: {
                key: rsaKey,
            }
        });
        if (result.data != null) {
            return result.data;
        }
    }
}

export async function removeAuditVaultUserPermission(id) {

    var rsaKey = `{Id: "${await encryptKey(id.toString())}"}`;
    if (rsaKey) {
        var result = await axios.delete(`${WEB_API_URL}User/RemoveAuditVaultUserPermission`, {
            params: {
                key: `${rsaKey}`
            }
        });
    }
    if (result.data != null) {
        return result.data;
    }
}

export async function removeCompanyAdminAccess(companyId, companyOrSysAdminId, removeCompanyAdminId) {

    var rsaKey = `{CompanyId: "${await encryptKey(companyId.toString())}", CompanyOrSysAdminId: "${await encryptKey(companyOrSysAdminId)}", RemoveCompanyAdminId: "${await encryptKey(removeCompanyAdminId)}"}`;
    if (rsaKey) {
        var result = await axios.delete(`${WEB_API_URL}User/RemoveCompanyAdminAccess`, {
            params: {
                key: `${rsaKey}`
            }
        });
    }
    if (result.data != null) {
        return result.data;
    }
}

export function verifyUserCanAccessTenantData(user, tenantId) {
    if (user) {
        if (user.isSystemAdmin)
            return true;

        if (user.isCompanyAdmin)
            return true;

        if (user.permissions && user.permissions.length > 0) {
            for (var i = 0; i < user.permissions.length; i++) {
                if (user.permissions[i].tenantId == tenantId) {
                    return true;
                }
            }
        }
    }
    return false;
}


export function verifyUserCanAccessCompanyData(user) {
    if (user) {
        if (user.isSystemAdmin) {
            console.log("IS SYS ADMIN");
            return true;
        }

        if (user.isCompanyAdmin) {
            console.log("IS COMPANY ADMIN");
            return true;
        }
    }
    return false;
}





// Function that checks the User's permissions and ensure they have an entry for the specific tenantId where the role <= roleId.
// Returns true if a match is found, else returns false.
export function verifyUserHasTenantRolePermissions(user, checkTenantId, checkRoleId) {
    //console.log("PERM CHECK: tenantId: " + checkTenantId + ", roleId: " + checkRoleId);
    if (user && (user.isSystemAdmin || user.isCompanyAdmin)) {
        // User is sys admin - no need to check.
        //console.log("PERM: IS SYS ADMIN");
        return true;
    }
    // Check if the user object and permissions array exist
    if (user && user.permissions && user.permissions.length > 0) {
        // Iterate through the permissions array to find a matching entry
        for (let i = 0; i < user.permissions.length; i++) {
            const permission = user.permissions[i];
            // Check if the current permission matches the criteria
            if (
                permission.tenantId == checkTenantId &&
                permission.roleId <= checkRoleId
            ) {
                // Matching permission found, return true
                //console.log("PERM: FOUND A MATCH FOR tenant: " +  checkTenantId + ", and roleId: " + checkRoleId);
                return true;
            }
            /*
            else {
                console.log("PERM: checked but no match... tenantId: " + permission.tenantId + ", roleId: " + permission.roleId);
            }
            */
        }
    }
    //console.log("PERM: NO PERM MATCH FOUND");
    // No matching permission found, return false
    return false;
}

export function getTenantListFromUserPerms(user, company) {
    if (!user)
        return [];

    if (user.isSystemAdmin)
        return company.tenantList;

    if (user.companyId == company.id) {
        if (user.isCompanyAdmin)
            return company.tenantList;

        if (user.permissions.length > 0) {
            var list = [];
            for (var x = 0; x < company.tenantList.length; x++) {
                for (var y = 0; y < user.permissions.length; y++) {
                    if (company.tenantList[x].id == user.permissions[y].tenantId) {
                        list.push(company.tenantList[x]);
                        break;
                    }
                }
            }
            return list;
        }
    }
}


export function validateEmail(input) {

    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!input || regex.test(input) === false) {

        return false;
    }
    return true;
}


