// React Components
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import PropTypes from 'prop-types';

// Material UI Components
import { Grid, Paper } from '@mui/material';
import Link from '@mui/material/Link';
import { Alert, AlertTitle } from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// Audit Vault Components
import CompanyNag from "../components/common/CompanyNag";
import AccessDenied from "../components/common/AccessDenied";
import ServiceIsDown from "../components/common/ServiceIsDown";
import ProgressBar from "../components/common/ProgressBar";
import DisplayBreadCrumb from "../components/common/DisplayBreadCrumb";
import DisplaySectionTitleAndDescription from "../components/common/DisplaySectionTitleAndDescription";
import ImageExchange from '../imgs/iconExchange.svg';
import AuditGrowth from "../components/reports/AuditGrowth";
import PeriodDropDown from "../components/reports/PeriodDropDown";
import ExchangeDeletedItems from "../components/reports/ExchangeDeletedItems";

// Audit Vault Utilities
import { getCompanyByCompanyId } from "../utilities/common-company";
import { NumberBadge } from '../utilities/common-ui-utils';
import { formatAuditJobRunTimeDateServerTimeToGMT } from '../utilities/common-date-utils';
import TenantDropDown from "../components/reports/TenantDropDown";
import { verifyUserHasTenantRolePermissions } from "../utilities/common-user-utils";
import { ROLE_CODE_TENANTREPORTREADER } from "../constants/constants-roles";
import { REPORT_PERIOD_LIST, REPORT_ON_PERIOD_30DAYS, REPORT_ON_PERIOD_90DAYS, REPORT_ON_PERIOD_DEFAULT_DROPDOWNVALUE } from "../constants/constants-reportperiods";
import { getHealthStatsByTenantId } from "../utilities/common-healthstats";

const theBreadcrumbPath = [
    { name: 'Home', link: '/' },
    { name: 'Reports', link: '/Reports/ReportsHome' },
    { name: 'Exchange Reports', link: '/Reports/Exchange' },
];

const theSectionTitle = "Exchange Reports Dashboard";
const theSectionDesc = "Exchange audits capture crucial metadata for mailbox activity, administration, mail flow rules, exchange admin and compliance events.  Note: Audit info regarding reading and sending emails are not logged as part of the Exchange admin audit logs.  The reports below allow you to obtain insights into the aforementioned audit information.  Below is a visual representation of your essential Exchange audit data.";
const isAdminPage = false;


/*
Displays the Exchange Reports home page for Company X.

Future:
- Activity: 
    New mailboxes (New-Mailbox) in last 30 days, last 3 months
    ?Create (new Note or Calendar entries) in last 30 days, last 3 months
- User: Deleted items by User
*/
function ReportsExchange(props) {

    const { user, openCompanyNag, setOpenCompanyNag } = props;

    // Component Constants
    const [company, setCompany] = useState("");
    const [tenantHealthStats, setTenantHealthStats] = useState("");
    const [focusTenantId, setFocusTenantId] = useState("");
    const [focusPeriodId, setFocusPeriodId] = useState(REPORT_ON_PERIOD_DEFAULT_DROPDOWNVALUE);
    const [accessDenied, setAccessDenied] = useState(false);

    const [loading, setLoading] = useState(true);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);
    const navigate = useNavigate();

    const [selectedTenantOption, setSelectedTenantOption] = useState('');
    const [selectedPeriodOption, setSelectedPeriodOption] = useState('');
    const [tabValue, setTabValue] = useState(0);

    // Component Functions
    // Load TenantHealthStats given the tenandId.
    async function fetchTenantHealthStats(theTenantId) {
        setLoading(true);
        try {

            // Check if current user has Report Reader access to the specified tenant.
            var checkReportAccessForTenant = verifyUserHasTenantRolePermissions(user, theTenantId, ROLE_CODE_TENANTREPORTREADER);
            if (!user || !checkReportAccessForTenant) {
                setAccessDenied(true);
            }
            else {
                setAccessDenied(false);
            }

            // We attempt to load the tenant health stats (which lets us see if the tenant is pending still etc).
            var response2 = await getHealthStatsByTenantId(theTenantId, 3, user);
            if (response2) {
                setTenantHealthStats(response2);
            }
            setLoading(false);
        }
        catch (e) {
            console.log("ERROR: ReportExchange.fetchTenantHealthStats");
            console.log(e);
            setServiceIsDownError(true);
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        async function fetchCompanyData() {
            try {
                if (user && user.companyId) {
                    var response1 = await getCompanyByCompanyId(user.companyId, setServiceIsDownError);
                    if (response1) {

                        setCompany(response1);

                        if (response1.tenantList && response1.tenantList.length > 0) {

                            fetchTenantHealthStats(response1.tenantList[0].id);
                            setFocusTenantId(response1.tenantList[0].id);
                            setSelectedTenantOption(response1.tenantList[0]);  // Set to the first item in the list.
                            // Set the default period in the drop down list (we default to 30 days).                            
                            setSelectedPeriodOption(REPORT_PERIOD_LIST[REPORT_ON_PERIOD_DEFAULT_DROPDOWNVALUE]);
                        }
                    }
                }

                setLoading(false);
            }
            catch (e) {
                console.log("ERROR: ReportExchange.fetchCompanyData");
                console.log(e);
                setServiceIsDownError(true);
            }
            finally {
                setLoading(false);
            }
        }

        fetchCompanyData();
    }, []);
    // So at this point - the value for selectedTenantOption is either empty or the tenant object in question.  
    // We can use the selectedTenantOption as what we need to load the reports for (tenant object).  


    // Tab panel.
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        value: PropTypes.number.isRequired,
        index: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };


    // Event handlers for drop down lists.
    const handleTenantOptionChange = (event) => {
        setSelectedTenantOption(event.target.value);
        fetchTenantHealthStats(event.target.value.id);
        setFocusTenantId(event.target.value.id);
    };
    const handlePeriodOptionChange = (event) => {
        setSelectedPeriodOption(event.target.value);
        setFocusPeriodId(event.target.value.id);
    };

    // Component UI
    if (loading) {
        console.log("Loading Data ....")
        return (<ProgressBar message="Loading ..." loading={loading} />);
    }

    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }

    if (!accessDenied && selectedTenantOption && !selectedTenantOption.logExchangeAudit) {
        return (<Alert severity="info"><AlertTitle>Exchange Audit Logging is not enabled for this tenant.</AlertTitle>If you would like this feature, please enable Preserve Exchange Audit Logs by editing your Tenant settings (or <Link onClick={() => navigate(`/Support/SupportHome`)} component="button">Contact your Administrator</Link>).</Alert>)
    }


    // Get the total audit record count.
    var totalAuditRecords = 0;
    if (tenantHealthStats.totalExchangeAuditRecords) {
        totalAuditRecords = tenantHealthStats.totalExchangeAuditRecords;
    }

    // A valid Tenant is chosen from the textfield selection drop down list.
    return (
        <>
            <DisplayBreadCrumb paths={theBreadcrumbPath} />
            { /* Display report section icon logo. */}
            <div style={{ display: 'flex' }}>
                <div style={{ flex: 1, textAlign: 'left', alignSelf: 'flex-start' }}>
                    <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />
                </div>
                <div style={{ width: '75px', textAlign: 'left', alignSelf: 'flex-start' }}>
                    <br /><img src={ImageExchange} alt="Exchange Reports" />
                </div>
            </div>
            <CompanyNag company={company} openCompanyNag={openCompanyNag} setOpenCompanyNag={setOpenCompanyNag} />

            <div>
                <Grid container spacing={2}>
                    {
                        accessDenied &&
                        <Grid sx={{ minWidth: 500, width: "100%", padding: 2 }}><AccessDenied /></Grid>
                    }
                    {!selectedTenantOption &&
                        (
                            <Grid sx={{ minWidth: 500, width: "100%", padding: 2 }}>
                                <Alert severity="error">
                                    <AlertTitle>Your Company has not yet completed the setup process or you have not yet specified a valid Tenant.</AlertTitle>
                                </Alert>
                            </Grid>)
                    }
                    {tenantHealthStats.status == 0 &&
                        (
                            <Grid sx={{ minWidth: 500, width: "100%", padding: 2 }}>
                                <Alert severity="error">
                                    <AlertTitle>This Tenant is still pending validation.  Reports are not available until validation is completed.</AlertTitle>
                                </Alert>
                            </Grid>
                        )
                    }
                    {selectedTenantOption &&
                        (
                            <Grid container sx={{ minWidth: 500, width: "100%", paddingX: 2, paddingY: 0 }} spacing={2}>
                                <Grid item>
                                    <TenantDropDown
                                        user={user}
                                        company={company}
                                        handleTenantOptionChange={handleTenantOptionChange}
                                        selectedTenantOption={selectedTenantOption}></TenantDropDown>
                                </Grid>
                            </Grid>
                        )
                    }

                    {!accessDenied && selectedTenantOption && tenantHealthStats.status >= 1 ? (
                        <>
                            <Grid container sx={{ minWidth: 500, width: "100%", paddingX: 2, paddingY: 0 }} spacing={2}>
                                <Grid item>
                                    <PeriodDropDown selectedPeriodOption={selectedPeriodOption}
                                        handlePeriodOptionChange={handlePeriodOptionChange} />
                                </Grid>
                            </Grid>
                            { /* Display last audit job run time. */}
                            <Grid sx={{ minWidth: 500, width: "100%", padding: 2 }}>
                                Audit data last updated on: {formatAuditJobRunTimeDateServerTimeToGMT(tenantHealthStats.exchangeAuditLastSuccessfulRunTime)}
                                <br />
                            </Grid>
                            { /* Display AuditGrowth just for Exchange. */}
                            <Grid sx={{ minWidth: 333, width: "33%", padding: 2 }}>
                                <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%' }}>
                                    <div style={{ height: '100%' }}>
                                        <AuditGrowth tenantId={focusTenantId} periodToUse={focusPeriodId} reportType="Exchange" />
                                    </div>
                                </Paper>
                            </Grid>
                            { /* Display deleted item activity by operation. */}
                            <Grid sx={{ minWidth: 333, width: "33%", padding: 2 }}>
                                <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%' }}>
                                    <ExchangeDeletedItems
                                        user={user}
                                        companyId={company.id}
                                        tenantId={focusTenantId}
                                        periodToUse={focusPeriodId}
                                        loadData={false}
                                        filterByColumn={"Operation"} />
                                </Paper>
                            </Grid>
                            { /* Display deleted item activity by userId. */}

                            <Grid sx={{ minWidth: 333, width: "33%", padding: 2 }}>
                                <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%' }}>
                                    <ExchangeDeletedItems user={user}
                                        companyId={company.id}
                                        tenantId={focusTenantId}
                                        periodToUse={focusPeriodId}
                                        loadData={false}
                                        filterByColumn={"userid"} />
                                </Paper>
                            </Grid>

                            { /* Display total audit record count for Exchange. */}
                            <Grid sx={{ minWidth: 333, width: "33%", padding: 2 }}>
                                <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%' }}>
                                    <div style={{ height: '100%' }}>
                                        <h6>Total Audit Records</h6>
                                        <br />
                                        <TableContainer>
                                            <Table style={{ width: '100%', fontSize: 'inherit', fontWeight: 'inherit' }}>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell style={{ border: 'none', padding: 2 }}>Exchange</TableCell>
                                                        <TableCell style={{ border: 'none', padding: 2 }}><NumberBadge number={totalAuditRecords} bgcolor='#36a2eb' txtcolor='white' /></TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                    <br />
                                </Paper>
                            </Grid>
                            { /* Display a link to Insights Search specific to Search Exchange. */}
                            <Grid sx={{ minWidth: 500, width: "66%", padding: 2 }}>
                                <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%' }}>
                                    <div style={{ height: '100%' }}>
                                        <h6>Search your Exchange Audit Records</h6>
                                        <br />
                                        <div style={{ textAlign: 'center' }}>
                                            <ManageSearchIcon style={{ fontSize: '3rem' }} />&nbsp;
                                            <Link onClick={() => navigate(`/Reports/InsightsSearch/Exchange`)} component="button">Configure and run your Insights Search.</Link>
                                        </div>
                                    </div>
                                    <br />
                                </Paper>
                            </Grid>
                            <Grid sx={{ minWidth: 500, width: "100%", padding: 2 }}>

                                {/* Tab Panel. */}
                                <Box sx={{ width: '100%' }}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs value={tabValue} onChange={handleTabChange} aria-label="Exchange Reports Tab">
                                            <Tab label="Activity Reports" {...a11yProps(0)} />
                                            <Tab label="User Reports" {...a11yProps(1)} />
                                        </Tabs>
                                    </Box>
                                    <TabPanel value={tabValue} index={0}>
                                        <Link onClick={() => navigate(`/Reports/ExchangeDeletedItemsReport/${focusTenantId}/${REPORT_ON_PERIOD_30DAYS}/operation`)} component="button">Deleted Items (Last 30 days)</Link><br />
                                        <Link onClick={() => navigate(`/Reports/ExchangeDeletedItemsReport/${focusTenantId}/${REPORT_ON_PERIOD_90DAYS}/operation`)} component="button">Deleted Items (Last 3 months)</Link><br />
                                        <Link onClick={() => navigate(`/Reports/ExchangeDeletedItemsReport/${focusTenantId}/${REPORT_ON_PERIOD_30DAYS}/userId`)} component="button">Deleted Items by User (Last 30 days)</Link><br />
                                        <Link onClick={() => navigate(`/Reports/ExchangeDeletedItemsReport/${focusTenantId}/${REPORT_ON_PERIOD_90DAYS}/userId`)} component="button">Deleted Items by User (Last 3 months)</Link><br />
                                        <Link onClick={() => navigate(`/Reports/ExchangeMailboxesCreatedReport/${focusTenantId}/${REPORT_ON_PERIOD_30DAYS}/operation`)} component="button">Mailboxes Created (Last 30 days)</Link><br />
                                        <Link onClick={() => navigate(`/Reports/ExchangeMailboxesCreatedReport/${focusTenantId}/${REPORT_ON_PERIOD_90DAYS}/operation`)} component="button">Mailboxes Created (Last 3 months)</Link><br />
                                        <Link onClick={() => navigate(`/Reports/ExchangeMailboxesCreatedReport/${focusTenantId}/${REPORT_ON_PERIOD_30DAYS}/userId`)} component="button">Mailboxes Created by User (Last 30 days)</Link><br />
                                        <Link onClick={() => navigate(`/Reports/ExchangeMailboxesCreatedReport/${focusTenantId}/${REPORT_ON_PERIOD_90DAYS}/userId`)} component="button">Mailboxes Created by User (Last 3 months)</Link><br />
                                        <Link onClick={() => navigate(`/Reports/ExchangeMailboxesDeletedReport/${focusTenantId}/${REPORT_ON_PERIOD_30DAYS}/operation`)} component="button">Mailboxes Deleted (Last 30 days)</Link><br />
                                        <Link onClick={() => navigate(`/Reports/ExchangeMailboxesDeletedReport/${focusTenantId}/${REPORT_ON_PERIOD_90DAYS}/operation`)} component="button">Mailboxes Deleted (Last 3 months)</Link><br />
                                        <Link onClick={() => navigate(`/Reports/ExchangeMailboxesDeletedReport/${focusTenantId}/${REPORT_ON_PERIOD_30DAYS}/userId`)} component="button">Mailboxes Deleted by User (Last 30 days)</Link><br />
                                        <Link onClick={() => navigate(`/Reports/ExchangeMailboxesDeletedReport/${focusTenantId}/${REPORT_ON_PERIOD_90DAYS}/userId`)} component="button">Mailboxes Deleted by User (Last 3 months)</Link><br />
                                    </TabPanel>
                                    <TabPanel value={tabValue} index={1}>
                                        <Link onClick={() => navigate(`/Reports/ExchangeAllActivityByUserReport/${focusTenantId}`)} component="button">All Activity by User</Link><br />
                                        <Link onClick={() => navigate(`/Reports/ExchangeMailboxesCreatedOrDeletedByUserReport/${focusTenantId}`)} component="button">Mailboxes Created or Deleted by Modifying User</Link><br />
                                        <Link onClick={() => navigate(`/Reports/ExchangeAllActivityByIPAddressReport/${focusTenantId}`)} component="button">All Activity by IP Address</Link><br />
                                    </TabPanel>

                                </Box>

                            </Grid>
                        </>
                    ) :
                        (
                            <>
                                {tenantHealthStats.status === 0 ?
                                    (
                                        <></>
                                    )
                                    :
                                    (
                                        <Grid sx={{ minWidth: 500, width: "100%", padding: 2 }}>
                                            <ProgressBar message="Loading ..." loading={loading} />
                                        </Grid>
                                    )
                                }
                            </>
                        )
                    }
                    <Grid sx={{ minWidth: 500, width: "100%", padding: 2 }}>
                        <br />
                        Need a custom or new report?  Provide us with your <Link onClick={() => navigate(`/Support/SupportHome`)} component="button">Product Feedback</Link>.
                        <br />* Unless otherwise specified as UTC, graphs on page are using your browser's local timezone.
                    </Grid>
                </Grid>
            </div>
        </>)

}
export default ReportsExchange;