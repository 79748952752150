//React Components
import axios from "axios";

// Audit Vault Utilities
import { encryptKey } from './common-encrypt-util';

// Audit Vault Constants
const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

export async function getCompanyByCompanyId(companyId, setServiceIsDownError) {

    if (companyId) {
        try {
            var rsaKey = await encryptKey(`{Id: ${companyId}}`);
            if (rsaKey) {

                var result = await axios.get(`${WEB_API_URL}Company/GetCompany`, {
                    params: {
                        key: `${rsaKey}`
                    }
                });
                if (result.data != null) {
                    return result.data;
                }
            }
        }
        catch (e) {
            console.log(`getCompanyByCompanyId Error:`, e.message);
            setServiceIsDownError(true);
        }
    }
    return "";
}

export async function getCompanyByAzureTenantId(azureTenantId) {

    var rsaKey = await encryptKey(`{AzureTenantId: "${azureTenantId}" }`);
    if (rsaKey) {

        var result = await axios.get(`${WEB_API_URL}Company/GetCompanyByAzureTenantId`, {
            params: {
                key: `${rsaKey}`
            }
        });
        if (result.data != null) {
            return result.data;
        }
    }
}

export async function getCompanyBySetupId(setupId) {

    var rsaKey = await encryptKey(`{SetupId: "${setupId}" }`);
    if (rsaKey) {

        var result = await axios.get(`${WEB_API_URL}Company/GetCompanyBySetupId`, {
            params: {
                key: `${rsaKey}`
            }
        });
        if (result.data != null) {
            return result.data;
        }
    }
}

export async function updateCompany(companyId,
    companyName,
    streetAddress,
    city,
    provinceState,
    country,
    phone,
    postalZip,
    primaryContactFirstName,
    primaryContactLastName,
    primaryContactEmail,
    primaryContactPhone,
    companySize,
    setupStageComplete,
    authorizedBy,
    authorizedByTenantId,
    accountCreatedDate,
    stripeCustomerId) {


    var payload = `{
        id: ${companyId},
        CompanyName: "${await encryptKey(companyName)}",
        StreetAddress: "${streetAddress}",
        City: "${city}",
        ProvinceState: "${provinceState}",
        Country: "${country}",
        Phone: "${phone}",
        PostalZip: "${postalZip}",
        PrimaryContactFirstName: "${primaryContactFirstName}",
        PrimaryContactLastName: "${primaryContactLastName}",
        PrimaryContactEmail: "${primaryContactEmail}",
        PrimaryContactPhone: "${primaryContactPhone}",
        CompanySize: ${companySize},
        SetupStageComplete: "${setupStageComplete}",
        AuthorizedBy: "${await encryptKey(authorizedBy)}",
        AuthorizedByTenantId: "${await encryptKey(authorizedByTenantId)}",
        AccountCreatedDate: "${accountCreatedDate}",
        StripeCustomerId: "${stripeCustomerId}",
      }`;

    var response = await axios.put(`${WEB_API_URL}Company/UpdateCompany`, null, {
        params: {
            key: payload,
        }
    })

    return response;

}

export async function insertCompany(
    companyName,
    country,
    phone,
    primaryContactFirstName,
    primaryContactLastName,
    primaryContactEmail,
    primaryContactPhone,
    companySize,
    setupId,
    authorizedBy,
    authorizedByGraphId) {


    var payload = `{
        CompanyName: "${await encryptKey(companyName)}",
        StreetAddress: "N/A",
        City: "N/A",
        ProvinceState: "N/A",
        Country: "${country}",
        Phone: "${phone}",
        PostalZip: "",
        PrimaryContactFirstName: "${primaryContactFirstName}",
        PrimaryContactLastName: "${primaryContactLastName}",
        PrimaryContactEmail: "${primaryContactEmail}",
        PrimaryContactPhone: "${primaryContactPhone}",
        CompanySize: ${companySize},
        SetupId: "${await encryptKey(setupId)}",
        IsAccountAuthorized: true,
        SetupStageComplete: 0,
        AuthorizedBy: "${authorizedBy}",
        AuthorizedByGraphId: "${authorizedByGraphId}"
      }`;

    var response = await axios.post(`${WEB_API_URL}Company/InsertCompany`, null, {
        params: {
            key: payload,
        }
    })

    return response;
}