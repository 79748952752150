// React Components
import * as React from 'react';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// Material UI Components
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Legend, Tooltip, TimeScale, LinearScale, PointElement, LineElement, CategoryScale} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels'; 
import { enUS } from 'date-fns/locale'; 
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import { Alert, AlertTitle } from '@mui/material';

// Audit Vault Components
import ServiceIsDown from '../common/ServiceIsDown';
import ProgressBar from '../common/ProgressBar';
import InvalidTenant from '../common/InvalidTenant';
import DisplayExportableDataSource from "./DisplayExportableDataSource";

// Audit Vault Utilities
import {reportTitleAddPeriodToUseText, presetDatasetColors} from '../../utilities/common-report-utils';
import { encryptKey } from '../../utilities/common-encrypt-util';
import { formatDate } from '../../utilities/common-date-utils';
import { verifyUserCanAccessTenantData } from "../../utilities/common-user-utils";
import { REPORT_DATASOURCE_BROWSER_DISPLAY_RECORD_COUNT } from '../../constants/constants-report-displayRecordCount';

const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;  
import { REPORT_ON_PERIOD_1WEEK, REPORT_ON_PERIOD_30DAYS, REPORT_ON_PERIOD_90DAYS, REPORT_ON_PERIOD_180DAYS, REPORT_ON_PERIOD_1YEAR } from "../../constants/constants-reportperiods";
import AccessDenied from '../common/AccessDenied';
import {REPORT_SUMMARYTEXT_MAX_LENGTH} from '../../constants/constants-report-summaryText';

// Note, below is the tree-shakable way to decrease the bundle size.
ChartJS.register(Title, Legend, Tooltip, TimeScale, LinearScale, PointElement, LineElement, CategoryScale);
ChartJS.register(ChartDataLabels);

/*
TemplateActivityByTenantAndPeriodWithLineChart report.
This template is intended to be used with a Report page with no direct UI inputs, and as a subcomponent to render a graph on a dashboard.

Note:
Right now our reportResults array may contain one or more rows of data.
Each reportResults element is another array which contains the data for a period of time.
Each element in reportResults represents a date range to display for the line chart.
Each operation is being displayed in a line chart.
So our line chart will have N lines displayed with X datapoints along x-axis of the chart.  Each of the N lines corresponds to a unique operation that may occur in each of the reportResults child arrays.

Reusable report template used by various reports for any workload source with multiple datasets over a period.
Parameters:
- tenantId: The Tenant Id we are reporting on.
- periodToUse: The reporting periods to report on (data points).
- loadData: true or false.  (if true displays table of sortable source data, if false includes link to report to view full details).
- filterByColumn: the variable we wish to generate lines in our chart for.  Must exist in our reportResults dataset child arrays (this column name must exist in the child arrays).
                     ex.) operation, userId etc.
                     NOTE: this field will control how many lines will be displayed in our chart,
                     Each unique value will be a seperate line to trace so use care!
                     The field must have the correct spelling and case.
- theGraphTitle: The title of the chart/graph to use.  (Is also used for the CSV file name)
- columns: the columns to display.

Chart: Linechart

*/
export default function ReportTemplateActivityByTenantAndPeriodWithLineChart(props) {

    // Component Constants
    const { user, companyId, tenantId, reportType, periodToUse, loadData, theGraphTitle, columns, filterByColumn } = props;        
    
    const [reportStats, setReportStats] = useState([]);
    const [reportData, setReportData] = useState([]);  
    const [displayRecordCountExceeded, setDisplayRecordCountExceeded] = React.useState(false);
    const [totalRecordCount, setTotalRecordCount] = React.useState(0);
    
    const [loading, setLoading] = useState(true);
    const [seeMoreUrl, setSeeMoreUrl] = useState('/Reports/SharePointFileActivityReport');   
    const [serviceIsDownError, setServiceIsDownError] = useState(false); 
    const [accessDenied, setAccessDenied] = useState(false);   
    const [invalidTenantError, setInvalidTenantError] = useState(false); 

      
    const navigate = useNavigate();


    // Use effect loading.
    useEffect(() => {

        async function fetchData() {            
            
            setLoading(true);
            try {
                    if (!verifyUserCanAccessTenantData(user, tenantId)) 
                    {
                        setAccessDenied(true);
                    }
                
                    var periodLength = 30;
                    switch (parseInt(periodToUse))
                    {
                        case REPORT_ON_PERIOD_1WEEK:
                            periodLength = 7;
                            break;

                        case REPORT_ON_PERIOD_30DAYS:
                            periodLength = 30;
                            break;

                        case REPORT_ON_PERIOD_90DAYS:
                            periodLength = 90;
                            break;

                        case REPORT_ON_PERIOD_180DAYS:
                            periodLength = 180;
                            break;

                        case REPORT_ON_PERIOD_1YEAR:
                            periodLength = 365;
                            break;
                    }

                    // set the seeMoreUrlPrefix
                    switch (reportType)
                    {
                        case "ExchangeDeletedItems":
                            setSeeMoreUrl("/Reports/ExchangeDeletedItemsReport");
                            break;

                        case "ExchangeMailboxesCreated":
                            setSeeMoreUrl("/Reports/ExchangeMailboxesCreatedReport");
                            break;

                        case "ExchangeMailboxesDeleted":
                            setSeeMoreUrl("/Reports/ExchangeMailboxesDeletedReport");
                            break;

                        case "SharePointPageViews":
                            setSeeMoreUrl("/Reports/SharePointPageViewsReport");
                            break;

                        case "EntraIDFailedLogins":
                            setSeeMoreUrl("/Reports/EntraIDFailedLoginsReport");
                            break;

                        case "EntraIDSuccessfulLogins":
                            setSeeMoreUrl("/Reports/EntraIDSuccessfulLoginsReport");
                            break;

                        case "EntraIDUserGroupActivity":
                            setSeeMoreUrl("/Reports/EntraIDGroupAndUserActivityReport");
                            break;

                        case "TeamsAllActivity":
                            setSeeMoreUrl("/Reports/TeamsAllActivityReport");
                            break;
                    }

                    var usrEmail = "N/A";
                    if (user.userEmail)
                        usrEmail = user.userEmail;

                    var payload = `{
                        companyId: ${companyId},
                        tenantId: ${tenantId},
                        reportType: "${reportType}",
                        period: ${periodLength},
                        filterByColumn: "${filterByColumn}",
                        userMicrosoftGraphId: "${await encryptKey(user.microsoftGraphId)}",
                        userEmail: "${await encryptKey(usrEmail)}"               
                    }`; 
                    var response = await axios.get(`${WEB_API_URL}Reports/GetReportStats`, {
                        params: {
                            key: `${payload}`
                        }
                    });

                    
                    var loadDataDisplayMaxRecordCountExceeded = false; 
                    if (response)
                    {
                        // Set reportStats.
                        console.log(response)
                        setReportStats(response.data);
                
                        // Now we do a pre-check to see how many elements we have before we load the reportData.                        
                        const totalAuditHistoryCount = response.data.datasets.reduce((total, dataset) => {
                        return total + dataset.auditHistoryCount;
                        }, 0);
                        //console.log("Total Audit History Count: " + totalAuditHistoryCount);
                        
                        setTotalRecordCount(totalAuditHistoryCount);
                        if(totalAuditHistoryCount > REPORT_DATASOURCE_BROWSER_DISPLAY_RECORD_COUNT) {
                            // We do not load or display the results since the count is exceeded.
                            loadDataDisplayMaxRecordCountExceeded = true;
                            setDisplayRecordCountExceeded(true);  
                        }                        
                    }

                    if (loadData && !loadDataDisplayMaxRecordCountExceeded)
                    {
                        // load the actual data for the report
                        var response2 = await axios.get(`${WEB_API_URL}Reports/GetReportData`, {
                            params: {
                                key: `${payload}`
                            }
                        });
        
                        if (response2)
                        {
                            setReportData(response2.data);
                        }
                    }
            }   
            catch(e){
                setAccessDenied(true);          
                console.log("ERROR: ReportTemplateActivityByTenantAndPeriodWithLineChart.fetchData");
                console.log(e);
            }
            finally{
                setLoading(false);
            }                                 
        }
        
        fetchData();                
    }, [periodToUse]);

    
    // Component UI    
    // If we are still loading data, display that in the UI...
    if (loading) {
        return (<ProgressBar message="Loading ..." loading={loading} />);
    }

    // Add check to see if WebAPI service is running or not.
    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }

    // Add check to see if invalid tenant error detected.
    if (invalidTenantError) {
        return (<><InvalidTenant></InvalidTenant></>);
    }

    if (accessDenied)
    {
        return (<><AccessDenied></AccessDenied></>)
    }
    
    var displayTheGraphTitle = reportTitleAddPeriodToUseText(theGraphTitle, periodToUse);  

    // Prepare data for the chart
    var data;
    let counter = 0;
    var summaryText = "";

    // Get the data points by operation name
    function GetDataPoints(obj, dataPoints, operationName)
    {
        if (!obj || !obj.operationDataset)
            return;
        
        for (var x = 0; x < obj.operationDataset.length; x++)
        { 
            if (obj.operationDataset[x].operation == operationName)
            {
                dataPoints.push({
                    label: obj.operationDataset[x].operationCount,
                    x: new Date(obj.endDate).getTime(),
                    y: obj.operationDataset[x].operationCount,
                    startDate: obj.startDate,
                    endDate: obj.endDate,
                });
            }
        }
    }

    // Loop through all the operations for this dataset and get the datapoints by operation
    var ds = [];
    for (var i = 0; i < reportStats.allOperationsForReport.length; i++) 
    {
        var dataPoints = [];

        for (var x = 0; x < reportStats.datasets.length; x++)
        {
            GetDataPoints(reportStats.datasets[x], dataPoints, reportStats.allOperationsForReport[i].operation);
        }

        summaryText += reportStats.allOperationsForReport[i].operation + ": " + reportStats.allOperationsForReport[i].operationCount;
        if((i+1) < reportStats.allOperationsForReport.length) {
            summaryText += ", ";
        }
        else {
            summaryText += " ";
        }
        
        ds.push({
            label: reportStats.allOperationsForReport[i].operation,
            data: dataPoints,
            borderColor: presetDatasetColors[counter % presetDatasetColors.length],
            fill: false,
        },)

        counter++;
        
        data = { datasets: ds};
    } 
    
   
    // Configure the chart options.
    var chartOptions = {        
        scales: {
        x: {
            type: 'time',
            adapters: { 
                date: {
                    locale: enUS, 
                },
            },                         
            time: {
                unit: 'day', // Set the desired unit (day, week, month, etc.)
            },
            title: {  // The x-axis title.
                display: true,  
                text: 'Date',
            },
        },
        y: {
            beginAtZero: true,
            title: {  // The y-axis title.
                display: true,
                text: 'Counts',
            },
        },
        },        
        plugins: {
            legend: {
                display: true, 
                position: 'right', // Adjust the legend position ('top', 'bottom', 'left', 'right')
                labels: {
                    boxHeight: 10, // Adjust the height of each legend item
                },
            },
            tooltip: 
            {
                callbacks: 
                {
                    title: function(tooltipItems, data) {
                        //Return value for title
                        try{
                            var dataSetIndex = tooltipItems[0].dataIndex + 1;
                            var ds = reportStats.datasets[dataSetIndex];
                            if (dataSetIndex > 0)
                            {
                                return (formatDate(ds.startDate) + " to " + formatDate(ds.endDate));
                            }
                        }
                        catch
                        {
                            
                        }
                    },
                }
            },
        },        
    };

    return (
        <>
        <h6>{displayTheGraphTitle}</h6>
        <div style={{ maxHeight: '670px', overflow: 'auto' }}> 
                <Line data={data} options={chartOptions} /> 
        </div>
        <div style={{ fontSize: '0.8rem' }}>
            <b>Activity Summary for Period: </b>
            {!loadData && summaryText.length > REPORT_SUMMARYTEXT_MAX_LENGTH ? 
                (summaryText.slice(0, REPORT_SUMMARYTEXT_MAX_LENGTH) + " ...") :
                (summaryText)
            }
        </div>      
        <br />
        {!loadData &&
            <>
            <Button size="small" onClick={() => navigate(`${seeMoreUrl}/${tenantId}/${periodToUse}/${filterByColumn}`)}>See More</Button>                        
            </>
        }
        {loadData ? (
            !displayRecordCountExceeded ? (
                <DisplayExportableDataSource 
                    columns={columns} 
                    reportResults={reportData} 
                    csvFilename={displayTheGraphTitle + ".csv"} 
                    tenantId={tenantId} 
                    reportType={reportType} 
                    user={user}
                />
            ) : (                                 
                <Grid item xs={12}>                
                Entries Returned: <b>{totalRecordCount }</b>   
                <br /><br />
                <Alert severity="warning">
                    <AlertTitle>We are not displaying the source data table below.  The results have exceeded the total number of viewable results in a page.</AlertTitle>
                    The maximum number of data results displayable to prevent browser performance issues is: {REPORT_DATASOURCE_BROWSER_DISPLAY_RECORD_COUNT}.
                    <br />As an alternative we recommend running an Insights Search which allows for a refined result set based on a restricted date range.
                </Alert>
                </Grid>
            )
        ) : null}
        </>
    );    
}