import axios from "axios";

// Audit Vault common
import { encryptKey } from "./common-encrypt-util";

// Audit Vault Constants
const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

export async function getCompanyProductsInfo(companyId, setCompanyProducts) {
    if (companyId) {
        const response = await axios.get(`${WEB_API_URL}Products/getproductsbycompanyid/${companyId}`);
        if (response) {
            console.log("GetProductsByCompanyId:")
            console.log(response.data)
            setCompanyProducts(response.data);
            var returnMsg = '{"products": [';
            for (var i = 0; i < response.data.length; i++) {
                returnMsg = returnMsg + `{"stripeSubscriptionId":"${response.data[i].stripeSubscriptionId}"},`;
            }
            if (response.data.length > 0) {
                // remove trailing ,
                returnMsg = returnMsg.substring(0, returnMsg.length - 1);
            }
            returnMsg = returnMsg + "]}";
        }

        let productsObjs = JSON.parse(returnMsg);
        console.log("Products Object:");
        console.log(productsObjs);
        return productsObjs;
    }

};

export async function getStripeCustomer(stripeCustomerId) {

    var rsaKey = `{StripeCustomerId: "${await encryptKey(stripeCustomerId)}" }`;
    if (rsaKey) {

        var result = await axios.get(`${WEB_API_URL}Billing/get-stripe-customer`, {
            params: {
                key: `${rsaKey}`
            }
        });
        if (result.data != null) {
            return result.data;
        }
    }
}

export async function getStripeCustomerPaymentMethod(stripeCustomerId) {

    var rsaKey = `{StripeCustomerId: "${await encryptKey(stripeCustomerId)}" }`;
    if (rsaKey) {

        var result = await axios.get(`${WEB_API_URL}Billing/get-stripe-customer-paymentMethod`, {
            params: {
                key: `${rsaKey}`
            }
        });
        if (result.data != null) {
            return result.data;
        }
    }
}

export async function getStripeCustomerInvoices(stripeCustomerId, limit) {

    var rsaKey = `{StripeCustomerId: "${await encryptKey(stripeCustomerId)}", Limit: "${await encryptKey(limit.toString())}" }`;
    if (rsaKey) {

        var result = await axios.get(`${WEB_API_URL}Billing/get-customer-invoices`, {
            params: {
                key: `${rsaKey}`
            }
        });
        if (result.data != null) {
            return result.data;
        }
    }
}


export async function getSubscriptionInfo(stripeCustomerId, setSubscription) {

    var rsaKey = `{StripeCustomerId: "${await encryptKey(stripeCustomerId)}" }`;
    if (rsaKey) {

        var result = await axios.get(`${WEB_API_URL}Billing/get-customer-subscription`, {
            params: {
                key: `${rsaKey}`
            }
        });
        if (result.data != null) {
            setSubscription(result.data);
            return "true";
        }
    }
};

export async function getSubscriptionInfo2(stripeCustomerId) {

    var rsaKey = `{StripeCustomerId: "${await encryptKey(stripeCustomerId)}" }`;
    if (rsaKey) {

        var result = await axios.get(`${WEB_API_URL}Billing/get-customer-subscription`, {
            params: {
                key: `${rsaKey}`
            }
        });
        if (result.data != null) {
            return result.data;
        }
    }
};

export async function createPaymentIntent(productId, priceId, requestEmail) {

    if (!requestEmail) {
        requestEmail = "";
    }

    const payload = `{"ProductId":"${productId}", "items": [{"id": "${priceId}"}], "ReceiptEmail":"${await encryptKey(requestEmail)}"}`;
    console.log(payload);
    if (payload) {
        var result = await axios.post(`${WEB_API_URL}Billing/create-payment-intent`, null, {
            params: {
                key: payload,
            }
        });
        if (result.data != null) {
            return result.data;
        }
    }
};

export async function updatePaymentMethod(stripeCustomerId, paymentMethodId) {

    const payload = `{"StripeCustomerId": "${await encryptKey(stripeCustomerId)}", "PaymentMethodId":"${await encryptKey(paymentMethodId)}"}`;
    if (payload) {
        var result = await axios.put(`${WEB_API_URL}Billing/update-payment-method`, null, {
            params: {
                key: payload,
            }
        });
        if (result.data != null) {
            return result.data;
        }
    }
};


export async function removeTrialPeriod(subscriptionId) {

    const payload = `{"SubscriptionId": "${await encryptKey(subscriptionId)}"}`;
    if (payload) {
        var result = await axios.put(`${WEB_API_URL}Billing/remove-trial-period`, null, {
            params: {
                key: payload,
            }
        });
        if (result.data != null) {
            return result.data;
        }
    }
};

export const getBillingStatus = (status) => {
    switch (status) {
        case 'active':
            return "Active";
        case 'past_due':
            return "Past Due";
        case 'unpaid':
            return "Unpaid";
        case 'canceled':
            return "Canceled";
        case 'incomplete':
            return "Incomplete";
        case 'incomplete_expired':
            return "Incomplete Expired";
        case 'trialing':
            return "Trial";
        case 'paused':
            return "Paused";
    }

};

export const getBillingStatusColour = (status) => {
    switch (status) {
        case 'active':
            return "green";
        case 'past_due':
            return "red";
        case 'unpaid':
            return "orange";
        case 'canceled':
            return "orange";
        case 'incomplete':
            return "orange";
        case 'incomplete_expired':
            return "red";
        case 'trialing':
            return "blue";
        case 'paused':
            return "orange";
    }
};

export const getCollectionMethod = (method) => {
    switch (method) {
        case 'charge_automatically':
            return "Charged Automatically";

        default:
            return "N/A";
    }
};

export const getPaymentType = (type) => {
    switch (type) {
        case 'subscription_item':
            return "Subscription";

        default:
            return "N/A";
    }
};

export const getBillingInterval = (interval) => {
    switch (interval) {
        case 'month':
            return "Monthly";

        case 'year':
            return "Yearly";

        default:
            return "N/A";
    }
};