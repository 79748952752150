// React Components
import React from 'react';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { useMediaQuery, useTheme } from "@mui/material";

// Material UI Components
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

// Audit Vault Components
import CompanyNag from "../components/common/CompanyNag";
import ServiceIsDown from "../components/common/ServiceIsDown";
import TenantStats from "../components/TenantStats";
import CompanyDetails from "../components/company/CompanyDetails";
import ProgressBar from "../components/common/ProgressBar";
import DisplayBreadCrumb from "../components/common/DisplayBreadCrumb";
import DisplaySectionTitleAndDescription from "../components/common/DisplaySectionTitleAndDescription";
import BillingInfo from "../components/billing/BillingInfo";

import signInWithMsLogo from '../imgs/buttonSignInWithMicrosoft.svg'
import { loginToMicrosoft } from '../utilities/common-user-utils';
import { useMsal } from '@azure/msal-react';

// Audit Vault Common
import { getCompanyByCompanyId } from "../utilities/common-company";
import { getSubscriptionInfo, getCompanyProductsInfo } from "../utilities/common-getBillingInfo";
import LogoWindowPanes from '../imgs/logoWindowPanes.svg';

// Audit Vault Constants
import { LINK_TO_TERMS_OF_SERVICE, LINK_TO_PRIVACY_POLICY } from '../constants/constants-links';

const theSectionTitle = "Company";
const theSectionDesc = "Welcome to your Company Dashboard. This is where you can review and configure your Company's respective settings.";
const isAdminPage = false;

const signInTitle = "Enhanced Compliance Confidence";
const signInDescription = "A powerful and cost effective solution to retain and surface your Microsoft 365 Audit Log records for as long as you require.";
const signInGetStarted = "Get started";

const theBreadcrumbPath = [
  { name: 'Home', link: '/' },
  { name: 'Company', link: '/MyCompany' },
];

function MyCompany(props) {

  const { user, openCompanyNag, setOpenCompanyNag } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  // Component Constants
  const [company, setCompany] = useState("");
  const [companyProducts, setCompanyProducts] = useState("");
  const [subscription, setSubscription] = useState("");
  const [loading, setLoading] = useState(true);
  const [serviceIsDownError, setServiceIsDownError] = useState(false);
  const [showCompanySetupNow, setShowCompanySetupNow] = useState(false);

  const navigate = useNavigate();
  const { instance } = useMsal();

  var signInMinHeight = 500;
  var signInWindowPainsPaddingBottom = 10;
  var getStartedMarginTop = 150;
  var getStartedTermsMarginTop = 20;

  if (isMobile) {
    signInMinHeight = 100;
    signInWindowPainsPaddingBottom = 1;
    getStartedMarginTop = 10;
    getStartedTermsMarginTop = 5;
  }

  // Component functions
  const handleUpdate = (value) => {
    if (value > 0) {
      navigate(`/MyCompany/company-update/${value}`);
    }
  };

  // When the sign up button is clicked.
  const handleSignUpClick = () => {
    setShowCompanySetupNow(true);
  };


  useEffect(() => {
    async function fetchData() {
      try {

        if (loading) {
          if (user) {
            const response = await getCompanyByCompanyId(user.companyId, setServiceIsDownError);
            if (response) {
              setCompany(response);
              if (response.setupStageComplete > 0) {
                var productsReponse = await getCompanyProductsInfo(response.id, setCompanyProducts);
                if (productsReponse) {
                  for (var i = 0; i < productsReponse.products.length; i++) {
                    var subRespone = await getSubscriptionInfo(productsReponse.products[i].stripeSubscriptionId, setSubscription);
                    if (subRespone) {
                      //setLoading(false);
                    }
                  }
                }
              }
            }
          }
        }
      }
      catch (e) {
        console.log("ERROR: MyCompany.fetchData");
        console.log(e);
        setServiceIsDownError(true);
      }
      finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [setSubscription]);


  // Component UI
  if (serviceIsDownError) {
    return (<><ServiceIsDown></ServiceIsDown></>);
  }

  /* Company Setup Stages
        Created = 0,            // first created by user 
        PaymentComplete = 1,    // Payment completed by Authorized user at organization
        TenantCreated = 2,      // Tenant Created
        GrantedAccess = 3,      // Global Admin granted access to Audit Vault to Tenant
        SetupComplete = 4       // Set up complete and first audit ran successfully
  */

  if (loading) {
    return (
      <><div><ProgressBar loading={loading} message="Loading ..." /></div></>
    );
  }

  if (!loading && !company) {
    // Display a sign-in or sign-up option.  
    {
      return (
        showCompanySetupNow == false ?
          (
            <Grid container spacing={2} sx={{ height: '100%' }}>
              <Grid sx={{ minWidth: 500, width: '50%', padding: 2 }}>
                <Paper style={{ backgroundColor: 'lightblue', padding: 15, height: '100%', minHeight: signInMinHeight }}>
                  <Typography component="div" sx={{ paddingBottom: signInWindowPainsPaddingBottom }}>
                    <img src={LogoWindowPanes} alt="ECM Insights" width="43" height="46" />
                  </Typography>
                  <Typography component="div">
                    <Typography variant="h4">{signInTitle}</Typography>
                    <Typography variant="h5">{signInDescription}</Typography>
                  </Typography>
                </Paper>
              </Grid>
              <Grid sx={{ minWidth: 500, width: '50%', textAlign: 'center', padding: 2 }}>
                <Typography variant="h4" component="div" style={{ textAlign: 'center', paddingTop: getStartedMarginTop, paddingBottom: 20 }}>
                  {signInGetStarted}
                </Typography>
                <Typography component="div">
                  <Typography component="span" sx={{ paddingRight: 1 }}>
                    <Button onClick={() => loginToMicrosoft(instance)} sx={{ padding: '3px', '&:hover': { backgroundColor: 'lightblue' } }}>
                      <img src={signInWithMsLogo} alt="Sign in with Microsoft" />
                    </Button>
                  </Typography>
                  <Typography component="span" sx={{ paddingLeft: 1 }}>
                    <Button variant="contained" color="primary" onClick={handleSignUpClick} style={{ width: '215px' }}>Sign Up</Button>
                  </Typography>
                </Typography>
                <Typography sx={{ marginTop: getStartedTermsMarginTop, textAlign: 'center' }}>
                  <Typography component="div" variant='caption'>
                    <a href={LINK_TO_TERMS_OF_SERVICE} target="_blank" rel="noopener noreferrer">Terms of Service</a>&nbsp; | &nbsp;
                    <a href={LINK_TO_PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                  </Typography>
                </Typography>
              </Grid>
            </Grid >
          )
          :
          (
            navigate(`/mycompanysetup/${uuidv4()}`)
          )
      );
    }
  }

  if (!loading && company) {
    return (
      <>
        <DisplayBreadCrumb paths={theBreadcrumbPath} />
        <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />
        <CompanyNag company={company} openCompanyNag={openCompanyNag} setOpenCompanyNag={setOpenCompanyNag} />
        {!loading && company && company.setupStageComplete > 0 &&
          <div>
            <Grid container spacing={2}>
              {
                company.tenantList.map((tenant) =>
                  <Grid sx={{ minWidth: 450, width: "50%", padding: 2 }}>
                    <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray' }}>
                      <h6>Tenant Summary</h6>
                      <TenantStats tenant={tenant} user={user} reportPageId={9} />
                    </Paper>
                  </Grid>
                )
              }
              {
                company.stripeCustomerId && subscription && (
                  <Grid sx={{ minWidth: 450, width: "50%", padding: 2 }}>
                    <Paper key="billing" elevation={3} sx={{ p: 2, border: '1px solid lightgray' }}>
                      <h6>Billing and Payments</h6>
                      <BillingInfo subscription={subscription} companyId={company.id} />
                      <br />
                    </Paper>
                  </Grid>)
              }
              <Grid sx={{ minWidth: 450, width: "100%", padding: 2 }}>
                <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray' }}>
                  <h6>Your Company Details</h6>
                  <CompanyDetails company={company} />
                  <br></br>
                  <div>
                    <Button
                      variant="contained"
                      type="button"
                      onClick={(event) => handleUpdate(company.id)}
                    >
                      Edit
                    </Button>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </div>
        }
      </>

    );
  }
}

export default MyCompany;