import React from "react";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

const StripeIsDown = (props) => {

    const { msg } = props;

    return (
      <>
      <Alert severity="error">
            <AlertTitle>There seems to be an issue with the Stripe service.</AlertTitle>
            <p>{msg}</p>
            <p>Please try your request again momentarily. If the issue persists please raise a support ticket.</p>
      </Alert>
      </>);
};
export default StripeIsDown;