// React Components
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useRef } from "react";
import axios from "axios";

// Material UI Components
import dayjs from 'dayjs';
import { Grid, Paper } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { Alert, AlertTitle } from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

// Audit Vault Components
import CompanyNag from "../components/common/CompanyNag";
import AccessDenied from "../components/common/AccessDenied";
import ServiceIsDown from "../components/common/ServiceIsDown";
import ProgressBar from "../components/common/ProgressBar";
import DisplayBreadCrumb from "../components/common/DisplayBreadCrumb";
import DisplaySectionTitleAndDescription from "../components/common/DisplaySectionTitleAndDescription";
import StartAndEndDateSelector from "../components/reports/StartAndEndDateSelector";
import KeywordSelector from "../components/reports/KeywordSelector";
import UserSelector from "../components/reports/UserSelector";
import SiteCollectionSelector from "../components/reports/SiteCollectionSelector";
import SearchNameSelector from "../components/reports/SearchNameSelector";
import WorkloadSelector from "../components/reports/WorkloadSelector";
import ActivitiesSelector from "../components/reports/ActivitiesSelector";
import SearchResultsList from "../components/reports/SearchResultsList";

// Audit Vault Utilities
import catchAxiosErr2 from '../utilities/common-axios-err-catch2';
import { getCompanyByCompanyId } from "../utilities/common-company";
import TenantDropDown from "../components/reports/TenantDropDown";
import { verifyUserHasTenantRolePermissions } from "../utilities/common-user-utils";
import { ROLE_CODE_TENANTREPORTREADER } from "../constants/constants-roles";
import { combineDateJsDateAndTimeToDateTimeOffset, convertArrayValuesToSemicolonSeperatedString, convertSplitStringBySemicolonToArray } from "../utilities/common-report-utils";
import { MAX_SAVED_SEARCHES_PER_USER } from "../constants/constants-search";
import { getHealthStatsByTenantId } from "../utilities/common-healthstats";
import { encryptKey } from '../utilities/common-encrypt-util';


const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

const theBreadcrumbPath = [
    { name: 'Home', link: '/' },
    { name: 'Reports', link: '/Reports/ReportsHome' },
    { name: 'Insights Search', link: '/Reports/InsightsSearch/All' },
];

const theSectionTitle = "Insights Search";
const theSectionDesc = "Configure and run your Audit Searches from this screen.  To get started give your Search a name, specify your search criteria, and then submit your Search. When the search results are ready they are automatically saved for your reference below.";
const isAdminPage = false;


/*
Displays the Insights Search home page for Company X, search against a specific tenant by a the current user.
*/
function ReportsSearch(props) {

    const { user, openCompanyNag, setOpenCompanyNag } = props;
    const { defaultWorkload } = useParams();

    // Component Constants
    const [company, setCompany] = useState("");
    const [focusTenantId, setFocusTenantId] = useState("");
    const [tenantHealthStats, setTenantHealthStats] = useState("");
    const [accessDenied, setAccessDenied] = useState(false);

    const [loading, setLoading] = useState(true);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);
    const navigate = useNavigate();
    const [submittedSearchCount, setSubmittedSearchCount] = useState(0);  // Used to "touch" the Search Results child component for an update.
    const [mySearchJobsCount, setMySearchJobsCount] = useState(0);  // Count up the number of jobs created by the current user.  Used to prevent too many Searches for a specific user (prevent malicious intent and prevent system performance problems).    

    // Form input related.
    const [formErrorText, setFormErrorText] = useState('');
    const [formSubmittedSuccessText, setFormSubmittedSuccessText] = useState('');
    // Tenant drop down.
    const [selectedTenantOption, setSelectedTenantOption] = useState('');
    // Date selection related.
    const previousDay = new Date();
    previousDay.setDate(previousDay.getDate());
    const nextDay = new Date();
    nextDay.setDate(nextDay.getDate() + 1);
    const [selectedStartDate, setSelectedStartDate] = useState(previousDay);
    const [selectedStartTime, setSelectedStartTime] = useState('00:00');
    const [selectedEndDate, setSelectedEndDate] = useState(nextDay);
    const [selectedEndTime, setSelectedEndTime] = useState('00:00');
    // Search Name selection related.
    const selectedNameValue = useRef("");
    const [errorSelectedName, setErrorSelectedName] = useState(false);
    const [selectedName, setSelectedName] = useState("");
    // Keyword search selection related.
    const selectedKeywordValue = useRef("");
    const [errorSelectedKeyword, setErrorSelectedKeyword] = useState(false);
    const [selectedKeyword, setSelectedKeyword] = useState("");
    // User selection related.
    const selectedUserIdValue = useRef("");
    const [errorSelectedUserId, setErrorSelectedUserId] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState("");
    // Site selection related.
    const selectedSiteValue = useRef("");
    const [errorSelectedSite, setErrorSelectedSite] = useState(false);
    const [selectedSite, setSelectedSite] = useState("");
    // Workloads related.    
    const [selectedWorkloads, setSelectedWorkloads] = useState([]);
    const [errorSelectedWorkloads, setErrorSelectedWorkloads] = useState(false);
    // Activities related.    
    const [selectedActivities, setSelectedActivities] = useState([]);
    const [selectedSharePointOperations, setSelectedSharePointOperations] = useState([]);
    const [selectedExchangeOperations, setSelectedExchangeOperations] = useState([]);
    const [selectedEntraIDOperations, setSelectedEntraIDOperations] = useState([]);
    const [selectedTeamsOperations, setSelectedTeamsOperations] = useState([]);
    const [selectedSharePointFriendly, setSelectedSharePointFriendly] = useState([]);
    const [selectedExchangeFriendly, setSelectedExchangeFriendly] = useState([]);
    const [selectedEntraIDFriendly, setSelectedEntraIDFriendly] = useState([]);
    const [selectedTeamsFriendly, setSelectedTeamsFriendly] = useState([]);


    // Component Functions
    // Load TenantHealthStats given the tenandId.
    async function fetchTenantHealthStats(theTenantId) {
        setLoading(true);
        try {

            // Check if current user has Report Reader access to the specified tenant.
            var checkReportAccessForTenant = verifyUserHasTenantRolePermissions(user, theTenantId, ROLE_CODE_TENANTREPORTREADER);
            if (!user || !checkReportAccessForTenant) {
                setAccessDenied(true);
            }
            else {
                setAccessDenied(false);
            }

            // We attempt to load the tenant health stats (which lets us see if the tenant is pending still etc).
            var response2 = await getHealthStatsByTenantId(theTenantId, 7, user);
            if (response2) {
                setTenantHealthStats(response2);
            }
            setLoading(false);
        }
        catch (e) {
            catchAxiosErr2(e, "ReportSearch.fetchTenantHealthStats", setLoading, setServiceIsDownError);
        }
    }

    useEffect(() => {
        /*
        load current User's company.                
        */
        async function fetchCompanyData() {
            setLoading(true);
            try {
                if (user && user.companyId) {
                    // The companyId was specified.
                    var response1 = await getCompanyByCompanyId(user.companyId, setServiceIsDownError);
                    if (response1) {
                        // Company is valid.  We reference Company using response because setting the Company may take some time.  
                        setCompany(response1);
                        // Set the default to the first item in the list if there is any.
                        if (response1.tenantList && response1.tenantList.length > 0) {
                            setSelectedTenantOption(response1.tenantList[0]);  // Set to the first item in the list.
                            // Set the default period in the drop down list (we default to 30 days).                            
                            fetchTenantHealthStats(response1.tenantList[0].id);
                            setFocusTenantId(response1.tenantList[0].id);
                        }
                        // Else the tenantList is empty.  Nothing in the selection list to set.                                        
                    }
                }

                setLoading(false);
            }
            catch (e) {
                catchAxiosErr2(e, "Report.fetchCompanyData", setLoading, setServiceIsDownError);
            }
        }

        fetchCompanyData();
    }, []);
    // So at this point - the value for selectedTenantOption is either empty or the tenant object in question.  
    // We can use the selectedTenantOption as what we need to load the reports for (tenant object).  

    // Event handlers for Tenant drop down list.
    const handleTenantOptionChange = (event) => {
        setSelectedTenantOption(event.target.value);
        fetchTenantHealthStats(event.target.value.id);
        setFocusTenantId(event.target.value.id);
        // Clear any errors.
        setFormErrorText("");
    };

    // HandleRun - check and validate input controls before running.
    async function handleRun() {
        // Validation.
        let myFormError = "";
        let criteriaProvidedCount = 0;
        let formattedSelectedStartDate = dayjs(selectedStartDate).format('YYYY-MM-DD');
        let formattedSelectedEndDate = dayjs(selectedEndDate).format('YYYY-MM-DD');
        // Reset form errors.  
        setErrorSelectedName(false);
        setErrorSelectedWorkloads(false);
        setFormSubmittedSuccessText("");

        // Check the search name field.
        if (selectedName == null || selectedName.length === 0) {
            myFormError = "Search Name cannot be empty.";
            setErrorSelectedName(true);  // Flag the error.            
        }

        // Check the start date and end date are valid.
        if (formattedSelectedStartDate > formattedSelectedEndDate) {
            myFormError = myFormError + " The start date cannot be before the end date.";
        }
        else {
            if (formattedSelectedStartDate == formattedSelectedEndDate) {
                if (selectedStartTime >= selectedEndTime) {
                    myFormError = myFormError + " The start time must be before the end time when the start date and end date are the same.";
                }
            }
            // Else the dates are not the same - in this case it's all valid at this point.                        
        }

        // Check that at least 1 workload is specified.
        if (selectedWorkloads && selectedWorkloads.length === 0) {
            myFormError = myFormError + " You must select at least 1 workload.";
            setErrorSelectedWorkloads(true);  // Flag the error.
        }

        // Check that at least 1 of the Search Criteria is provided.
        if (selectedKeyword && selectedKeyword.length > 0) {
            criteriaProvidedCount++;
        }
        if (selectedUserId && selectedUserId.length > 0) {
            criteriaProvidedCount++;
        }
        if (selectedSite && selectedSite.length > 0) {
            criteriaProvidedCount++;
        }
        // Note: Workloads doesn't count as a criteria in that sense.
        if (selectedActivities && selectedActivities.length > 0) {
            criteriaProvidedCount++;
        }
        // Not enough search criteria provided.
        if (criteriaProvidedCount == 0) {
            myFormError = myFormError + " You must provide at least 1 additional search criteria to search for.";
        }

        // If User has exceed the total number of their saved searches (generated by them) then prevent any new searches from being submitted.
        if (mySearchJobsCount >= MAX_SAVED_SEARCHES_PER_USER) {
            myFormError = "Sorry, your search was not submitted.  Reason: Every user is allowed a maximum of " + MAX_SAVED_SEARCHES_PER_USER + " saved searches, you have exceeded the maximum number of allowed saved searches for your account.  You must delete some searches before trying again.";
        }

        // Check if we are to submit the search request.
        if (myFormError.length == 0) {
            // There were no errors.            
            setFormErrorText("");

            // Attempt to submit the search.  We have to do some prep below.
            // Note: We safely encode the siteuri, keywordtext before calling the WebAPI...ex.) encodeURIComponent(selectedSite), on backend it would need to be unencoded before saving to database ex.) string decodedSite = HttpUtility.UrlDecode(site);
            // Note: Activities were translated from friendly name back to Operation value which is what is stored in the database for the audit entries.
            // Note: Arrays are converted to semi-colon seperated string value (ex. SharePoint;EntraID;Exchange)
            // Note: The CriteriaStartDateUtc and CriteriaEndDateUtc - are stored as regular UTC dates with no offsets! ex.) 2023-10-25 21:00:00

            var payload = {
                // Required fields.
                id: 0,  // Note: The WebAPI function will automatically set the next Id, so this is not required to be filled in.
                tenantId: focusTenantId,
                searchName: encodeURIComponent(selectedName),
                searchCreatedDate: new Date(),  // Note: The WebAPI function will automatically use the current server time, so this is not required to be filled in.                
                searchCreatedByUser: await encryptKey(user.userEmail),  // Use the current logged in user (UPN).  We encrypt this one item.
                jobStatus: 0, // Note: The WebAPI function will automatically set the job status to queued, so this is not required to be filled in.
                lastUpdatedDate: new Date(), // Note: The WebAPI function will automatically use the current server time, so this is not required to be filled in.
                lastUpdatedByUser: user.userEmail, // Use the current logged in user (UPN).
                totalResults: 0,
                //criteriaStartDateUtc: (new Date(combineDateJsDateAndTimeToDateTimeOffset(formattedSelectedStartDate, selectedStartTime) + " UTC")).toISOString(),  // Convert assumed UTC time entry from the inputs to ISO string format.  We need to force the UTC in the string conversion.
                criteriaStartDateUtc: combineDateJsDateAndTimeToDateTimeOffset(formattedSelectedStartDate, selectedStartTime),  // Convert assumed UTC time entry from the inputs to ISO string format.
                //criteriaEndDateUtc: (new Date(combineDateJsDateAndTimeToDateTimeOffset(formattedSelectedEndDate, selectedEndTime) + " UTC")).toISOString(), // Convert assumed UTC time entry from the inputs to ISO string format.  We need to force the UTC in the string conversion.
                criteriaEndDateUtc: combineDateJsDateAndTimeToDateTimeOffset(formattedSelectedEndDate, selectedEndTime),  // Convert assumed UTC time entry from the inputs to ISO string format. 
                criteriaForWorkload: convertArrayValuesToSemicolonSeperatedString(selectedWorkloads),
                // Optional fields.
                criteriaKeyword: selectedKeyword && selectedKeyword.length > 0 ? encodeURIComponent(selectedKeyword) : "",
                criteriaSiteOrLocation: selectedSite && selectedSite.length > 0 ? encodeURIComponent(selectedSite) : "",
                criteriaForUsers: selectedUserId && selectedUserId.length > 0 ? encodeURIComponent(selectedUserId) : "",
                criteriaActivitiesSharePoint: convertArrayValuesToSemicolonSeperatedString(selectedSharePointOperations),
                criteriaActivitiesEntraID: convertArrayValuesToSemicolonSeperatedString(selectedEntraIDOperations),
                criteriaActivitiesExchange: convertArrayValuesToSemicolonSeperatedString(selectedExchangeOperations),
                criteriaActivitiesTeams: convertArrayValuesToSemicolonSeperatedString(selectedTeamsOperations),
                criteriaActivitiesFriendlySharePoint: convertArrayValuesToSemicolonSeperatedString(selectedSharePointFriendly),
                criteriaActivitiesFriendlyEntraID: convertArrayValuesToSemicolonSeperatedString(selectedEntraIDFriendly),
                criteriaActivitiesFriendlyExchange: convertArrayValuesToSemicolonSeperatedString(selectedExchangeFriendly),
                criteriaActivitiesFriendlyTeams: convertArrayValuesToSemicolonSeperatedString(selectedTeamsFriendly)
            };
            var keyString = JSON.stringify(payload);

            /*
            console.log("SUBMIT");
            console.log(`Payload: ${JSON.stringify(payload)}`);
            console.log(`Submit to... ${WEB_API_URL}Search/CreateSearchJob`);
            //ex.) 2023-11-01 01:00:00 TO 2023-11-01T22:53:15.415Z            
            //console.log("HELLO: " + (new Date(combineDateJsDateAndTimeToDateTimeOffset(formattedSelectedStartDate, selectedStartTime) + " UTC")).toISOString());
            console.log("HELLO_Workloads: " + selectedWorkloads);
            console.log("HELLO_Activities: " + selectedActivities);           
            console.log("HELLO_Entra Operations: " + selectedEntraIDOperations);
            console.log("HELLO_Entra Friendly: " + selectedEntraIDFriendly);
            console.log("HELLO_Exchange Operations: " + selectedExchangeOperations);
            console.log("HELLO_Exchange Friendly: " + selectedExchangeFriendly);
            console.log("HELLO_SharePoint Operations: " + selectedSharePointOperations);        
            console.log("HELLO_SharePoint Friendly: " + selectedSharePointFriendly);                                    
            console.log("HELLO_Teams Operations: " + selectedTeamsOperations);        
            console.log("HELLO_Teams Friendly: " + selectedTeamsFriendly);        
            */

            axios.post(`${WEB_API_URL}Search/CreateSearchJob`, null,
                {
                    params: {
                        key: keyString,
                    }
                }
            ).then((response) => {
                // If Search submits successfully - clear the form text to prevent multiple accidental easy submissions. Also set success message to be displayed.
                setFormSubmittedSuccessText("Your Search was successfully submitted, it is now queued for processing.");
                setSubmittedSearchCount(submittedSearchCount + 1);  // Increment the submitted search count, which is passed into the search list sub component which automatically refreshes now.
                handleClear();
            }).catch(e => {
                console.log(e);
                setFormErrorText("There was a problem submitting your Search.  Error code: " + e.message);
            });

        }
        else {
            setFormErrorText(myFormError);
        }

    }

    // HandleClear - clear the controls and related values in memory.
    async function handleClear() {

        setSelectedName("");
        setSelectedKeyword("");
        setSelectedSite("");
        setSelectedUserId("");

        setSelectedWorkloads([]);

        setSelectedActivities([]);
        setSelectedSharePointOperations([]);
        setSelectedEntraIDOperations([]);
        setSelectedExchangeOperations([]);
        setSelectedTeamsOperations([]);
        setSelectedSharePointFriendly([]);
        setSelectedEntraIDFriendly([]);
        setSelectedExchangeFriendly([]);
        setSelectedTeamsFriendly([]);

        setSelectedStartDate(previousDay);
        setSelectedStartTime('00:00');
        setSelectedEndDate(nextDay);
        setSelectedEndTime('00:00');

        setFormErrorText("");

        setErrorSelectedName(false);
        setErrorSelectedWorkloads(false);
    }

    // Update mySearchJobsCount.  Called from SearchJobList child component.
    function updateMySearchJobsCount(theCount) {
        setMySearchJobsCount(theCount);
    }

    // Populate search from the SearchJobList.  Called from SearchJobList child component.    
    // Repopulates a new search with a previous job's search criteria and gives the search name a new name ex.) Name_New.
    function populateSearch(theSearchJob) {
        handleClear();  // Clear the search first.

        setSelectedName(theSearchJob.searchName + "_New");
        if (theSearchJob.criteriaKeyword != null) {
            setSelectedKeyword(theSearchJob.criteriaKeyword);
        }
        else {
            setSelectedKeyword("");
        }
        if (theSearchJob.criteriaSiteOrLocation != null) {
            setSelectedSite(theSearchJob.criteriaSiteOrLocation);
        }
        else {
            setSelectedSite("");
        }
        if (theSearchJob.criteriaForUsers != null) {
            setSelectedUserId(theSearchJob.criteriaForUsers);
        }
        else {
            setSelectedUserId("");
        }

        setSelectedWorkloads(convertSplitStringBySemicolonToArray(theSearchJob.criteriaForWorkload));

        var tempArray1 = null;
        var tempArray2 = null;
        var tempArray3 = null;
        var tempArray4 = null;

        setSelectedSharePointOperations(convertSplitStringBySemicolonToArray(theSearchJob.criteriaActivitiesSharePoint));
        tempArray1 = convertSplitStringBySemicolonToArray(theSearchJob.criteriaActivitiesFriendlySharePoint);
        setSelectedSharePointFriendly(tempArray1);

        setSelectedExchangeOperations(convertSplitStringBySemicolonToArray(theSearchJob.criteriaActivitiesExchange));
        tempArray2 = convertSplitStringBySemicolonToArray(theSearchJob.criteriaActivitiesFriendlyExchange);
        setSelectedExchangeFriendly(tempArray2);

        setSelectedEntraIDOperations(convertSplitStringBySemicolonToArray(theSearchJob.criteriaActivitiesEntraID));
        tempArray3 = convertSplitStringBySemicolonToArray(theSearchJob.criteriaActivitiesFriendlyEntraID);
        setSelectedEntraIDFriendly(tempArray3);

        setSelectedTeamsOperations(convertSplitStringBySemicolonToArray(theSearchJob.criteriaActivitiesTeams));
        tempArray4 = convertSplitStringBySemicolonToArray(theSearchJob.criteriaActivitiesFriendlyTeams);
        setSelectedTeamsFriendly(tempArray4);

        // Finally take all of the Friendly arrays and add them together for setSelectedActivities.        
        // Use functional form of setState to ensure correct updates, otherwise they may not update in time.
        setSelectedActivities((prevSelectedActivities) => {
            const combinedArray = [
                ...prevSelectedActivities, // Include previous state
                ...tempArray1,
                ...tempArray2,
                ...tempArray3,
                ...tempArray4,
            ];
            return combinedArray;
        });

        // Set the dates.
        // Get and set the YYYY-MM-DD.
        var startDate = new Date(theSearchJob.criteriaStartDateUtc);
        var formattedStartDate = dayjs(startDate).format('YYYY-MM-DD');  // Adjust to our date format for our control.
        var endDate = new Date(theSearchJob.criteriaEndDateUtc);
        var formattedEndDate = dayjs(endDate).format('YYYY-MM-DD');   // Adjust to our date format for our control.
        setSelectedStartDate(formattedStartDate);
        setSelectedEndDate(formattedEndDate);
        // Get and set the hh:mm.
        var startHours = startDate.getHours();
        var startMinutes = startDate.getMinutes();
        var formattedStartTime = `${String(startHours).padStart(2, '0')}:${String(startMinutes).padStart(2, '0')}`;
        var endHours = endDate.getHours();
        var endMinutes = endDate.getMinutes();
        var formattedEndTime = `${String(endHours).padStart(2, '0')}:${String(endMinutes).padStart(2, '0')}`;
        setSelectedStartTime(formattedStartTime);
        setSelectedEndTime(formattedEndTime);

        /*
        console.log("ID: " + theSearchJob.id);
        console.log("selectedSharePointFriendly " + selectedSharePointFriendly);
        console.log("selectedExchangeFriendly: " + selectedExchangeFriendly);
        console.log("selectedEntraIdFriendly: " + selectedEntraIDFriendly);        
        console.log("selectedTeamsFriendly: " + selectedTeamsFriendly);  
        console.log("Combined Selected Activities: " + selectedActivities);        
        console.log("startDate: " + startDate);
        console.log("endDate: " + endDate);        
        console.log("startTime: " + formattedStartTime); 
        console.log("endTime: " + formattedEndTime); 
        */
    }



    // Component UI
    if (loading) {
        console.log("Loading Data ...")
        return (<ProgressBar message="Loading ..." loading={loading} />);
    }

    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }

    // A valid Tenant is chosen from the textfield selection drop down list.
    return (
        <>
            <DisplayBreadCrumb paths={theBreadcrumbPath} />
            { /* Display report section icon logo. */}
            <div style={{ display: 'flex' }}>
                <div style={{ flex: 1, textAlign: 'left', alignSelf: 'flex-start' }}>
                    <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />
                </div>
                <div style={{ width: '75px', textAlign: 'left', alignSelf: 'flex-start' }}>
                    <br />
                    <ManageSearchIcon style={{ fontSize: '3rem' }} />
                </div>
            </div>
            <CompanyNag company={company} openCompanyNag={openCompanyNag} setOpenCompanyNag={setOpenCompanyNag} />

            <div>
                <Grid container spacing={2}>


                    {
                        accessDenied &&
                        <Grid item xs={12}><AccessDenied /></Grid>
                    }

                    {
                        !selectedTenantOption &&
                        (
                            <Grid item xs={12}>
                                <Alert severity="error">
                                    <AlertTitle>Your Company has not yet completed the setup process or you have not yet specified a valid Tenant.</AlertTitle>
                                </Alert>
                            </Grid>)
                    }
                    {
                        tenantHealthStats.status == 0 &&
                        (
                            <Grid item xs={12}>
                                <Alert severity="error">
                                    <AlertTitle>This Tenant is still pending validation.  Insights Search is not available until validation is completed.</AlertTitle>
                                </Alert>
                            </Grid>
                        )
                    }

                    {
                        formErrorText.length > 0 &&
                        (
                            <Grid item xs={12}>
                                <Alert severity="error">
                                    <AlertTitle>Form Error: {formErrorText}</AlertTitle>
                                </Alert>
                            </Grid>
                        )
                    }

                    {
                        formSubmittedSuccessText.length > 0 &&
                        (
                            <Grid item xs={12}>
                                <Alert severity="success">
                                    <AlertTitle>{formSubmittedSuccessText}</AlertTitle>
                                </Alert>
                            </Grid>
                        )
                    }

                    <Grid item xs={12}>


                        {selectedTenantOption &&
                            (
                                <>
                                    <TenantDropDown
                                        user={user}
                                        company={company}
                                        handleTenantOptionChange={handleTenantOptionChange}
                                        selectedTenantOption={selectedTenantOption}></TenantDropDown>
                                    <div style={{ height: '15px' }}></div>
                                </>
                            )
                        }
                        {
                            selectedTenantOption && !accessDenied && tenantHealthStats.status != 0 &&
                            (
                                <>
                                    {/* Render Search Name Textfield control. */}
                                    <div id="searchInput"></div>
                                    <SearchNameSelector tenantId={focusTenantId} selectedName={selectedName} setSelectedName={setSelectedName} selectedNameValue={selectedNameValue} errorSelectedName={errorSelectedName} />
                                    <div style={{ height: '15px' }}></div>
                                    {/* Render reusable Start and End date picker control. */}
                                    <StartAndEndDateSelector selectedStartDate={selectedStartDate} setSelectedStartDate={setSelectedStartDate} selectedStartTime={selectedStartTime} setSelectedStartTime={setSelectedStartTime} selectedEndTime={selectedEndTime} selectedEndDate={selectedEndDate} setSelectedEndDate={setSelectedEndDate} setSelectedEndTime={setSelectedEndTime} />
                                    <div style={{ height: '15px' }}></div>
                                    { /* Render Keyword Search Textfield control. */}
                                    <KeywordSelector tenantId={focusTenantId} selectedKeyword={selectedKeyword} setSelectedKeyword={setSelectedKeyword} selectedKeywordValue={selectedKeywordValue} errorSelectedKeyword={errorSelectedKeyword} />
                                    <div style={{ height: '15px' }}></div>
                                    { /* Render reusable Site Collecton / Location Textfield control. */}
                                    <SiteCollectionSelector tenantId={focusTenantId} selectedSite={selectedSite} setSelectedSite={setSelectedSite} selectedSiteValue={selectedSiteValue} errorSelectedSite={errorSelectedSite} />
                                    <div style={{ height: '15px' }}></div>
                                    { /* Render reusable UserID Textfield control. */}
                                    <UserSelector tenantId={focusTenantId} selectedUserId={selectedUserId} setSelectedUserId={setSelectedUserId} selectedUserIdValue={selectedUserIdValue} errorSelectedUserId={errorSelectedUserId} />
                                    <div style={{ height: '15px' }}></div>
                                    { /* Render reusable Workload multi-item Selector control. */}
                                    <WorkloadSelector tenantId={focusTenantId} selectedWorkloads={selectedWorkloads} setSelectedWorkloads={setSelectedWorkloads} defaultWorkload={defaultWorkload} errorSelectedWorkloads={errorSelectedWorkloads} />
                                    <div style={{ height: '15px' }}></div>
                                    { /* Render reusable Activities friendly name Selector control. */}
                                    <ActivitiesSelector tenantId={focusTenantId} selectedActivities={selectedActivities} setSelectedActivities={setSelectedActivities} selectedSharePointOperations={selectedSharePointOperations} setSelectedSharePointOperations={setSelectedSharePointOperations} selectedExchangeOperations={selectedExchangeOperations} setSelectedExchangeOperations={setSelectedExchangeOperations} selectedEntraIDOperations={selectedEntraIDOperations} setSelectedEntraIDOperations={setSelectedEntraIDOperations} selectedTeamsOperations={selectedTeamsOperations} setSelectedTeamsOperations={setSelectedTeamsOperations}
                                        selectedSharePointFriendly={selectedSharePointFriendly} setSelectedSharePointFriendly={setSelectedSharePointFriendly} selectedExchangeFriendly={selectedExchangeFriendly} setSelectedExchangeFriendly={setSelectedExchangeFriendly} selectedEntraIDFriendly={selectedEntraIDFriendly} setSelectedEntraIDFriendly={setSelectedEntraIDFriendly} selectedTeamsFriendly={selectedTeamsFriendly} setSelectedTeamsFriendly={setSelectedTeamsFriendly}
                                    />

                                    <div style={{ height: '15px' }}></div>
                                </>
                            )
                        }
                        {
                            !accessDenied && tenantHealthStats.status != 0 &&
                            (
                                <>
                                    <Button variant="contained" type="button" onClick={() => handleRun()}>Search</Button>
                                    &nbsp; <Button variant="contained" type="button" onClick={() => handleClear()}>Clear</Button>
                                </>
                            )
                        }

                    </Grid>


                    {
                        selectedTenantOption && !accessDenied && tenantHealthStats.status != 0 &&
                        (
                            <>
                                <Grid item xs={12}>
                                    <br />
                                    <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%' }}>
                                        <SearchResultsList tenantId={focusTenantId} user={user} submittedSearchCount={submittedSearchCount} populateSearch={populateSearch} updateMySearchJobsCount={updateMySearchJobsCount} />
                                    </Paper>
                                </Grid>
                            </>
                        )
                    }

                    <Grid item xs={12}>
                        <br />
                        Need a custom or new report?  Provide us with your <Link onClick={() => navigate(`/Support/SupportHome`)} component="button">Product Feedback</Link>.
                        <br />* Inputs on this page are using GMT/UTC time periods.
                    </Grid>
                </Grid>
            </div>
        </>)

}
export default ReportsSearch;