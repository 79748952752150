const JOB_PROCESS_INTERVALS = [
    {
        value: '30',
        label: '30 mins'
    },
    {
        value: '60',
        label: '60 mins'
    },
    {
        value: '90',
        label: '90 mins'
    },
    {
        value: '120',
        label: '120 mins'
    },
    {
        value: '150',
        label: '150 mins'
    },
    ,
    {
        value: '180',
        label: '3 hours'
    },
    {
        value: '720',
        label: '12 hours'
    },
    {
        value: '1440',
        label: '1 Day'
    },
];

export { JOB_PROCESS_INTERVALS };