// React Components
import * as React from 'react';
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Audit Vault Components
import ProgressBar from '../../components/common/ProgressBar';
import TenantForm from "../../components/TenantForm";
import AccessDenied from '../../components/common/AccessDenied';
import ServiceIsDown from '../../components/common/ServiceIsDown';

import { getCompanyByCompanyId } from '../../utilities/common-company';

// Micrsoft Graph Components
function CreateTenantAdmin(props) {

    const { user, setUser } = props;

    const { companyId } = useParams();
    const [company, setCompany] = useState(null);
    const [loading, setLoading] = useState(true);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);

    let isAdminPage = true;

    useEffect(() => {
        async function fetchData() {
            try {
                // Need to get the selected company, not the company associated with Admin user.
                if (companyId) {
                    const response = await getCompanyByCompanyId(companyId, setServiceIsDownError);
                    if (response) {
                        setCompany(response);
                    }
                }
            }
            catch (e) {
                console.log(e);
                setServiceIsDownError(true);
            }
            finally {
                setLoading(false);
            }
        }
        fetchData();
    }, []);

    if (user) {
        if (!user.isSystemAdmin) {
            return (<AccessDenied></AccessDenied>)
        }
    }

    if (loading) {
        return (<ProgressBar loading={loading} message="Loading ..." />)
    }

    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }

    return (
        <>
            {!loading && company &&
                <TenantForm user={user} companyId={companyId} tenantId={company.authorizedByTenantId} isAdminPage={isAdminPage} setUser={setUser}>
                </TenantForm>}
        </>
    );

}
export default CreateTenantAdmin;