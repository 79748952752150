// React Components
import React, { useEffect, useState } from 'react';

// Material UI Components
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

// Audit Vault Components
import ProgressBar from '../components/common/ProgressBar';
import DisplayBreadCrumb from "../components/common/DisplayBreadCrumb";
import DisplaySectionTitleAndDescription from "../components/common/DisplaySectionTitleAndDescription";
import SessionTimeout from "../components/common/SessionTimeout";


// Audit Vault Utilities
import { getUserOrganizationData, loginToMicrosoft } from '../utilities/common-user-utils';
import getRole from '../utilities/common-role-utils';

// MSAL Components
import { useMsal, useIsAuthenticated } from '@azure/msal-react';

function GetProfile(props) {

    const { user } = props;

    return (
        <>
            <ProfileContent user={user} />
        </>
    );
}

const theBreadcrumbPath = [
    { name: 'Home', link: '/' },
    { name: 'Profile', link: '/myprofile' },
];

const theSectionTitle = "My Profile";
const theSectionDesc = "This is where you can review your Audit Vault and Microsoft 365 account settings.";
const isAdminPage = false;

function ProfileContent(props) {

    const { user } = props;

    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [orgData, setOrgData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loginTimeout, setLoginTimeout] = useState(false);
    const [companyNotSetup, setCompanyNotSetup] = useState(false);

    const handleLogout = (logoutType) => {
        if (logoutType === "redirect") {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
        }
    }

    useEffect(() => {
        async function fetData() {

            setLoginTimeout(false);

            try {
                var response = await getUserOrganizationData(instance, isAuthenticated, accounts, orgData, setOrgData);
                if (response) {
                    console.log("ProfileContent: ");
                    console.log(response);
                }
            }
            catch (e) {
                console.log("Error: ProfileContent");
                console.log(e);
                if (e.toString().indexOf("invalid_grant") > 0) {
                    // means that company has not been set up yet or admin consent has not been granted yet,
                    setCompanyNotSetup(true);
                }
                else if (e.toString().indexOf("login_required") > 0) {
                    console.log("Logging into Microsoft again.");
                    loginToMicrosoft(instance);
                }
                else
                    setLoginTimeout(true);

            }
            finally {
                setLoading(false);
            }
        };
        fetData();
    }, []);

    if (loginTimeout) {
        return (<SessionTimeout></SessionTimeout>)
    }

    if (companyNotSetup) {
        <Typography component="span"><b>Tenance Consent Reguired.</b></Typography>
    }

    return (
        <>
            <DisplayBreadCrumb paths={theBreadcrumbPath} />
            <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />
            <ProgressBar message="Loading ..." loading={loading} />

            {!loading && accounts[0] && isAuthenticated && orgData &&
                <>

                    <Card sx={{ maxWidth: 800 }} variant="outlined" key="card1">
                        <CardContent>
                            <Typography component="span"><b>Your Audit Vault Security Role Assignments:</b></Typography>
                            <Table sx={{ minWidth: 250 }} aria-label="simple table">
                                <TableHead>
                                    <TableCell key="perm1">Security Access Roles</TableCell>
                                    <TableCell key="perm2">Tenant</TableCell>
                                </TableHead>
                                <TableBody>
                                    {user.isSystemAdmin &&
                                        <TableRow>
                                            <TableCell key={'Perm-SA'} >System Administrator</TableCell>
                                            <TableCell key={'PermTen-SA'} ></TableCell>
                                        </TableRow>
                                    }
                                    {user.isCompanyAdmin &&
                                        <TableRow>
                                            <TableCell key={'Perm-CA'} >Company Administrator</TableCell>
                                            <TableCell key={'PermTen-CA'} ></TableCell>
                                        </TableRow>
                                    }
                                    {user.permissions.map((row) => {
                                        return (
                                            <TableRow>
                                                <TableCell key={'Perm-' + row.id} >{getRole(Number(row.roleId))}</TableCell>
                                                {row.tenantUrl &&
                                                    <TableCell key={'PermTen-' + row.id} >{row.tenantUrl}</TableCell>
                                                }
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>
                    <Typography component="div" key="div5"><br></br></Typography>
                    <Card sx={{ maxWidth: 800 }} variant="outlined" key="card3">
                        <CardContent>
                            <Typography component="span"><b>Your Microsoft 365 Account Properties:</b></Typography>
                            <Table sx={{ minWidth: 250 }} aria-label="simple table">

                                <TableBody>
                                    <TableRow>
                                        <TableCell key="fn1">Name:</TableCell>
                                        <TableCell key="fn2">{accounts[0].name}</TableCell>
                                        <TableCell key="fn3"></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell key="ln1">User Name:</TableCell>
                                        <TableCell key="ln2">{accounts[0].username}</TableCell>
                                        <TableCell key="ln3"></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell key="id1">Id:</TableCell>
                                        <TableCell key="id2">{accounts[0].localAccountId}</TableCell>
                                        <TableCell key="id3"></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell key="tenid1">Tenant Id:</TableCell>
                                        <TableCell key="tenid2">{accounts[0].tenantId}</TableCell>
                                        <TableCell key="tenid3"></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>
                    <Typography component="div" key="div4"><br></br></Typography>
                    <Card sx={{ maxWidth: 800 }} variant="outlined">
                        <CardContent>
                            <Typography component="span"><b>Your Microsoft Organizations:</b></Typography>
                            <Table sx={{ minWidth: 250 }} aria-label="simple table">
                                <TableHead>
                                    <TableCell key="ten1">Tenant Id</TableCell>
                                    <TableCell key="ten2">Verified Domains</TableCell>
                                </TableHead>
                                <TableBody>
                                    {orgData.value.map((row) => {
                                        return (
                                            <TableRow>
                                                <TableCell key={row.id + "-TenantId"}><p>{row.id}</p></TableCell>
                                                <TableCell key={`org${row.id}`}>
                                                    {row.verifiedDomains.map((rowOrg) => {
                                                        return (<p>{rowOrg.name}</p>)
                                                    })}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </CardContent>
                        <CardActions>
                            <Button size="small" onClick={() => handleLogout("redirect")}>Sign Out</Button>
                        </CardActions>
                    </Card>

                </>
            }
        </>
    );
};

export default GetProfile;