// React Components
import React from 'react';
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import dayjs from 'dayjs';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";

// Material UI Components
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';


// Audit Vault Components
import CompanySetUpStep1 from '../../components/companysetup/CompanySetUpStep1';
import ServiceIsDown from "../../components/common/ServiceIsDown";
import MyCompanyCheckout from './MyCompanyCheckout';
import MyCompanyPreCheckout from '../companysetup/MyCompanyPreCheckout';


// Audit Vault Common
import { validateEmail } from '../../utilities/common-user-utils';
import { loginToMicrosoft } from '../../utilities/common-user-utils';
import { getCompanyByAzureTenantId, getCompanyBySetupId, updateCompany, insertCompany} from '../../utilities/common-company';
import { createUser } from '../../utilities/common-user-utils';
import { encryptKey } from '../../utilities/common-encrypt-util';

// Audit Vault Constants
const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

import { COMPANY_SIZES } from "../../constants/constants-companysizes";
import ProgressBar from '../common/ProgressBar';

const steps = ['1: Your Company Details', '2: Add Billing Info', '3: Complete Order & Start Using Audit Vault for M365']

function CompanySetup(props) {

    const { user, setupId } = props;

    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const [company, setCompany] = useState("");
    const [loading, setLoading] = useState(true);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);

    const navigate = useNavigate();

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());

    // company info fields
    const [companyIdValue, setCompanyIdValue] = useState(0);
    const [companyNameValue, setCompanyNameValue] = useState("");
    const [companySizeValue, setCompanySizeValue] = useState(COMPANY_SIZES[0].value);
    const [countryValue, setCountryValue] = useState('');
    const companySize = useRef("");
    const country = useRef("");
    const [firstNameValue, setFirstNameValue] = useState('');
    const [lastNameValue, setLastNameValue] = useState('');
    const [emailValue, setEmailValue] = useState('');
    const [phoneValue, setPhoneValue] = useState('');
    const [agreeToTermsValue, setAgreeToTermsValue] = useState(false);
    const [authorizedEmailValue, setAuthorizedEmailValue] = useState('');

    const [companyAlreadyExists, setCompanyAlreadyExists] = useState(false);

    const [errorCompanyName, setCompanyNameError] = useState(false);
    const [errorFirstName, setFirstNameError] = useState(false);
    const [errorLastName, setLastNameError] = useState(false);
    const [errorEmail, setEmailError] = useState(false);
    const [errorPhone, setPhoneError] = useState(false);
    const [errorAgreeToTerms, setAgreeToTermsError] = useState(false);

    // Product Info From Stripe
    const [stripeProducts, setStripeProducts] = useState("");

    // Billing info fields
    const [product, setProduct] = useState("");
    const [price, setPrice] = useState("");
    const [billingInterval, setBillingInterval] = useState("year");
    const [billingCustomerName, setBillingCustomerName] = useState("");
    const [billingCustomerPhone, setBillingCustomerPhone] = useState("");
    const [billingCustomerEmail, setBillingCustomerEmail] = useState("");
    const [billingCustomerAddressLine1, setBillingCustomerAddressLine1] = useState("");
    const [billingCustomerAddressLine2, setBillingCustomerAddressLine2] = useState("");
    const [billingCustomerCity, setBillingCustomerCity] = useState("");
    const [billingCustomerProvince, setBillingCustomerProvince] = useState("");
    const [billingCustomerPostalCode, setBillingCustomerPostalCode] = useState("");
    const [billingCustomerCountry, setBillingCustomerCountry] = useState("");
    const [promoCode, setPromoCode] = useState("");

    const [billingCustomerNameError, setBillingCustomerNameError] = useState(false);
    const [billingCustomerPhoneError, setBillingCustomerPhoneError] = useState(false);
    const [billingCustomerEmailError, setBillingCustomerEmailError] = useState(false);
    const [billingCustomerAddressLine1Error, setBillingCustomerAddressLine1Error] = useState(false);
    const [billingCustomerAddressLine2Error, setBillingCustomerAddressLine2Error] = useState(false);
    const [billingCustomerCityError, setBillingCustomerCityError] = useState(false);
    const [billingCustomerProvinceError, setBillingCustomerProvinceError] = useState(false);
    const [billingCustomerPostalCodeError, setBillingCustomerPostalCodeError] = useState(false);

    const promo = useRef("");
    const [totalPrice, setTotalPrice] = useState("");

    const validateCompanyInfoHandler = () => {
      var isError = false;

      if (companyNameValue.trim() === '' ? setCompanyNameError(true) : setCompanyNameError(false));
      if (firstNameValue.trim() === '' ? setFirstNameError(true) : setFirstNameError(false));
      if (lastNameValue.trim() === '' ? setLastNameError(true) : setLastNameError(false));
      if (emailValue.trim() === '' ? setEmailError(true) : setEmailError(false));
      if (validateEmail(emailValue.trim()) == false ? setEmailError(true) : setEmailError(false));
      if (!phoneValue || phoneValue.trim() === '' ? setPhoneError(true) : setPhoneError(false));
      if (agreeToTermsValue == false ? setAgreeToTermsError(true) : setAgreeToTermsError(false))
      if (companyNameValue.trim() === '') { isError = true; }
      if (firstNameValue.trim() === '') { isError = true; }
      if (lastNameValue.trim() === '') { isError = true; }
      if (emailValue.trim() === '') { isError = true; }
      if (validateEmail(emailValue.trim()) == false) { isError = true; }
      if (!phoneValue || phoneValue.trim() === '') { isError = true; }
      if (countryValue.trim() === '') { isError = true; }

      if (agreeToTermsValue == false) { isError = true }

      return isError;
    }

    function handleBillingValidate()
    {
        let isError=false;

        if (billingCustomerName.trim() === '' ? setBillingCustomerNameError(true) : setBillingCustomerNameError(false));
        if (billingCustomerName.trim() === '') {isError = true;}
        if (billingCustomerPhone.trim() === '' ? setBillingCustomerPhoneError(true) : setBillingCustomerPhoneError(false));
        if (billingCustomerPhone.trim() === '') {isError = true;}
        if (billingCustomerEmail.trim() === '' ? setBillingCustomerEmailError(true) : setBillingCustomerEmailError(false));
        if (billingCustomerEmail.trim() === '') {isError = true;}
        if (validateEmail(billingCustomerEmail.trim()) == false) {isError = true; setBillingCustomerEmailError(true)}
        if (billingCustomerAddressLine1.trim() === '' ? setBillingCustomerAddressLine1Error(true) : setBillingCustomerAddressLine1Error(false));
        if (billingCustomerAddressLine1.trim() === '') {isError = true;}
        if (billingCustomerCity.trim() === '' ? setBillingCustomerCityError(true) : setBillingCustomerCityError(false));
        if (billingCustomerCity.trim() === '') {isError = true;}

        if (billingCustomerCountry === 'US' || billingCustomerCountry === 'CA')
        {
            if (billingCustomerPostalCode.trim() === '')
            {
                setBillingCustomerPostalCodeError(true);
                isError = true;
            }
            else
            {
                setBillingCustomerPostalCodeError(false);
            }

            if (billingCustomerProvince.trim() === '')
            {
                setBillingCustomerProvinceError(true);
                isError = true;
            }
            else
            {
                setBillingCustomerProvinceError(false);
            }
        }
        else
        {
            setBillingCustomerPostalCodeError(false);
            setBillingCustomerProvinceError(false);
        }

        if (!isError)
        {
            handleNext();
        }
    }

  async function getProductAndPriceIds(val)
  {
    for (let i = 0; i < stripeProducts.length; i++) {
      if (companySizeValue > stripeProducts[i].minUserCount)
      {
        setProduct(stripeProducts[i]);
        for (let x = 0; x < stripeProducts[i].productPrices.length; x++) {
            if (stripeProducts[i].productPrices[x].billingInterval == val)
            {
                setPrice(stripeProducts[i].productPrices[x]);
                return stripeProducts[i].productPrices[x];
            }
        }
        break;
      }
    }
  }

    const handleNext = async () => {
        let newSkipped = skipped;
        let isError = false;
        getProductAndPriceIds(billingInterval);
        
        console.log(`Active Step: ${activeStep}`)
        if (activeStep == 0) {
            isError = validateCompanyInfoHandler(); 
            if (!isError && !companyIdValue)
            {
            setLoading(true);
            // insert the company here since user logged in first and didnt click on sign in with microsoft.
            var response = await handleInsert();
            }
            else if (!isError)
            {
                handleUpdate();
            }
        }
        
        if(activeStep == 1 || activeStep == 2)
        {
            isError = validateCompanyInfoHandler(); 
            if (!isError && companyIdValue)
            {
                handleUpdate();
            }
        }

        if (!isError) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setSkipped(newSkipped);
        }

    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    const handleInsertUser = async (graphId, email, displayName, companyId) => {
        var createUserResponse = await createUser(graphId, email, displayName, companyId); 
        if (createUserResponse)
            return createUserResponse;
    }

    const handleUpdate = async () => {
        {
            try{
            var response = await updateCompany(
                companyIdValue,
                companyNameValue,
                billingCustomerAddressLine1,
                billingCustomerCity,
                billingCustomerProvince,
                countryValue,
                phoneValue,
                billingCustomerPostalCode,
                firstNameValue,
                lastNameValue,
                emailValue,
                phoneValue,
                companySizeValue,
                0,
                accounts[0].username,
                accounts[0].tenantId,
                dayjs(),
                ""
                );

            if (response)
            {
                console.log("Update Company Response:");
                console.log(response);
            }
        }
        catch (e)
        {
            console.log("ERROR: CompanySetup.handleUpdate");
            console.log(e);
            setServiceIsDownError(true);
        }
      }
    }

    const handleInsert = async () => {

      let isError = validateCompanyInfoHandler();
      // check if a company already exists for this user
    
      if (!isError)
      {
            try
            {

                var authById = "";
                var authByGraphId = "";
                if (isAuthenticated)
                {
                    authById = accounts[0].username;
                    authByGraphId = accounts[0].localAccountId;
                }

                var companyResponse = await insertCompany(
                    companyNameValue,
                    countryValue,
                    phoneValue,
                    firstNameValue,
                    lastNameValue,
                    emailValue,
                    phoneValue,
                    companySizeValue,
                    setupId,
                    authById,
                    authByGraphId
                );

                if(companyResponse)
                {
                  console.log(companyResponse);
                  setCompanyIdValue(companyResponse.data);

                  if (!isAuthenticated)
                  {
                    // Login to Microsoft to verify the user
                    loginToMicrosoft(instance);
                  }
                  else
                  {
                    await fetchData();
                  }
                }
            }
            catch(e)
            {
                console.log("ERROR: CompanySetUp.handleInsert");
                console.log(e);
                setServiceIsDownError(true);
            };
      }
    }

    async function deleteSignUpData() {

        try 
        {
            if (setupId) {
                setLoading(true);
                const response = await getCompanyBySetupId(setupId);
                if (response) {
                    
                    // check if a company has already been created for this organization
                    var checkResponse = await getCompanyByAzureTenantId(accounts[0].tenantId);
                    if (checkResponse)
                    {
                        if (checkResponse.authorizedByTenantId == accounts[0].tenantId && response.setupStageComplete == 0)
                        {  
                            var rsaKey = await encryptKey(`{Id: ${response.id}}`);
                            if (rsaKey) {
                                var result = await axios.delete(`${WEB_API_URL}Company/DeleteTempCompany`, {
                                    params: {
                                        key: `${rsaKey}`
                                    }
                                });
                            }
                        }
                    }
                }
            }
            navigate('/Support/SupportHome');
  
        }
        catch (e) {
            setServiceIsDownError(true);
            console.log("ERROR: MyCompanySetup.deleteSignUpData");
            console.log(e);
        }
        finally
        {
            setLoading(false);
        }
    }
    

    async function fetchData() {
      try 
      {
        if (loading) 
        {
            // set the default values
            setCompanySizeValue(3000);
            getProductAndPriceIds(billingInterval);
            
            setCountryValue("US");  // Set default country as United States.
            setPromoCode("");

            // check if a company has already been created for this organization
            if (isAuthenticated)
            {
                var checkResponse = await getCompanyByAzureTenantId(accounts[0].tenantId);
                console.log(checkResponse);
                if (checkResponse != null)
                {
                    if (checkResponse.authorizedByTenantId == accounts[0].tenantId)
                    {
                        setCompanyAlreadyExists(true);
                    }
                    else
                    {
                        var stripeProductsResponse = await axios.get(`${WEB_API_URL}Billing/get-products`);
                        if (stripeProductsResponse)
                        {
                            setStripeProducts(stripeProductsResponse.data);
                            setProduct(stripeProductsResponse.data[0]);
                            if (isAuthenticated && setupId)
                            {
                                const response = await getCompanyBySetupId(setupId);
                                if (response) 
                                {
                                    setCompany(response);

                                    setCompanyIdValue(response.id);
                                    setCompanyNameValue(response.companyName);
                                    setCompanySizeValue(response.companySize);

                                    getProductAndPriceIds(billingInterval);

                                    setCountryValue(response.country);                         
                                    setFirstNameValue(response.primaryContactFirstName);
                                    setLastNameValue(response.primaryContactLastName);
                                    setEmailValue(response.primaryContactEmail);
                                    setPhoneValue(response.primaryContactPhone);
                                    setAgreeToTermsValue(response.isAccountAuthorized); // set to true here since user is coming back to the page after logging in from Microsoft.
                                    setAuthorizedEmailValue(response.companyAppAdminEmail);

                                    // insert the logged in user as a company admin
                                    var insertCompanyAdminResponse = await handleInsertUser(accounts[0].localAccountId, accounts[0].username, accounts[0].name, response.id);
                                    if (insertCompanyAdminResponse)
                                    {
                                        console.log(company)
                                    }
                                }
                                
                            }
                        }
                    }
                }
            }
        
            
        }
    }
    catch (e) {
        console.log("ERROR: MyCompanySetup.fetchData")
        console.log(e);
        setServiceIsDownError(true);
    }
    finally{
        setLoading(false);
    }
  }

    useEffect(() => {
        fetchData();
    }, []);

    // Component UI
    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }

    if (companyAlreadyExists)
    {
        return (<>
        <Alert severity='warning'><AlertTitle>The Company you logged in as is already setup for use with Audit Vault for M365.</AlertTitle>
        <Typography component='div'>Click the button below to navigate to your organizations' support home page.</Typography>
        <Typography component='div'><br></br></Typography>
        <Button onClick={deleteSignUpData} variant="contained">Support Home</Button>
        </Alert></>)
    }

    if (loading)
    {
        return(<ProgressBar loading={loading}></ProgressBar>)
    }

    /* Company Setup Stages
          Created = 0,            // first created by user 
          PaymentComplete = 1,    // Payment completed by Authorized user at organization
          TenantCreated = 2,      // Tenant Created
          GrantedAccess = 3,      // Global Admin granted access to Audit Vault to Tenant
          SetupComplete = 4       // Set up complete and first audit ran successfully
    */

    if (!loading) {
        return (
            <>
                <Typography variant="h5" component="h5">Audit Vault for M365 - 3 Easy Steps to Sign Up</Typography>
                <div><br></br></div>
                <Box sx={{ width: '100%' }}>
                    <Stepper activeStep={activeStep} sx={{ paddingBottom: '3ch' }}>
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}

                    </Stepper>
                    <React.Fragment>

                        {activeStep === 0 ?

                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1, width: '100ch' },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <CompanySetUpStep1
                                    stripeProducts={stripeProducts}
                                    product={product} setProduct={setProduct}
                                    price={price} setPrice={setPrice}
                                    billingInterval={billingInterval} setBillingInterval={setBillingInterval}
                                    companyNameValue={companyNameValue}
                                    setCompanyNameValue={setCompanyNameValue}
                                    errorCompanyName={errorCompanyName}
                                    setCompanyNameError={setCompanyNameError}
                                    companySizeValue={companySizeValue}
                                    setCompanySizeValue={setCompanySizeValue}
                                    companySize={companySize}
                                    firstNameValue={firstNameValue}
                                    setFirstNameValue={setFirstNameValue}
                                    errorFirstName={errorFirstName}
                                    setFirstNameError={setFirstNameError}
                                    lastNameValue={lastNameValue}
                                    setLastNameValue={setLastNameValue}
                                    errorLastName={errorLastName}
                                    setLastNameError={setLastNameError}
                                    emailValue={emailValue}
                                    setEmailValue={setEmailValue}
                                    errorEmail={errorEmail}
                                    setEmailError={setEmailError}
                                    phoneValue={phoneValue}
                                    setPhoneValue={setPhoneValue}
                                    errorPhone={errorPhone}
                                    setPhoneError={setPhoneError}
                                    countryValue={countryValue}
                                    setCountryValue={setCountryValue}
                                    country={country}
                                    agreeToTermsValue={agreeToTermsValue}
                                    setAgreeToTermsValue={setAgreeToTermsValue}
                                    errorAgreeToTerms={errorAgreeToTerms}
                                    setAgreeToTermsError={setAgreeToTermsError}
                                    companySetupId={setupId}
                                    setLoading={setLoading}
                                    setServiceIsDownError={setServiceIsDownError}
                                    validateCompanyInfoHandler={validateCompanyInfoHandler} 
                                    handleInsert={handleInsert} />

                            </Box>

                            : ""}


                        {activeStep === 1 ?

                            <React.Fragment>
                                <Box
                                    noValidate
                                    autoComplete="off"
                                >
                                    <MyCompanyPreCheckout
                                        handleNext={handleNext}
                                        companyNameValue={companyNameValue}
                                        emailValue={emailValue}
                                        phoneValue={phoneValue}
                                        country={country}
                                        countryValue={countryValue}
                                        product={product} setProduct={setProduct}
                                        price={price} setPrice={setPrice}
                                        getProductAndPriceIds={getProductAndPriceIds}
                                        billingInterval={billingInterval} setBillingInterval={setBillingInterval}
                                        billingCustomerName={billingCustomerName} setBillingCustomerName={setBillingCustomerName}
                                        billingCustomerPhone={billingCustomerPhone} setBillingCustomerPhone={setBillingCustomerPhone}
                                        billingCustomerEmail={billingCustomerEmail} setBillingCustomerEmail={setBillingCustomerEmail}
                                        billingCustomerAddressLine1={billingCustomerAddressLine1} setBillingCustomerAddressLine1={setBillingCustomerAddressLine1}
                                        billingCustomerAddressLine2={billingCustomerAddressLine2} setBillingCustomerAddressLine2={setBillingCustomerAddressLine2}
                                        billingCustomerCity={billingCustomerCity} setBillingCustomerCity={setBillingCustomerCity}
                                        billingCustomerProvince={billingCustomerProvince} setBillingCustomerProvince={setBillingCustomerProvince}
                                        billingCustomerPostalCode={billingCustomerPostalCode} setBillingCustomerPostalCode={setBillingCustomerPostalCode}
                                        billingCustomerCountry={billingCustomerCountry} setBillingCustomerCountry={setBillingCustomerCountry}
                                        promoCode={promoCode} setPromoCode={setPromoCode}
                                        billingCustomerNameError={billingCustomerNameError}
                                        billingCustomerPhoneError={billingCustomerPhoneError}
                                        billingCustomerEmailError={billingCustomerEmailError}
                                        billingCustomerAddressLine1Error={billingCustomerAddressLine1Error}
                                        billingCustomerCityError={billingCustomerCityError}
                                        billingCustomerProvinceError={billingCustomerProvinceError}
                                        billingCustomerPostalCodeError={billingCustomerPostalCodeError}
                                        promo={promo}
                                        totalPrice={totalPrice} setTotalPrice={setTotalPrice}
                                    ></MyCompanyPreCheckout>
                                </Box>
                            </React.Fragment>

                            : ""}

                        {activeStep === 2 ?

                            <React.Fragment>
                                <Box
                                    noValidate
                                    autoComplete="off"
                                >       
                                    <MyCompanyCheckout
                                        stripeProducts={stripeProducts}
                                        companyId={companyIdValue}
                                        authorizedBy={user.userEmail}
                                        authorizedByTenantId={accounts[0].tenantId}
                                        product={product}
                                        price={price}
                                        billingInterval={billingInterval} setBillingInterval={setBillingInterval}
                                        billingCustomerName={billingCustomerName}
                                        billingCustomerPhone={billingCustomerPhone}
                                        billingCustomerEmail={billingCustomerEmail}
                                        billingCustomerAddressLine1={billingCustomerAddressLine1}
                                        billingCustomerAddressLine2={billingCustomerAddressLine2}
                                        billingCustomerCity={billingCustomerCity}
                                        billingCustomerProvince={billingCustomerProvince}
                                        billingCustomerPostalCode={billingCustomerPostalCode}
                                        billingCustomerCountry={billingCustomerCountry}
                                        promoCode={promoCode}
                                        totalPrice={totalPrice} setTotalPrice={setTotalPrice}
                                        activeStep={activeStep}
                                        handleBack={handleBack} />

                                </Box>
                            </React.Fragment>

                            : ""}

                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        {activeStep < 2 ?
                            <Button
                                color="inherit"
                                variant="contained"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                            >
                                Back
                            </Button>
                            : ""}
                            <Box sx={{ flex: '1 1 auto' }} />

                            {activeStep === 0 ? <Button onClick={handleNext} variant="contained" disabled={!isAuthenticated}>Next</Button> : ""}
                            {activeStep === 1 ? <Button variant="contained" onClick={handleBillingValidate}>Next</Button> : ""}
                            {activeStep === 2 ? "" : ""}

                        </Box>
                    </React.Fragment>
                </Box>
            </>
        );
    };
}

export default CompanySetup;