// React Components
import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import axios from "axios";

// Material UI Components
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

// Audit Vault Components
import AddTenantButton from '../components/AddTenantButton';
import CompanyNag from "../components/common/CompanyNag";
import ServiceIsDown from '../components/common/ServiceIsDown';
import ProgressBar from '../components/common/ProgressBar';
import DisplayBreadCrumb from "../components/common/DisplayBreadCrumb";
import DisplaySectionTitleAndDescription from "../components/common/DisplaySectionTitleAndDescription";

// MSAL Components
import { useMsal, useIsAuthenticated } from '@azure/msal-react';

// Audit Vault Common
import { getCompanyByCompanyId } from "../utilities/common-company";
import { getUser } from '../utilities/common-user-utils';
import { encryptKey } from '../utilities/common-encrypt-util';

// Audit Vault Constants
const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;
const isAdminPage = false;

const theBreadcrumbPath = [
    { name: 'Home', link: '/' },
    { name: 'Setup', link: '/mycompany' },
];

function MyCompanyCheckoutComplete(props) {

    const { user, setUser, openCompanyNag, setOpenCompanyNag } = props;

    const { accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const queryParams = new URLSearchParams(window.location.search);
    const customerId = queryParams.get("customer_id");

    const stripeMessge = queryParams.get("redirect_status");
    const tenantId = queryParams.get("tenant_id");
    const subscriptionId = queryParams.get("subscription_id");

    const { companyId, priceId, dbUnits, productId } = useParams();
    const [company, setCompany] = useState("");
    const [loading, setLoading] = useState(true);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);

    let theSectionTitle = "Sign Up Succeeded";
    const theSectionDesc = "Congratulations!  The initial setup was completed.";


    useEffect(() => {
        async function fetchData() {

            try {
                console.log(user);
                if (stripeMessge && stripeMessge == "Successfully Subscribed") {
                    if (loading && user) {

                        // encrypt some variables in the payload.
                        var payload = `{
                            companyId: ${Number(companyId)},
                            AuthorizedBy: "${await encryptKey(accounts[0].username)}",
                            AuthorizedByEmail: "${await encryptKey(accounts[0].username)}",
                            AuthorizedByGraphId: "${await encryptKey(user.microsoftGraphId)}",
                            AuthorizedByTenantId: "${await encryptKey(tenantId)}",
                            StripeCustomerId: "${await encryptKey(customerId)}",
                            StripeProductId: "${productId}",
                            PriceId: "${priceId}",
                            SubscriptionId: "${await encryptKey(subscriptionId)}",
                            DbUnitsPerTenant: ${Number(dbUnits)}
                        }`;


                        var response = await axios.put(`${WEB_API_URL}Company/UpdateCompanyPaymentComplete`, null, {
                            params: {
                                key: payload
                            }
                        });

                        if (response) {
                            var userResponse = await getUser(accounts, isAuthenticated, setUser);

                            if (userResponse) {

                                const response2 = await getCompanyByCompanyId(companyId, setServiceIsDownError);
                                if (response2) {
                                    console.log(response2)
                                    setCompany(response2);
                                }
                            }
                        }
                    }
                }
                else {
                    theSectionTitle = "Payment Failed!";
                }
            }
            catch (e) {
                console.log("ERROR: MyCompanyCheckoutComplete.fetchData")
                console.log(e);
                theSectionTitle = "Payment Failed!";
            }
            finally {
                setLoading(false);
            }
        }
        fetchData();
    }, []);


    return (
        <>
            <DisplayBreadCrumb paths={theBreadcrumbPath} />
            <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />
            {serviceIsDownError && (<ServiceIsDown></ServiceIsDown>)}
            {loading && (<ProgressBar loading={loading} message={"Loading ..."} />)}
            {!loading &&
                <>
                    <Card variant="outlined">
                        <CardContent>
                            <Table sx={{ minWidth: 250 }} aria-label="simple table">
                                <TableBody>
                                    {stripeMessge == "Successfully Subscribed" &&
                                        (
                                            <>
                                                <Typography><b>There are just a couple of easy steps to configure Audit Vault for M365, let's start below:</b></Typography>
                                                <Typography sx={{ marginBottom: 5 }}>Please click the "Add a New Tenant" button below to configure your Audit Vault Tenant settings to start preserving audit records for your organization.</Typography>
                                            </>
                                        )
                                    }
                                    <TableRow>
                                        <TableCell key="fn1">Subscription Status:</TableCell>
                                        <TableCell key="fn2" sx={{ whiteSpace: 'nowrap', paddingRight: 5 }}>{stripeMessge}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <div><br></br></div>
                            {!loading && company.setupStageComplete > 0 && (<CompanyNag company={company} openCompanyNag={openCompanyNag} setOpenCompanyNag={setOpenCompanyNag} />)}
                        </CardContent>
                        <CardActions>
                            {company && (<AddTenantButton />)}
                        </CardActions>
                    </Card>
                </>
            }
        </>
    );
};

export default MyCompanyCheckoutComplete;