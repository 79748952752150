
const COMPANY_SIZES = [
  {
    value: '250',
    label: '1 - 250'
  },
  {
    value: '500',
    label: '251 - 500'
  },
  {
    value: '750',
    label: '501 - 750'
  },
  {
    value: '1000',
    label: '751 - 1000'
  },
  {
    value: '1500',
    label: '1001 - 1500'
  },
  {
    value: '2000',
    label: '1501 - 2000'
  },
  {
    value: '3000',
    label: '2000 +'
  },
];

export { COMPANY_SIZES };
